import React from 'react';
import SubHeader from 'src/components/SubHeader';
import Breadcrumb from 'src/components/Breadcrumb';
import { SubHeaderDivider } from 'src/components/SubHeaderDivider';
import GoBack from 'src/components/GoBack';
import { ErrorMessageContainer, ErrorPageContainer, GoBackButton } from './style';
import { ReactComponent as LogoError } from 'src/assets/logos/logoError.svg';
import errorBanner from 'src/assets/landing.png';
import PartnerLink from 'src/components/PartnerLink';

interface ErrorPageProps {
  notFound?: boolean;
  somethingWrong?: boolean;
  accessDenied?: boolean;
}

const ErrorPage: React.FC<ErrorPageProps> = ({ notFound, somethingWrong, accessDenied }) => {

  const handleGoBack = () => {
    window.history.back();
  };

  return (
    <ErrorPageContainer>
      <SubHeader background={errorBanner}>
        <Breadcrumb
          crumbs={[
            <PartnerLink className="home" to="/home">
              Início
            </PartnerLink>,
            <PartnerLink>Erro</PartnerLink>,
          ]}
        />

        <SubHeaderDivider />

        <GoBack />
      </SubHeader>

      <ErrorMessageContainer>
        <LogoError />

        {notFound && (
          <>
            <h1>
              Ops, <span>não encontramos</span> essa página.
            </h1>
            <p>Erro 404: Essa página que você procura não existe.</p>
          </>
        )}

        {accessDenied && (
          <>
            <h1>
              Ops, <span>algo deu errado</span>
            </h1>
            <p>A página que você procura não está disponível.</p>
          </>
        )}

        {somethingWrong && (
          <>
            <h1>
              Ops, <span>algo deu errado</span>
            </h1>
            <p>O carregamento dessa página falhou.</p>
          </>
        )}

        <div>
          <GoBackButton type="button" onClick={handleGoBack}>
            Voltar a página anterior
          </GoBackButton>
        </div>
      </ErrorMessageContainer>
    </ErrorPageContainer>
  );
};

export default ErrorPage;
