import React from 'react';
import styled from 'styled-components';
import { ReactComponent as SearchIcon } from 'src/assets/search-icon.svg';
import useVideos from 'src/pages/Videos/contexts/useVideos';

const VideoSearchInputContainer = styled.span`
  margin: 8px 16px;
  position: relative;

  & > .search-icon {
    position: absolute;
    top: 6px;
    left: 8px;
  }

  input {
    padding: 8px;
    padding-left: 28px;
    height: 100%;
    width: 100%;
    border-radius: 16px;
    outline: none;
    border: none;
  }
`;

const VideoSearchInput: React.FC = () => {
  const { search, setSearch } = useVideos();

  return (
    <VideoSearchInputContainer>
      <SearchIcon className="search-icon" />
      <input
        type="search"
        placeholder="Digite o que você quer encontrar"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
    </VideoSearchInputContainer>
  );
};

export default VideoSearchInput;
