import React, { useCallback, useEffect, useState } from 'react';
import Content from 'src/models/content';
import { LatestVideosContainer } from './style';
import { getContentsService } from 'src/services/contentsService';
import DefaultSlider from 'src/components/DefaultSlider';
import VideoCard from 'src/components/VideoCard';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import useUser from 'src/contexts/UserContext/useUser';

const LatestVideos: React.FC = () => {
  const { selectedPartner: partner } = useUser();
  const [videos, setVideos] = useState([] as Content[]);

  const getLatestVideos = useCallback(async () => {
    const { data: videos } = await getContentsService({
      order_by: 'updated_at',
      ...(partner
        ? {
          segmentation_item_ids: [
            partner.segmentation_item_id ||
            process.env.REACT_APP_MANOLE_UUID ||
            '8f5caaa2-3981-4e83-84f9-44f488cca3b3',
          ],
        }
        : {}),
    });
    setVideos(videos);
  }, [partner]);

  useEffect(() => {
    getLatestVideos();
  }, [getLatestVideos]);

  return (
    <LatestVideosContainer className="max-content">
      {!!videos.length && (
        <DefaultSlider
          title="Últimos lançamentos"
          items={
            videos?.length
              ? videos?.map((content) => {
                return (
                  <VideoCard
                    key={content.content_id}
                    video={content}
                    inProgress
                  />
                );
              })
              : []
          }
        />
      )}
    </LatestVideosContainer>
  );
};

export default LatestVideos;
