import { BlockedBlockContainer, BlockedOverlay, CircleLock } from './style';
import { ReactComponent as LockKey } from 'src/assets/icons/lock-key.svg';
import { FC } from 'react';

interface IBlockedContentProps {
    img?: string;
}

export const BlockedBlock: FC<IBlockedContentProps> = ({ children, img }) => {
  return (
    <BlockedBlockContainer img={img}>
      <BlockedOverlay>
        <CircleLock>
          <LockKey />
        </CircleLock>
      </BlockedOverlay>
      { children }
    </BlockedBlockContainer>
  );
};

export default BlockedBlock;

