import styled from 'styled-components';

const DropdownContainer = styled.div`
  position: relative;
`;

const DropdownButton = styled.button`
  background-color: transparent;
  border-radius: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 6px;
  border: none;
  vertical-align: middle;

  display: flex;
  align-items: center;
  gap: 16px;
  color: var(--text-color);
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;

  svg {
    color: var(--text-color);
    width: 24px;
    margin: 0;
  }
`;

interface DropdownNavProps {
  isActive?: boolean;
}

const DropdownNav = styled.nav<DropdownNavProps>`
  width: 200px;
  position: absolute;
  top: 60px;
  right: 0;
  background: #f2f2f2;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  opacity: ${({ isActive }) => (isActive ? '1' : '0')};
  visibility: ${({ isActive }) => (isActive ? 'visible' : 'hidden')};
  transform: ${({ isActive }) => isActive ? 'translateY(0)' : 'translateY(-20px)'};
  transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
  overflow: hidden;

  ul {
    list-style: none;

    li {
      background: #f2f2f2;
      transition: 0.3s;

      &:hover {
        filter: brightness(0.9);
      }

      button {
        font-size: 20px;
        line-height: 24px;
        color: #25282b;
        padding: 15px 20px;
        display: block;
      }

      button.logout-button {
        width: 100%;
        background: #f2f2f2;
        border-left: 0;
        border-right: 0;
        border-top: 1px solid #909599;
        border-bottom: 0;
        border-radius: 4px;
        padding: 14px;

        transition: filter 0.2s ease-in-out;

        span {
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          gap: 10px;
          margin-left: 10px;
          font-size: 1em;
          line-height: 20px;
          color: var(--primary-color);

          svg {
            color: var(--primary-color);
          }
        }
      }
    }
  }
`;

const UserAvatarContainer = styled.div`
  width: 36px;
  min-width: 36px;
  height: 36px;
  min-height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;

    object-fit: cover;
  }
`

export { DropdownContainer, DropdownButton, DropdownNav, UserAvatarContainer };
