import Multiselect from 'multiselect-react-dropdown'
import styled from 'styled-components'

export const MultiselectContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 4px;

  label {
    margin-left: 8px;
    color: var(--text-color);
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
  }
`

export const MultiselectCustom = styled(Multiselect)`
  /* Overriding element hover */
  .optionContainer li:hover,
  .optionContainer .highlight {
    background: var(--primary-color);
    color: #fff;
  }

  /* Placehoolder Color Change */
  .searchBox#css_custom_input::placeholder {
    color: var(--primary-color);
  }

  /* disabled */
  .disable_ms {
    opacity: 1;
  }
`
