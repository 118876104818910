import { createContext } from 'react';
import Theme from 'src/models/theme';

interface IThemesContext {
  themes: Theme[];
}

const ThemesContext = createContext({} as IThemesContext);

export default ThemesContext;
