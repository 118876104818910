import React, { useCallback } from 'react';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';
import ReactPaginate from 'react-paginate';
import { PaginationContainer } from './style';

const pagesToIncrease = 1;

interface PaginationProps {
  onPageChange: (selectedPage: number) => void;
  pageCount: number;
}

const Pagination: React.FC<PaginationProps> = ({ onPageChange, pageCount }) => {
  const handleChangePage = useCallback(
    (event: { selected: number }) => {
      onPageChange(event.selected + pagesToIncrease);
    },
    [onPageChange]
  );

  return (
    <PaginationContainer className="pagination">
      <ReactPaginate
        onPageChange={handleChangePage}
        pageCount={pageCount}
        nextLabel={<AiOutlineRight color="var(--primary-color)" />}
        previousLabel={<AiOutlineLeft color="var(--primary-color)" />}
      />
    </PaginationContainer>
  );
};

export default Pagination;
