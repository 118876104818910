import { FiLogOut } from 'react-icons/fi'
import useUser from 'src/contexts/UserContext/useUser'
import useMobileMenu from 'src/contexts/MobileMenuContext/useMobileMenu'
import {
  MobileMenuContainer,
} from './style'
import { ReactComponent as Logo } from 'src/assets/logos/logo-manole-play.svg';
import { ReactComponent as CloseIcon } from 'src/assets/icons/close-icon.svg';

interface IMobileMenu {
  openModalPartners: () => void
}

export function MobileMenu({ openModalPartners }: IMobileMenu) {
  const { navigate, logout } = useUser()

  const { opened, setOpened } = useMobileMenu()
  const handleCloseMobileMenu = () => setOpened(false)

  const handleNavigate = (url: string) => {
    navigate(url)
    handleCloseMobileMenu()
  }

  const handleLogout = () => {
    handleCloseMobileMenu()
    logout()
  }

  if (!opened) return null

  return (
    <MobileMenuContainer>
      <nav className="menu">
        <Logo className="logo-svg" />

        <hr />

        <ul className="menu-itens">
          <li className="menu-item" onClick={() => handleNavigate('my-progress')}>
            <span>Meu progresso</span>
          </li>

          <li className="menu-item" onClick={() => handleNavigate('my-list')}>
            <span>Minha lista</span>
          </li>

          <li className="menu-item" onClick={() => handleNavigate('my-profile')}>
            <span>Meu perfil</span>
          </li>

          <li className="menu-item" onClick={openModalPartners}>
            <span>Trocar o parceiro</span>
          </li>
        </ul>

        <div className="menu-item-logout" onClick={handleLogout}>
          <FiLogOut />
          <span>Sair</span>
        </div>
      </nav>

      <section className="container-close-menu">
        <div className="btn-close-menu" onClick={handleCloseMobileMenu}>
          <CloseIcon />
        </div>
      </section>
    </MobileMenuContainer>
  )
}
