import { useCallback, useEffect, useState } from 'react';
import { Swiper } from 'swiper/react/swiper-react.js';
import { SwiperSlide } from 'swiper/react/swiper-react.js';
import SwiperCore, { Navigation, Pagination, Mousewheel } from 'swiper';
import HighlightVideoSlide from '../HighlightVideoSlide';
import { Container, StylesSwiper } from './style';
import { getContentsService } from 'src/services/contentsService';
import Content from 'src/models/content';
import 'swiper/swiper.scss';
import 'swiper/modules/navigation/navigation.scss';
import 'swiper/modules/pagination/pagination.scss';
import useUser from 'src/contexts/UserContext/useUser';
import useWindowSize from 'src/contexts/WindowSizeContext/useWindowSize';

SwiperCore.use([Navigation, Pagination, Mousewheel]);

const HighlightedVideoSlider = () => {
  const { selectedPartner: partner } = useUser();
  const { windowWidth } = useWindowSize()

  const [contentsHighlight, setContentsHighlight] = useState<Content[]>();

  const getContentsHighlight = useCallback(async () => {
    const { data: contents } = await getContentsService({
      is_highlight: true,
      relations: ['content_user'],
      ...(partner
        ? {
          segmentation_item_ids: [
            partner.segmentation_item_id ||
            process.env.REACT_APP_MANOLE_UUID ||
            '8f5caaa2-3981-4e83-84f9-44f488cca3b3',
          ],
        }
        : {}),
    });

    setContentsHighlight(contents);
  }, [partner]);

  useEffect(() => {
    getContentsHighlight();
  }, [getContentsHighlight]);

  return (
    <Container>
      <StylesSwiper>
        <Swiper
          cssMode={true}
          navigation={ windowWidth > 640}
          pagination={true}
          mousewheel={true}
          className="mySwiper"
        >
          {contentsHighlight?.map((contentHighlight) => {
            return (
              <SwiperSlide key={contentHighlight.content_id}>
                <HighlightVideoSlide content={contentHighlight} />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </StylesSwiper>
    </Container>
  );
};

export default HighlightedVideoSlider;
