import React from 'react';
import useVideos from '../../contexts/useVideos';
import AutoPlayToggle from './components/AutoPlayToggle';
import VideoCard from './components/VideoPlayerCard';
import VideoSearchInput from './components/VideoSearchInput';
import {
  ContentList,
  TitleAndAutoPlayToggleContainer,
  VideosListContainer,
  VideosTitle,
} from './style';

const VideosList: React.FC = () => {
  const { filteredContents } = useVideos();

  return (
    <VideosListContainer>
      <TitleAndAutoPlayToggleContainer>
        <VideosTitle>Todos os Vídeos</VideosTitle>
        <AutoPlayToggle />
      </TitleAndAutoPlayToggleContainer>
      <VideoSearchInput />
      <ContentList>
        {filteredContents?.length ? (
          filteredContents?.map((c, index) => (
            <VideoCard key={`${index}_${c.content_id}`} content={c} />
          ))
        ) : (
          <></>
        )}
      </ContentList>
    </VideosListContainer>
  );
};

export default VideosList;
