import styled from 'styled-components';

const SearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 24px;
`;

const ResultDescription = styled.p`
  .search-text {
    color: var(--text-color);
    font-weight: bold;
  }
`;

const ContentsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;

  @media (max-width: 1000px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 700px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const SpecialtiesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  margin-top: 48px;

  @media (max-width: 1000px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 700px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const NoResultFound = styled.p``;

const SpecialtiesTitle = styled.h2`
  grid-column: 1 / 4;
  color: var(--text-color);

  @media (max-width: 1000px) {
    grid-column: 1 / 3;
  }

  @media (max-width: 700px) {
    grid-column: 1 / 2;
  }
`;

const ContentsTitle = styled.h2`
  grid-column: 1 / 4;
  color: var(--text-color);

  @media (max-width: 1000px) {
    grid-column: 1 / 3;
  }

  @media (max-width: 700px) {
    grid-column: 1 / 2;
  }
`;

export {
  SearchContainer,
  ContentsContainer,
  SpecialtiesContainer,
  ResultDescription,
  NoResultFound,
  ContentsTitle,
  SpecialtiesTitle,
};
