import Button from 'src/components/Button';
import styled, { css } from 'styled-components';

const CertificateCardContainer = styled.div`
  position: relative;
  min-height: 160px;
  overflow: hidden;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 8px;
`;

const CertificateCardThumbnail = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 4;
`;

const CertificateCardTitle = styled.h3`
  z-index: 9;
  color: var(--white);
`;

interface CertificateDownloadButtonProps {
  hovered: boolean;
}

const CertificateDownloadButton = styled(
  Button
)<CertificateDownloadButtonProps>`
  z-index: 9;
  opacity: 0;
  transform: translateY(120%);
  visibility: hidden;

  background: var(--primary-color);
  color: white;
  border-radius: 4px;

  span {
    flex: 1;
  }

  ${(props) =>
    props.hovered &&
    css`
      visibility: visible;
      opacity: 1;
      transform: translateY(0%);
    `}
`;

export {
  CertificateCardContainer,
  CertificateCardThumbnail,
  CertificateCardTitle,
  CertificateDownloadButton,
};
