import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import SearchContext from 'src/contexts/SearchContext/SearchContext';
import useUser from '../UserContext/useUser';

interface ExtendedWindow extends Window {
  searchRedirectTimeoutId: number;
}

declare let window: ExtendedWindow;

const SearchContextProvider: React.FC = ({ children }) => {
  const { navigate } = useUser();
  const location = useLocation();
  const [searchText, setSearchText] = useState('');
  const [opened, setOpened] = useState(false);

  const goToSearchPage = useCallback(() => {
    if (window.searchRedirectTimeoutId) {
      clearTimeout(window.searchRedirectTimeoutId);
    }

    if (searchText && !location.pathname.includes('/search')) {
      window.searchRedirectTimeoutId = window.setTimeout(() => {
        navigate('/search');
      }, 300);
    }
  }, [location.pathname, navigate, searchText]);

  useEffect(() => {
    goToSearchPage();
  }, [goToSearchPage]);

  useEffect(() => {
    if (!location.pathname.includes('/search')) {
      setSearchText('');
    }
  }, [location.pathname]);

  return (
    <SearchContext.Provider
      value={{ searchText, setSearchText, opened, setOpened }}
    >
      {children}
    </SearchContext.Provider>
  );
};

export default SearchContextProvider;
