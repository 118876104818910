import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { trim } from 'src/helpers';

const PlayerSambavideosContainer = styled.div`
  @media screen and (max-width: 640px) {
    margin: 0 -16px;
  }

  iframe {
    width: 100%;
    height: 440px;

    @media (max-width: 1300px) {
      height: 360px;
    }

    @media (max-width: 768px) {
      height: 220px;
    }

    @media (max-width: 400px) {
      height: 160px;
    }
  }
`;

declare let SambaPlayer: any;

export interface PlayerEventListener {
  event: string;
  eventParam?: any;
  duration?: any;
}

interface PlayerSambaVideosProps {
  contentReference: string;
  resume?: boolean | number;
  handleOnFinish: (goToNext: boolean) => void;
  autoPlay: boolean;
  getEventListeners?: (eventListener: PlayerEventListener) => void;
  contentId: string;
  isLocked: boolean;
}

const PlayerSambavideos: React.FC<PlayerSambaVideosProps> = ({
  contentReference,
  resume = false,
  autoPlay,
  contentId,
  getEventListeners,
  handleOnFinish = () => {},
  isLocked,
}) => {
  const playerContainer = useRef<HTMLDivElement>(null);

  const [sambaPlayerControllerRef, setSambaPlayerControllerRef] = useState<any>();

  const configurePlayer = () => {
    const playerBaseUrl =
      process.env.REACT_APP_PLAYER_BASE_URL ||
      'https://fast.player.liquidplatform.com/pApiv2/embed/';

    const contentReferenceWithoutBaseUrl = trim(
      (contentReference || '').replace(playerBaseUrl, '') || '',
      '/'
    );

    if (contentReferenceWithoutBaseUrl) {
      const splittedReference = contentReferenceWithoutBaseUrl.split('/');
      if (splittedReference && splittedReference.length > 1) {
        const playerHash = `${splittedReference[0]}`.replace(/^\/+|\/+$/g, '');
        const midiaId = `${splittedReference[1]}`.replace(/^\/+|\/+$/g, '');

        SambaPlayer.clearAllMediaEvents();

        const sm_player = new SambaPlayer(playerContainer.current, {
          ph: playerHash,
          m: midiaId,
          playerParams: {
            enableShare: false,
            resume,
            autoStart: autoPlay && !resume,
          },
          events: {
            '*': getEventListeners,
          },
        });
        setSambaPlayerControllerRef(sm_player);
      }
    }
  };

  useEffect(() => {
    if (playerContainer && playerContainer.current) {
      if (Array.from(playerContainer.current.children).length > 0) {
        playerContainer.current.firstChild?.remove();
      }

      configurePlayer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentReference, contentId]);

  useEffect(() => {
    if (sambaPlayerControllerRef) {
      sambaPlayerControllerRef.handlersMap['player:finish'] = [() => handleOnFinish(autoPlay)];
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoPlay, sambaPlayerControllerRef]);

  return <PlayerSambavideosContainer ref={playerContainer} />;
};

export default PlayerSambavideos;
