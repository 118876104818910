import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { goToTop } from 'src/helpers';
import AppContext from './AppContext';

const AppContextProvider: React.FC = ({ children }) => {
  const location = useLocation();
  const [showUserContextMenu, setShowUserContextMenu] = useState(false);

  useEffect(() => {
    goToTop();
  }, [location.pathname]);

  return (
    <AppContext.Provider
      value={{ setShowUserContextMenu, showUserContextMenu }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
