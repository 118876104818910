import { Routes as Switch, Route } from 'react-router-dom';
import Specialty from 'src/pages/Specialty';
import Specialties from 'src/pages/Specialties';
import Videos from 'src/pages/Videos';
import Login from '../pages/Login';
import Home from '../pages/Home';
import { LandingPage } from '../pages/LandingPage';
import Search from 'src/pages/Search';
import { MyProfile } from 'src/pages/MyProfile';
import MyList from 'src/pages/MyList';
import ErrorPage from 'src/pages/Error';
import MyProgress from 'src/pages/MyProgress';
import HomeContextProvider from 'src/contexts/HomeContext/HomeContextProvider';
import LandingContextProvider from 'src/contexts/LandingContext/LandingContextProvider';
import SignUp from 'src/pages/SignUp';
import { ValidateCertificate } from 'src/pages/ValidateCertificate';

const RoutesWithPartner: React.FC = () => {
  return (
    <Switch>
      <Route
        path="/"
        element={
          <HomeContextProvider>
            <Home />
          </HomeContextProvider>
        }
      />

      <Route
        path="/home"
        element={
          <HomeContextProvider>
            <Home />
          </HomeContextProvider>
        }
      />

      <Route path="/my-profile" element={<MyProfile />} />
      <Route path="/my-profile/:subPage" element={<MyProfile />} />

      <Route path="/search" element={<Search />} />

      <Route path="/specialties" element={<Specialties />} />

      <Route path="/specialty/:specialtyId" element={<Specialty />} />

      <Route path="/my-list" element={<MyList />} />
      <Route path="/my-list/:showingContent" element={<MyList />} />

      <Route path="/my-progress/" element={<MyProgress />} />
      <Route path="/my-progress/:showingContent" element={<MyProgress />} />

      <Route path="/videos/specialty/:specialtyId" element={<Videos />} />
      <Route
        path="/videos/specialty/:specialtyId/theme/:themeId/"
        element={<Videos />}
      />
      <Route
        path="/videos/specialty/:specialtyId/theme/:themeId/content/:contentId"
        element={<Videos />}
      />
      <Route
        path="videos/specialty/:specialtyId/content/:contentId"
        element={<Videos />}
      />
      <Route path="/videos/content/:contentId" element={<Videos />} />

      <Route path="/accessDenied" element={<ErrorPage accessDenied />} />
      <Route path="/somethingWrong" element={<ErrorPage somethingWrong />} />

      <Route path="*" element={<ErrorPage notFound />} />
    </Switch>
  );
};

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route
        path="/"
        element={
          <LandingContextProvider>
            <LandingPage />
          </LandingContextProvider>
        }
      />

      <Route path="/login" element={<Login />} />

      <Route path="/sign-up" element={<SignUp />} />

      <Route path="/validate-certificate" element={<ValidateCertificate />} />

      <Route path="/:partnerUrlComplement/*" element={<RoutesWithPartner />} />

      <Route path="*" element={<ErrorPage notFound />} />
    </Switch>
  );
};

export default Routes;
