import styled from 'styled-components';

interface InputContainerProps {
  flex: number;
  disabled: boolean;
}

const InputContainer = styled.div<InputContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 4px;
  max-height: 68px;
  align-items: start;
  flex: ${(props) => props.flex};

  label {
    margin-left: 8px;
    color: var(--text-color);
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
  }

  .input-container {
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;

    input {
      background: ${(props) => (props.disabled ? '#E1E3E6' : '#F5F8FA')};
      border: 1px solid #cfd2d4;
      height: 48px;
      width: 100%;
      border-radius: 30px;
      padding: 16px 24px;
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 16px;
      color: var(--text-color);

      :focus {
        outline: 2px solid var(--primary-color);
      }

      :-webkit-autofill,
      :-webkit-autofill:hover,
      :-webkit-autofill:focus,
      :-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px white inset;
      }
    }

    .eye-container {
      padding: 6px 4px 0;
      position: absolute;
      top: 6px;
      right: 16px;
      cursor: pointer;

      svg {
        stroke: var(--primary-color);
      }
    }
  }

`;

export { InputContainer };
