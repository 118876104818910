import styled, { css } from 'styled-components';

const ActualVideoContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex-basis: 600px;
`;

interface BannerImageProps {
  src: string;
}

const BannerImage = styled.div<BannerImageProps>`
  width: 100%;
  height: 440px;

  @media (max-width: 1300px) {
    height: 360px;
  }

  @media (max-width: 768px) {
    height: 220px;
  }

  @media (max-width: 400px) {
    height: 160px;
  }

  background-repeat: no-repeat;
  background-size: cover;
  ${(props) =>
    props.src &&
    css`
      background-image: url(${props.src});
    `}
`;

const AddToListAndRateContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  flex-wrap: wrap;
`;

const ContentTitle = styled.h3`
  color: var(--text-color);
  font-size: 1.4rem;
`;

const ContentAuthor = styled.p`
  color: var(--gray-darker);
  font-size: 0.9rem;
`;

const ContentLockedInfoContainer = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 8px;
    padding: 12px 8px;
    margin-top: 12px;
    color: var(--white);
    background: var(--primary-color);
    border-radius: 4px;

    
    .message {
      font-size: 16px;
      font-weight: normal;

      @media (max-width: 768px) {
        font-size: 16px;
      }
    }

    .link-plans {
      font-weight: bold;
      color: inherit;
      text-decoration: underline;

      :hover {
        cursor:pointer;
      }
    }
`

export {
  ActualVideoContentContainer,
  ContentLockedInfoContainer,
  BannerImage,
  AddToListAndRateContainer,
  ContentTitle,
  ContentAuthor,
};
