import React, { useEffect, useState } from 'react';
import WindowSizeContext from 'src/contexts/WindowSizeContext/WindowSizeContext';

const WindowSizeContextProvider: React.FC = ({ children }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.onresize = () => {
      setWindowWidth(window.innerWidth);
    };
  }, []);

  return (
    <WindowSizeContext.Provider value={{ windowWidth }}>
      {children}
    </WindowSizeContext.Provider>
  );
};

export default WindowSizeContextProvider;
