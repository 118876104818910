import styled from 'styled-components';

const VideosContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 32px;
  `;

const PlayerContainer = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 16px;
  margin-bottom: 32px;
`;

const VideosTitle = styled.h2`
  color: var(--white);
  font-size: 2.2em;
  padding: 32px 0 24px 0;

  @media screen and (max-width: 640px) {
      font-size: 24px;
    }
`;

export { VideosContainer, PlayerContainer, VideosTitle };
