import { Link } from 'react-router-dom'
import {
  SideContentContainer,
  GoBackButton,
  GoToLandingLink,
  SideContentText,
  SignUpLink,
  Step,
} from './style'
import { ReactComponent as GoBack } from 'src/assets/back-arrow.svg'

interface ISideContent {
  text: string
  step?: number
  goBack?: () => void
}

export function SideContent({ text, step, goBack }: ISideContent) {
  const linkTo = `/${step ? 'login' : 'sign-up'}`

  return (
    <SideContentContainer>
      {step === 2
        ? (
          <GoBackButton onClick={goBack}>
            <GoBack />
          </GoBackButton>
        )
        : (
          <GoToLandingLink to="/">
            <GoBack />
          </GoToLandingLink>
        )
      }

      <div className="text-step-container">
        <SideContentText>{text}</SideContentText>
        {step && <Step>{`Passo ${step} de 2`}</Step>}
      </div>

      <SignUpLink>
        <span>{`${step ? 'Já' : 'Não'} tem uma conta? `}</span>
        <Link to={linkTo}>
          {`${step ? 'Entre' : 'Crie'} agora`}
        </Link>
      </SignUpLink>
    </SideContentContainer>
  )
}
