import styled from 'styled-components';

interface DefaultButtonProps {
  isOutline?: boolean;
  widthValue?: string;
}

const StyleDefaultButton = styled.button<DefaultButtonProps>`
  width: ${({ widthValue }) => (widthValue ? widthValue : '100%')};
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 16px 24px 16px 24px;
  border-radius: 8px;
  border: ${({ isOutline }) =>
    isOutline ? '1px solid var(--white)' : '1px solid var(--primary-color)'};
  color: var(--white);
  background: ${({ isOutline }) =>
    isOutline ? 'transparent' : 'var(--primary-color)'};
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 1px;
  text-transform: uppercase;

  transition: filter 0.2s ease-in-out;

  &:hover {
    filter: brightness(0.9);
  }
`;

export { StyleDefaultButton };
