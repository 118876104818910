import { FC, ReactNode } from "react"
import {
  ModalWrapper,
  ModalBackdrop,
  ModalContent
} from "./style"

interface IModalProps {
  children: FC | ReactNode,
  close: () => void
}

export function Modal({ children, close }: IModalProps) {
  return (
    <ModalWrapper>
      <ModalBackdrop onClick={close} />
      <ModalContent>
        {children}
      </ModalContent>
    </ModalWrapper>
  )
}
