import httpClient from 'src/http-client'
import {
  IChangePasswordService,
  ILoginCredentials,
  ILoginRefreshToken,
  ILoginResponse
} from 'src/models/loginModel'
import { IUserPost } from 'src/models/userModel'

export const loginService = async (credentials: ILoginCredentials): Promise<ILoginResponse> => {
  const { data } = await httpClient.post<ILoginResponse>(`/sessions/login`, credentials)
  return data
}

export const signUpService = async (credentials: IUserPost, token?: string): Promise<ILoginResponse> => {
  const { data } = await httpClient.post<ILoginResponse>(`/sessions/signup`, credentials, {
    headers: {
      'partner-signup-token': token || ''
    }
  })
  return data
}

export const refreshTokenService = async (refresh_token: string): Promise<ILoginRefreshToken> => {
  const { data } = await httpClient.post<ILoginRefreshToken>(`/sessions/refresh`, {
    refresh_token
  })
  return data
}

export const changePasswordService = async (payload: IChangePasswordService) => {
  await httpClient.post<ILoginRefreshToken>(`/sessions/change-password`, payload)
}
