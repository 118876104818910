import styled from 'styled-components';

const SpecialtyContainer = styled.div``;

const SubHeaderSpecialtyInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 24px;
  color: var(--white);

  .themes-and-videos {
    font-size: 1.2em;
    font-weight: 500;

    @media screen and (max-width: 640px) {
      font-size: 16px;
    }

    .separator {
      text-align: center;
      display: inline-block;
      width: 26px;
    }
  }

  .title {
    font-weight: bold;
    font-size: 2.6em;

    @media screen and (max-width: 640px) {
      font-size: 24px;
    }
  }

  .watch-and-bookmark {
    display: flex;
    align-items: center;
    gap: 16px;

    @media screen and (max-width: 360px) {
      flex-wrap: wrap;

      button {
        width: 100%;
      }
    }

    button {
      color: var(--white);
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 48px;

      &.watch-all {
        background: var(--primary-color);
        padding: 12px 48px;

        :hover {
          box-shadow: 0px 0px 5px var(--primary-color);
        }
      }

      &.bookmark {
        background: transparent;
        border: solid 1px var(--white);
        padding: 12px 16px;

        :hover {
          box-shadow: 0px 0px 5px var(--white);
        }
      }

      svg,
      path,
      circle {
        color: var(--white);
        stroke: var(--white);
      }
    }
  }

  .plan-info {
    font-size: 0.85em;
    padding-bottom: 48px;
  }
`;

const SpecialtyThemesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 24px;

  & .title {
    font-size: 1.8em;
    color: var(--text-color);
  }
`;

const SpecialtyContentsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  margin-bottom: 48px;

  & .title-and-filter {
    grid-column: 1 / 4;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 640px) {
      flex-wrap: wrap;
    }

    & .title {
      font-size: 1.4em;
      color: var(--text-color);
    }
  }

  @media (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);

    & .title-and-filter {
      grid-column: 1 / 3;
    }
  }

  @media (max-width: 700px) {
    grid-template-columns: repeat(1, 1fr);

    & .title-and-filter {
      grid-column: 1 / 2;
    }
  }
`;

export {
  SpecialtyContainer,
  SubHeaderSpecialtyInfo,
  SpecialtyThemesContainer,
  SpecialtyContentsContainer,
};
