import httpClient from 'src/http-client'
import Content from 'src/models/content'
import Paginated from 'src/models/paginated'
import { IGetRecommendationsByTagParams } from './contentsService'

export const getContentsService = async (filters?: any): Promise<Paginated<Content>> => {
  const { data } = await httpClient.get<Paginated<Content>>(`/contents`, {
    params: {
      ...filters,
      is_active: true,
      flag: ['content'],
      relations: [
        ...['segmentations', 'tags'],
        ...(filters?.relations || []),
      ],
    },
  })
  return data
}

export const getContentRecommendationsByTagService = async (
  filters: IGetRecommendationsByTagParams = {}
): Promise<Content[]> => {
  const { data } = await httpClient.get<Content[]>(`/contents/recommendations/tag`, {
    params: {
      ...filters,
    },
  })
  return data
}

export const getContentService = async (contentId: string, filters?: any) => {
  const { data } = await httpClient.get<Content>(`/contents/${contentId}`, {
    params: {
      ...filters,
      relations: [
        'authors',
        'tags',
        'content_user',
        'extra_materials',
        ...(filters?.relations || []),
      ],
    },
  })
  return data
}

export const startContentService = async (contentId: string) => {
  const { data } = await httpClient.post(`/content-users/${contentId}/start`)

  updateContentProgressService(contentId, 1)

  return data
}

export const addToListContentService = async (contentId: string, addToList: boolean) => {
  const { data } = await httpClient.patch(`/content-users/${contentId}/bookmark`, {
    book_mark: addToList,
  })
  return data
}

export const updateContentProgressService = async (contentId: string, content_view: number | null) => {
  const { data } = await httpClient.patch(`/content-users/${contentId}/content-view`, {
    content_view,
  })
  return data
}

export const toggleContentActiveService = async (contentId: string, shouldActive: boolean) => {
  const { data } = await httpClient.patch(`/contents/${contentId}/active`, {
    is_active: shouldActive,
  })
  return data
}

export const rateContentService = async (contentId: string, newRating: number) => {
  const { data } = await httpClient.patch(`/content-users/${contentId}/review`, {
    review_stars: newRating,
    review_description: '',
  })
  return data
}

export const finishContentService = async (contentId: string) => {
  const { data } = await httpClient.post(`/content-users/${contentId}/finish`)
  return data
}
