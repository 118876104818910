import styled from 'styled-components';

const Button = styled.button`
  background: none;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  padding: 8px 12px;
  transition: all 0.3s;

  border-radius: 4px;

  &:disabled {
    background: var(--gray-lighter);
    cursor: not-allowed;
  }
`;

const CutImageContainer = styled.div`
  width: 100%;
  max-height: 80vh;
  overflow-y: auto;
  poisition: absolute;
  inset: 0;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  z-index: 10;

  #title {
    font-size: 14px;
    font-weight: normal;
    text-transform: uppercase;

    color: var(--blueish);
  }
`;

const FileInputContainer = styled.div`
  padding-left: 4px;
  border-radius: 4px;
  border: solid 1px var(--gray-lighter);
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  transition: all 0.3s;

  input {
    flex: 1;
  }

  :hover {
    border: 1px solid var(--blueish);
  }
`;

const SelectImageButton = styled(Button)`
  color: var(--gray-darker);
  border-left: solid 1px var(--gray-lighter);
  transition: all 0.3s;

  :hover {
    color: var(--blueish);
  }
`;

const ButtonCancel = styled(Button)`
  color: var(--danger);
  background: transparent;
  border: 1px solid var(--danger);

  :hover {
    box-shadow: 0 0 3px 1px var(--danger);
  }
`;

const ButtonContinue = styled(Button)`
  color: white;
  background: var(--success);

  :hover {
    box-shadow: 0 0 3px 1px var(--success);

    &:disabled {
      box-shadow: 0 0 3px 1px var(--gray-lighter);
    }
  }
`;

export {
  CutImageContainer,
  SelectImageButton,
  FileInputContainer,
  ButtonCancel,
  ButtonContinue,
};

