import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import AuthorCard from 'src/components/AuthorCard'
import { PlansSlider } from 'src/components/PlansSlider'
import { LandingCarousel } from 'src/pages/LandingPage/components/LandingCarousel'
import CarouselContent from 'src/pages/LandingPage/components/LandingCarousel/Content'
import PlanText from 'src/components/PlanText'
import {
  GlobalStyles,
  LandingHeader,
  Button,
  LandingPageContainer,
  Hero,
  SpecialistContainer,
  SignContainer,
  MostViewedContainer,
  DevicesContainer,
} from './style'
import useLoading from 'src/contexts/LoadingContext/useLoading'
import {
  getLandingContentsService,
  getLandingAuthorsService,
} from 'src/services/landingService'
import Content from 'src/models/content'
import Authors from 'src/models/author'
import devices from 'src/assets/devices.png'
import google from 'src/assets/googlePlay.svg'
import apple from 'src/assets/appStore.svg'
import { ReactComponent as Logo } from 'src/assets/logos/logo-manole-play.svg'

export function LandingPage() {
  const navigate = useNavigate()
  const { setIsLoading } = useLoading()

  const [landingContent, setLandingContent] = useState<Content[] | undefined>([])
  const [authorsContent, setAuthorsContent] = useState<Authors[] | undefined>([])

  const getContent = useCallback(async () => {
    const { data: content } = await getLandingContentsService()
    const filterdContent = content.filter((c) => c.flag === 'content')
    setLandingContent(filterdContent)
  }, [])

  const getAuthors = useCallback(async () => {
    const authors = await getLandingAuthorsService()
    const filteredAuthors = (authors || []).filter(
      (a) => a?.info?.is_highlight
    )
    setAuthorsContent(filteredAuthors.splice(0, 3))
  }, [])

  useEffect(() => {
    setIsLoading(true)

    getContent()
    getAuthors()

    setIsLoading(false)
  }, [getAuthors, getContent, setIsLoading])

  return (
    <LandingPageContainer>
      <GlobalStyles />

      <LandingHeader>
        <div className="max-content">
          <Logo />

          <Button header onClick={() => navigate('/login')}>
            já tenho conta
          </Button>
        </div>
      </LandingHeader>

      <Hero>
        <div className="leftSideHero max-content">
          <h1>
            Conteúdo médico para o seu <span>crescimento profissional.</span>
          </h1>

          <p>Assista onde e quando quiser.</p>

          <Button className="btn-white" onClick={() => navigate('/login')}>
            já tenho conta
          </Button>

          <Button onClick={() => navigate('/sign-up')}>
            assine agora
          </Button>
        </div>
      </Hero>

      <PlanText />

      <SpecialistContainer className="max-content">
        <h1>Especialistas Internacionais</h1>

        <div>
          {authorsContent?.map((author) => (
            <AuthorCard key={author.author_id} author={author} />
          ))}
        </div>
      </SpecialistContainer>

      <SignContainer className="max-content">
        <h1>Assine já e aproveite todo nosso conteúdo.</h1>
        <PlansSlider />
      </SignContainer>

      <MostViewedContainer>
        <div className="max-content">
          <h1>Mais acessados</h1>

          <LandingCarousel
            items={
              landingContent?.map((c) => {
                return (
                  <CarouselContent key={c.content_id} landingContent={c} />
                )
              })
            }
          />
        </div>
      </MostViewedContainer>

      <DevicesContainer>
        <div className="max-content">
          <img id="devices" src={devices} alt="Devices" />

          <div className="rightSide">
            <p>Assita no notebook, smartphone e acesse em qualquer lugar</p>

            <div className="store">
              <div>
                <img src={google} alt="Play Store" />
              </div>

              <div>
                <img src={apple} alt="Apple Store" />
              </div>
            </div>

            <Button type="button" onClick={() => navigate('/sign-up')}>
              assine agora
            </Button>
          </div>
        </div>
      </DevicesContainer>
    </LandingPageContainer>
  )
}
