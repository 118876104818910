import React from 'react';
import { BreadcrumbContainer, CrumbContainer } from './style';

interface BreadcrumbProps {
  crumbs: (JSX.Element | string)[];
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ crumbs }) => {
  return (
    <BreadcrumbContainer>
      {!!crumbs.length &&
        crumbs.map((crumb, index) => (
          <CrumbContainer key={index}>{crumb}</CrumbContainer>
        ))}
    </BreadcrumbContainer>
  );
};

export default Breadcrumb;
