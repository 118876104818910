import styled from 'styled-components'

export const SecurityContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 40px;
  max-width: 932px;
  flex: 1;

  .subtitle {
    margin-top: 16px;
  }

  .input-container {
    width: 100%;
    display: flex;
    align-items: flex-end;
    gap: 40px;

    .input-inner-container {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 32px;
    }
  }

  @media (max-width: 700px) {
    .input-container {
      gap: 32px;
      flex-direction: column;
      align-items: center;
    }
    > .input-container > div, > button {
      width: 100%;
    }
  }
`
