import React, { useEffect, useMemo, useRef } from 'react';
import styled, { css } from 'styled-components';
import { AiOutlineCheck } from 'react-icons/ai';
import clamp from 'clamp-js';
import useVideos from 'src/pages/Videos/contexts/useVideos';
import { ReactComponent as LockKey } from 'src/assets/icons/lock-key.svg';
import { secondsToTimeText } from 'src/helpers';
import Content from 'src/models/content';

interface VideoPlayerCardContainerProps {
  selected: boolean;
}

const VideoPlayerCardContainer = styled.div<VideoPlayerCardContainerProps>`
  padding: 16px;
  display: grid;
  grid-template-columns: 120px calc(100% - 120px);
  gap: 8px;
  cursor: pointer;
  transition: all 0.3s;

  :hover {
    background: var(--hover);
  }

  ${(props) =>
    props.selected &&
    css`
      background: var(--hover);
    `}
`;

const VideoTitleAndWatchedContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 8px;
`;

const VideoWatchedContainer = styled.div`
  width: 60px;

  & .watched {
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 0.65em;
  }
`;

interface VideoWatchedProps {
  video: Content;
}

const VideoWatched: React.FC<VideoWatchedProps> = ({ video }) => {
  const { selectedContent } = useVideos();

  const videoWatched = useMemo(() => {
    return !!selectedContent?.content_user?.finish_at;
  }, [selectedContent?.content_user?.finish_at]);

  return (
    <VideoWatchedContainer>
      {videoWatched && (
        <span className="watched">
          <AiOutlineCheck /> <span>Assistido</span>
        </span>
      )}
    </VideoWatchedContainer>
  );
};

interface VideoThumbProps {
  background: string;
}

const VideoThumb = styled.div<VideoThumbProps>`
  position: relative;
  width: 120px;
  height: 80px;
  border-radius: 4px;
  overflow: hidden;

  span.duration {
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.6);
    color: var(--white);
    font-size: 0.75rem;
    padding: 1px;
  }

  ${(props) =>
    css`
      background: #000 url(${props.background}) no-repeat center center;
      background-size: cover;
    `}
`;

const VideoInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const VideoTheme = styled.span`
  color: var(--primary-color);
  font-weight: bold;
  font-size: 0.75rem;
`;

const VideoTitle = styled.h5`
  color: var(--gray-darkest);
`;

interface VideoPlayerCardProps {
  content: Content;
}

const ThumbnailContentBlocked = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  z-index: 3;

  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 24px;
    height: 24px;
    position: relative;
  }

  ::before {
    content: '';
    width: 48px;
    height: 48px;
    position: absolute;
    background-color: var(--primary-color);
    border-radius: 100%;
  }
`;

const VideoPlayerCard: React.FC<VideoPlayerCardProps> = ({ content }) => {
  const titleHeading = useRef<HTMLHeadingElement>(null);
  const themeSpan = useRef<HTMLSpanElement>(null);
  const { allThemes, changeSelectedContent, selectedContent } = useVideos();

  const handleClick = () => {
    changeSelectedContent(content);
  };

  const theme = useMemo(() => {
    return (allThemes || []).find((t) => t.content_id === content.themeId);
  }, [allThemes, content.themeId]);

  useEffect(() => {
    if (titleHeading.current) {
      clamp(titleHeading.current, { clamp: 2 });
    }

    if (themeSpan.current) {
      clamp(themeSpan.current, { clamp: 1 });
    }
  }, [titleHeading, themeSpan]);

  return (
    <VideoPlayerCardContainer
      onClick={handleClick}
      selected={selectedContent?.content_id === content.content_id}
    >
      <VideoThumb background={content.images.thumbnail}>
        {!content?.info?.is_free && (
          <ThumbnailContentBlocked>
            <LockKey />
          </ThumbnailContentBlocked>
        )}
        <span className="duration">{secondsToTimeText(content.duration)}</span>
      </VideoThumb>
      <VideoInfo>
        {!!theme && <VideoTheme ref={themeSpan}>{theme?.name}</VideoTheme>}
        <VideoTitleAndWatchedContainer>
          <VideoTitle ref={titleHeading}>{content.name}</VideoTitle>
          <VideoWatched video={content} />
        </VideoTitleAndWatchedContainer>
      </VideoInfo>
    </VideoPlayerCardContainer>
  );
};

export default VideoPlayerCard;
