import styled from 'styled-components';

export const ValidateCertificateContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-bottom: 32px;

  h1 {
    font-size: 46px;
    font-weight: 700;
    line-height: 72px;
    color: var(--text-color);
  }

  header {
    display: flex;
    padding: 24px;
  }

  main {
    display: flex;
    gap: 56px;
    width: fit-content;
    margin: 0 auto;
    padding: 24px;

    .formContainer {
      width: 100%;
      max-width: 450px;
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 56px;
    }

    .dataContainer {
      padding-right: 40px;
    }
  }

  .vertical-bar {
    height: 434px;
    border-right: solid 1px var(--border-gray);
  }

  @media screen and (max-width: 1000px) {
    header {
      justify-content: center;
    }

    h1 {
      font-size: 32px;
    }

    main {
      flex-direction: column;
      gap: 40px;

      .formContainer {
        gap: 32px;
      }

      .input-container {
        width: calc(100% - 48px);
      }

      button {
        width: calc(100% - 48px);
      }
    }

    .vertical-bar {
      height: 0;
      width: calc(100% - 48px);
      border-top: solid 1px var(--border-gray);
    }
  }
`
