import React, { useCallback, useEffect, useRef, useState } from 'react';
import clamp from 'clamp-js';
import {
  ContinueWatching,
  ProgressContainer,
  ReWatch,
  ThumbnailContentBlocked,
  VideoCardContainer,
  VideoDescription,
  VideoTheme,
  VideoThemesContainer,
  VideoThumbnail,
  VideoTitle,
} from './style';
import useUser from 'src/contexts/UserContext/useUser';
import Content from 'src/models/content';
import { ReactComponent as ProgressPlayIcon } from 'src/assets/progress-play-icon.svg';
import { ReactComponent as ReWatchIcon } from 'src/assets/re-watch-icon.svg';
import { ReactComponent as LockKey } from 'src/assets/icons/lock-key.svg';

interface VideoCardProps {
  video: Content;
  inProgress?: boolean;
  isFinished?: boolean;
}

const VideoCard: React.FC<VideoCardProps> = ({ video, inProgress, isFinished }) => {
  const videoTitleRef = useRef<HTMLHeadingElement>(null);

  const { userPlan, navigate } = useUser()

  const [hovered, setHovered] = useState(false);

  const videoIsFree = !!video.info?.is_free
  const isContentBlocked = !videoIsFree && !userPlan?.planPremium

  const goToVideo = useCallback(async () => {
    navigate(`/videos/content/${video.content_id}`);
  }, [navigate, video.content_id]);

  const handleMouseEnter = useCallback(() => {
    setHovered(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setHovered(false);
  }, []);

  useEffect(() => {
    if (videoTitleRef.current) {
      clamp(videoTitleRef.current, { clamp: 2 });
    }
  }, []);

  return (
    <VideoCardContainer
      className="video-card-container"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={goToVideo}
    >
      <VideoThumbnail src={video.images?.thumbnail} hovered={hovered} />

      <VideoThemesContainer>
        {!!video.tags?.length && video.tags.map((tag) => (
          <VideoTheme key={tag.tag_id}>{tag.title}</VideoTheme>
        ))}
      </VideoThemesContainer>

      <VideoTitle ref={videoTitleRef} hovered={hovered}>
        {video.name}
      </VideoTitle>

      {hovered && (
        inProgress || isFinished
          ? (
            inProgress
              ? (
                <ContinueWatching>
                  <span className="icon">
                    <ProgressPlayIcon />
                  </span>
                  <span className="text">Continue assistindo</span>{' '}
                  <span className="percent">
                    {Math.floor(video.progress || 0)}%
                  </span>
                </ContinueWatching>
              )
              : isFinished && (
                <ReWatch>
                  <span className="icon">
                    <ReWatchIcon />
                  </span>
                  <span className="text">Reassistir</span>
                  <span className="percent">Concluído</span>
                </ReWatch>
              )
          )
          : (
            <VideoDescription
              dangerouslySetInnerHTML={{ __html: video.description }}
            />
          ))}

      {inProgress && (
        <ProgressContainer progress={video.progress || 0}>
          <span className="progress"></span>
        </ProgressContainer>
      )}

      {isFinished && (
        <ProgressContainer progress={100}>
          <span className="progress"></span>
        </ProgressContainer>
      )}

      {isContentBlocked && (
        <ThumbnailContentBlocked>
          <LockKey />
        </ThumbnailContentBlocked>
      )}
    </VideoCardContainer>
  );
};

export default VideoCard;
