import styled from 'styled-components'

export const ModalWrapper = styled.div`
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
`

export const ModalBackdrop = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.35);
`

export const ModalContent = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background: white;
  position: relative;
  overflow: auto;

  @media screen and (min-width: 640px) {
    max-width: 1024px;
    max-height: 80vw;
  }
`
