import httpClient from 'src/http-client'
import Theme from 'src/models/theme'
import Paginated from 'src/models/paginated'

export const getThemesService = async (filters?: any) => {
  const relations = [...['segmentations'], ...(filters?.relations || [])]

  const { data } = await httpClient.get<Paginated<Theme>>(`/contents`, {
    params: {
      ...filters,
      is_active: true,
      flag: ['theme'],
      relations,
    },
  })

  return data
}

export const getThemeService = async (themeId: string, filters?: any) => {
  const relations = ['children', ...(filters?.relations || [])]

  const { data } = await httpClient.get<Theme>(`/contents/${themeId}`, {
    params: {
      ...filters,
      relations,
    },
  })

  return data
}

