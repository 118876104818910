import styled from 'styled-components';

const FooterContainer = styled.footer`
  background-color: var(--secondary-color);
  padding: 30px;
  width: 100%;
`;

const FooterContent = styled.div`
  display: flex;
  flex-direction: column;
  color: var(--white);
  gap: 50px;
  font-size: 14px;

  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > svg {
      height: 40px;
      width: fit-content;
    }

    @media (max-width: 640px) {
      justify-content: center;
    }
  }

  .legal {
    @media (max-width: 640px) {
      text-align: center;
      display: flex;
      flex-direction: column-reverse;
    }
  }

  .socialMedias {
    display: flex;
    gap: 12px;

    svg {
      width: 36px;
    }
  }

  .rightSideElements {
    display: flex;
    flex-direction: row;
    gap: 52px;

    a {
      color: inherit;
    }

    @media (max-width: 640px) {
      flex-direction: column;
    }
  }

  @media (max-width: 1000px) {
    flex-wrap: wrap;

    & > div {
      flex-wrap: wrap;
      gap: 24px;
    }

    .rightSideElements {
      flex-wrap: wrap;
      gap: 24px;
      text-decoration: underline;
    }
  }
`;

export { FooterContainer, FooterContent };
