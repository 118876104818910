import styled from 'styled-components';

const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-bottom: 32px;
`;

const LoginHeader = styled.div`
  display: flex;
  padding: 24px 0;

  @media screen and (max-width: 640px) {
    justify-content: center;
    padding: 24px 16px
  }
`;

const SideAndFormContainer = styled.div`
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 32px;

  @media (max-width: 770px) {
    flex-direction: column;
    gap: 40px;
  }
`;

const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: stretch;
  justify-content: center;
  gap: 32px;
  max-width: 446px;
  margin: auto;

  @media (max-width: 770px) {
    width: 100%;
    gap: 20px;
  }
`;

const SignUpLinkMobile = styled.span`
  display: none;
  font-size: 14px;
  line-height: 24px;
  color: var(--text-color);
  margin-top: 30px;

  @media screen and (max-width: 640px) {
    display: inline;
  }

  a {
    text-decoration: underline;
    font-weight: 600;
    cursor: pointer;

    :link,
    :visited,
    :hover,
    :active {
      color: var(--text-color);
    }
  }
`;

const LoginFormButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;

  @media (max-width: 770px) {
    flex-direction: column-reverse;
    align-items: center;
    gap: 16px;
  }

  button {
    height: 48px;
    width: 216px;
  }

  a {
    font-size: 14px;
    text-decoration: underline;
    color: var(--text-color);
  }

  @media (max-width: 770px) {
    button {
      width: 100%;
    }
  }
`;

export {
  LoginContainer,
  LoginForm,
  SideAndFormContainer,
  LoginHeader,
  LoginFormButtonGroup,
  SignUpLinkMobile
};
