import styled from 'styled-components';

interface FormButtonProps {
  redOnWhite?: boolean;
}

const FormButton = styled.button<FormButtonProps>`
  width: 324px;
  min-height: 44px;
  background: ${({ redOnWhite }) => redOnWhite ? 'none' : 'var(--primary-color)'};
  border: ${({ redOnWhite }) => redOnWhite ? '1px solid var(--primary-color)' : 'none'};
  color: ${({ redOnWhite }) => redOnWhite ? 'var(--primary-color)' : 'white'};
  border-radius: 6px;
  font-family: Barlow;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 1px;
  text-transform: uppercase;
`;

export default FormButton;
