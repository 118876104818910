import styled from 'styled-components';

const MyProfileContainer = styled.div`
  width: 100%;
  max-width: 1400px;
  min-height: 396px;
  margin: 24px auto 80px;
  padding: 0 24px;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  flex-wrap: wrap;
  gap: 20px;

  @media (max-width: 1000px) {
    margin-top: 0;
  }
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;

  .main-title,
  .subtitle,
  .section-title {
    color: var(--text-color);
  }

  .main-title,
  .section-title {
    line-height: 40px;
  }

  .main-title {
    font-size: 38px;
  }

  .subtitle {
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
  }

  .section-title {
    font-size: 24px;
  }

  @media (max-width: 1000px) {
    padding: 0 16px;
    flex-basis: 800px;
  }
`;

export { MyProfileContainer, Content };
