import { useMemo } from 'react'
import SwiperCore, { Pagination, Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js'
import { SwiperContainer } from './style'
import useWindowSize from 'src/contexts/WindowSizeContext/useWindowSize'
import 'swiper/swiper-bundle.min.css'

SwiperCore.use([Pagination, Navigation])

interface ILandingCarousel {
  items?: JSX.Element[]
}

export function LandingCarousel({ items }: ILandingCarousel) {
  const { windowWidth } = useWindowSize()

  const slidesPerView = useMemo(() => {
    if (windowWidth > 1200) {
      return 4.0
    } else if (windowWidth > 1000) {
      return 2.9
    } else if (windowWidth > 768) {
      return 2.4
    } else if (windowWidth > 540) {
      return 1.7
    } else {
      return 1.2
    }
  }, [windowWidth])

  if (!items) return null

  return (
    <SwiperContainer>
      <Swiper
        slidesPerView={slidesPerView}
        spaceBetween={24}
        slidesPerGroup={1}
        navigation={true}
        mousewheel={true}
        className="mySwiper"
      >
        {items.map((item) => (
          <SwiperSlide key={item.key}>{item}</SwiperSlide>
        ))}
      </Swiper>
    </SwiperContainer>
  )
}
