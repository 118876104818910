import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import { AiOutlineDownload } from 'react-icons/ai';
import styled from 'styled-components';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import clamp from 'clamp-js';
import { BsDownload } from 'react-icons/bs';
import Button from 'src/components/Button';
import useVideos from 'src/pages/Videos/contexts/useVideos';
import useLoading from 'src/contexts/LoadingContext/useLoading';
import {
  downloadCertificateService,
} from 'src/services/certificatesService';
import { showErrorMessage } from 'src/helpers';
import ExtraMaterial from 'src/models/extra-material';
import SystemError from 'src/models/error';
import { ReactComponent as DownArrow } from 'src/assets/down-arrow.svg';
import { ReactComponent as UpArrow } from 'src/assets/up-arrow.svg';
import 'react-tabs/style/react-tabs.css';

const ContentTabsContainer = styled.div`
  display: flex;
  flex-direction: column;

  .react-tabs {
    ul.react-tabs__tab-list {
      border-color: #999;
      color: #333;

      li.react-tabs__tab {
        &.react-tabs__tab--selected {
          border: none;
          border-bottom: solid 4px var(--primary-color);
          color: var(--primary-color);
          font-weight: 500;
          background: transparent;
        }
      }
    }
  }
`;

const ExtraMaterials = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const DescriptionContainer = styled.div`
  p {
    font-size: 0.9rem;
    line-height: 1.45rem;
  }

  button {
    border: none;
    outline: none;
    background: none;
    color: var(--primary-color);
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    margin-top: 16px;
  }
`;

const NoExtraMaterialMessage = styled.p``;

const DownloadCertButton = styled(Button)`
  width: 100%;
  background: var(--primary-color);
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  border-radius: 2px;
`;

interface DescriptionProps {
  description: string | undefined;
}

const Description: React.FC<DescriptionProps> = ({ description }) => {
  const [showingAll, setShowingAll] = useState(false);
  const descriptionParagraph = useRef<HTMLParagraphElement>(null);

  const showMore = () => {
    setShowingAll(true);
  };

  const showLess = () => {
    setShowingAll(false);
  };

  useEffect(() => {
    if (descriptionParagraph.current) {
      if (showingAll) {
        clamp(descriptionParagraph.current, { clamp: Number.MAX_SAFE_INTEGER });
      } else {
        clamp(descriptionParagraph.current, { clamp: 8 });
      }
    }
  }, [descriptionParagraph, showingAll]);

  return (
    <DescriptionContainer>
      <p
        ref={descriptionParagraph}
        dangerouslySetInnerHTML={{ __html: description || '' }}
      ></p>

      {showingAll ? (
        <button type="button" className="show-less" onClick={showLess}>
          <span>Ver menos</span>
          <UpArrow />
        </button>
      ) : (
        <button type="button" className="show-more" onClick={showMore}>
          <span>Ver mais</span>
          <DownArrow />
        </button>
      )}
    </DescriptionContainer>
  );
};

const ExtraMaterialCardContainer = styled.a`
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.3s;
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
  border: solid 1px var(--gray-darker);
  text-decoration: none;
  color: var(--gray-darken);

  :hover {
    background: var(--hover);
  }
`;

interface ExtraMaterialCardProps {
  extraMaterial: ExtraMaterial;
}

const ExtraMaterialCard: React.FC<ExtraMaterialCardProps> = ({
  extraMaterial,
}) => {
  return (
    <ExtraMaterialCardContainer
      download
      target="_blank"
      href={extraMaterial.reference}
    >
      <span className="title">{extraMaterial.title}</span>
      <BsDownload />
    </ExtraMaterialCardContainer>
  );
};

const ContentTabs: React.FC = () => {
  const { setIsLoading } = useLoading();
  const { specialty, selectedContent } = useVideos();

  const handleDownloadCertificate = async () => {
    setIsLoading(true);

    try {
      const videoWatched = !!selectedContent?.content_user?.finish_at;

      if (!videoWatched) {
        throw new Error(
          'Não é possível emitir o certificado de conteúdos ainda não assistidos!'
        )
      }

      const { content_id, name } = selectedContent

      if (window.ReactNativeWebView?.postMessage) {
        const data = {
          downloadCertificate: {
            contentId: content_id,
            name,
          }
        };
        window.ReactNativeWebView?.postMessage(JSON.stringify(data));
      }else{
        await downloadCertificateService(content_id, name)
      }
    } catch (error) {
      showErrorMessage(error as SystemError);
    }

    setIsLoading(false);
  }

  return (
    <ContentTabsContainer>
      <Tabs>
        <TabList>
          <Tab>Descrição</Tab>
          {!!selectedContent && <Tab>Material complementar</Tab>}
          {!!selectedContent?.content_user?.finish_at && <Tab>Certificado</Tab>}
        </TabList>

        <TabPanel>
          <Description
            description={selectedContent?.description || specialty?.description}
          />
        </TabPanel>
        {!!selectedContent && (
          <TabPanel>
            <ExtraMaterials>
              {selectedContent.extra_materials?.length ? (
                selectedContent.extra_materials.map((em) => (
                  <ExtraMaterialCard
                    key={em.extra_material_id}
                    extraMaterial={em}
                  />
                ))
              ) : (
                <NoExtraMaterialMessage>
                  Não foram encontrados materiais complementares para este
                  vídeo.
                </NoExtraMaterialMessage>
              )}
            </ExtraMaterials>
          </TabPanel>
        )}
        {!!selectedContent?.content_user?.finish_at && (
          <TabPanel>
            <DownloadCertButton type="button" onClick={handleDownloadCertificate}>
              <AiOutlineDownload />
              <span>Baixar Certificado</span>
            </DownloadCertButton>
          </TabPanel>
        )}
      </Tabs>
    </ContentTabsContainer>
  );
};

export default ContentTabs;
