import React, { useRef, useState } from 'react';
import {
  CutImageContainer,
  FileInputContainer,
  SelectImageButton,
  ButtonCancel,
  ButtonContinue
} from './style';
import { Cropper } from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { showErrorMessage } from 'src/helpers';
import SystemError from 'src/models/error';
import {
  CreationForm,
  CreationFormButtonGroup,
  CreationFormGroup,
} from '../Forms';

interface ImageData {
  src: string;
  extension: string;
}

interface CutImageProps {
  onCutImage: (file: File) => void;
  aspect: number;
  modalId?: string;
  close: (value: boolean) => void;
}

const CutImage: React.FC<CutImageProps> = ({ aspect, onCutImage, close }) => {
  const [cropper, setCropper] = useState<Cropper>();
  const [initialImage, setInitialImage] = useState({
    src: '',
    extension: '',
  } as ImageData);

  const imageExtensions = ["jpg", "jpeg", "png"];

  const fileInput = useRef<HTMLInputElement>(null);

  const selectImage = () => {
    if (fileInput.current) {
      fileInput.current.click();
    }
  };

  const handleFileInput = (event: React.FormEvent<HTMLInputElement>) => {
    event.preventDefault();

    const currentTarget = event.currentTarget;

    if (currentTarget && currentTarget.files && currentTarget.files.length) {
      try {
        let file =
          currentTarget.files && currentTarget.files.length
            ? currentTarget.files[0]
            : null;

        if (file) {
          const lastIndexOfDot = file.name.lastIndexOf('.');
          if (lastIndexOfDot > -1) {
            const extension = file.name.slice(lastIndexOfDot + 1);
            if (!imageExtensions.includes(extension)) {
              throw new Error('Arquivo inválido informado');
            }
          }

          let fr = new FileReader();

          fr.onloadend = () => {
            setInitialImage((image) => ({
              src: `${fr.result}`,
              extension: (file && file.type) || '',
            }));
          };

          fr.readAsDataURL(file);
        }
      } catch (e) {
        showErrorMessage(e as SystemError);
      }
    }
  };

  const getCroppedImage = () => {
    if (cropper?.getCroppedCanvas()) {
      return cropper.getCroppedCanvas().toDataURL();
    }

    return '';
  };

  const cutImage = (event: React.FormEvent) => {
    event.preventDefault();

    const croppedImage = getCroppedImage();
    const croppedFile = dataURLtoFile(
      croppedImage,
      `thumbnail.${initialImage.extension}`
    );

    onCutImage(croppedFile);
    close(false);
  };

  function dataURLtoFile(dataurl: string, filename: string) {
    let arr = dataurl.split(',');

    let mime = null;
    if (arr && arr.length) {
      if (arr[0]) {
        mime = arr[0].match(/:(.*?);/);
        if (mime && mime.length > 1) {
          mime = mime[1];
        }
      }
    }

    let bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: `${mime}` });
  }

  return (
    <CutImageContainer>
      <CreationForm onSubmit={cutImage}>
        <CreationFormGroup>
          <label htmlFor="select-image" id="title">Visualize e corte a imagem:</label>
          <FileInputContainer>
            <input
              ref={fileInput}
              type="file"
              id="select-image"
              accept="image/*"
              onChange={handleFileInput}
            />
            <SelectImageButton type="button" onClick={selectImage}>
              Selecionar
            </SelectImageButton>
          </FileInputContainer>

          <div style={{ flex: 1 }}>
            <Cropper
              src={initialImage.src}
              style={{ height: '45vh' }}
              aspectRatio={aspect}
              guides={false}
              onInitialized={(instance) => {
                setCropper(instance);
              }}
            />
          </div>

          <CreationFormButtonGroup>
            <ButtonCancel type="button" onClick={() => close(false)}>
              Cancelar
            </ButtonCancel>
            <ButtonContinue disabled={!fileInput.current?.value}>Cortar imagem</ButtonContinue>
          </CreationFormButtonGroup>
        </CreationFormGroup>
      </CreationForm>
    </CutImageContainer>
  );
};

export default CutImage;
