import useUser from 'src/contexts/UserContext/useUser';
import Button from '../../components/Button';


export const PartnerLink: React.FC<any> = ({ children, to }) => {
  const { navigate } = useUser();

  return (
    <Button type="button" onClick={() => navigate(to)}>
      {children}
    </Button>
  );
};

export default PartnerLink;
