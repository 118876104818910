import { BrowserRouter as Router } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import Routes from 'src/routes/index'
import { Loading } from 'src/components/Loading'
import { AppLayout } from 'src/layouts/AppLayout'
import AppContextProvider from 'src/contexts/AppContext/AppContextProvider'
import UserContextProvider from 'src/contexts/UserContext/UserContextProvider'
import ThemesContextProvider from 'src/contexts/ThemesContext/ThemesContextProvider'
import SearchContextProvider from 'src/contexts/SearchContext/SearchContextProvider'
import LoadingContextProvider from 'src/contexts/LoadingContext/LoadingContextProvider'
import WindowSizeContextProvider from 'src/contexts/WindowSizeContext/WindowSizeContextProvider'
import MobileMenuContextProvider from 'src/contexts/MobileMenuContext/MobileMenuContextProvider'
import 'react-toastify/dist/ReactToastify.css'

export function App() {
  return (
    <Router>
      <UserContextProvider>
        <AppContextProvider>
          <ThemesContextProvider>
            <SearchContextProvider>
              <WindowSizeContextProvider>
                <MobileMenuContextProvider>
                  <LoadingContextProvider>
                    <AppLayout>
                      <Routes />
                    </AppLayout>
                    <ToastContainer position="bottom-right" />
                    <Loading />
                  </LoadingContextProvider>
                </MobileMenuContextProvider>
              </WindowSizeContextProvider>
            </SearchContextProvider>
          </ThemesContextProvider>
        </AppContextProvider>
      </UserContextProvider>
    </Router>
  )
}
