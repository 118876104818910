import { createContext, Dispatch, SetStateAction } from 'react';

interface IAppContext {
  showUserContextMenu: boolean;
  setShowUserContextMenu: Dispatch<SetStateAction<boolean>>;
}

const AppContext = createContext({} as IAppContext);

export default AppContext;
