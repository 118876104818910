import { getContentService } from 'src/services/contentsService'
import { getSpecialtyService } from 'src/services/specialtiesService'
import Content from 'src/models/content'
import { ISpecialty } from 'src/models/specialty'

export const getContentProgress = async (content: Content): Promise<Content> => {
  const fullContent = await getContentService(content.content_id)

  const contentView = fullContent.content_user?.content_view || 0

  const duration = fullContent.duration

  const progress = (contentView / duration) * 100

  return { ...fullContent, progress }
}

export const getSpecialtyProgress = async (
  specialty_content_id: string
): Promise<ISpecialty> => {
  const fullSpecialty = (await getSpecialtyService(specialty_content_id)) || ({} as ISpecialty)

  const fullSpecialtyThemes = fullSpecialty.children || []

  const fullSpecialtyContents =
    fullSpecialtyThemes.map((t) => t.children || []).flat() || []

  const numberOfFinishedContents = (
    fullSpecialtyContents.filter((c) => !!c.content_user?.finish_at) || []
  ).length

  const numberOfContents = fullSpecialtyContents.length

  const progress = (numberOfFinishedContents / numberOfContents) * 100

  return { ...fullSpecialty, progress }
}

