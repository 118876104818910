import styled from 'styled-components';

const GoBackContainer = styled.div`
  button {
    font-size: 1rem;
    align-items: center;
    color: var(--white);
  }

  @media screen and (max-width: 640px) {
    display: none;
  }
`;

export { GoBackContainer };
