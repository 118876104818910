import styled, { css } from 'styled-components'

export const ThemeCardContainer = styled.div`
  width: 190px;
  height: 150px;
  padding: 16px;
  border-radius: 4px;
  background: var(--secondary-color);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: var(--white);
  cursor: pointer;

  @keyframes from-down-to-up-theme {
    from {
      transform: translateY(20%);
    }
    to {
      transform: translateY(0%);
    }
  }

  & .icon-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
`

interface ThemeTitleProps {
  hovered: boolean;
}

export const ThemeTitle = styled.h3<ThemeTitleProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-weight: 500;
  text-align: left;

  ${({ hovered }) => hovered &&
    css`
      animation: from-down-to-up-theme 0.5s;
    `}
`

export const ThemeWatch = styled.p`
  font-weight: thin;
  font-size: 0.8em;
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 8px;
  animation: from-down-to-up-theme 0.5s;
`
