import React from 'react';
import { ReactComponent as Logo } from 'src/assets/logos/logo-manole-play-white.svg';
import { ReactComponent as Facebook } from 'src/assets/socialMedia/facebook-icon.svg';
import { ReactComponent as Instagram } from 'src/assets/socialMedia/instagram-icon.svg';
import { ReactComponent as Twitter } from 'src/assets/socialMedia/twitter-icon.svg';
import { FooterContainer, FooterContent } from './style';

const Footer: React.FC = () => {
  return (
    <FooterContainer>
      <FooterContent>
        <div>
          <Logo />
          <div className="socialMedias">
            <a
              href="https://www.facebook.com/EditoraManole"
              target="_blank"
              rel="noreferrer"
            >
              <Facebook />
            </a>
            <a
              href="https://www.instagram.com/oficialmanole/"
              target="_blank"
              rel="noreferrer"
            >
              <Instagram />
            </a>
            <a
              href="https://twitter.com/editora_manole"
              target="_blank"
              rel="noreferrer"
            >
              <Twitter />
            </a>
          </div>
        </div>
        <div className='legal'>
          <p>© Manole LTDA - 2022 | Todos os direitos reservados</p>
          <div className="rightSideElements">
            <a
              target="_blank"
              href="https://politicas.manole.com.br/#obtencao-e-uso-das-informacoes"
              rel="noreferrer"
            >
              <p>Termos de Uso</p>
            </a>
            <a
              target="_blank"
              href="https://politicas.manole.com.br"
              rel="noreferrer"
            >
              <p>Política de Privacidade</p>
            </a>
          </div>
        </div>
      </FooterContent>
    </FooterContainer>
  );
};

export default Footer;
