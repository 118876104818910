import styled from 'styled-components';

interface IBlockedBlockContainerProps {
  img?: string;
}

export const BlockedBlockContainer = styled.div<IBlockedBlockContainerProps>`
  position: relative;
  width: 100%;
  ${({ img }) => `background-image: url(${img});`}
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

export const BlockedOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.65);
  z-index: 99;
`;

export const CircleLock = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  background-color: var(--primary-color);
  border-radius: 100%;

  svg {
    @media (min-width: 769px) {
      width: 64px;
      height: 64px;
    }

    @media (max-width: 768px) {
      height: 48px;
      width: 48px;
    }
  }
`;
