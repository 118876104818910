import download from 'downloadjs'
import httpClient from 'src/http-client'
import { IGetValidationKeyService } from 'src/models/certificate'

export const getCertificatesService = async (params?: any) => {
  const { data } = await httpClient.get('/certificates', { params })
  return data
}

export const getValidationKeyService = async (certificateId: string) => {
  const { data } = await httpClient.get<IGetValidationKeyService>(`/certificates/${certificateId}`)
  return data
}

export const createCertificateService = async (content_id: string) => {
  const { data } = await httpClient.post('/certificates', { content_id })
  return data
}

export const validateCertificateService = async (validation_key: string) => {
  const { data } = await httpClient.get<IGetValidationKeyService>('/certificates/verify', {
    params: {
      validation_key,
    }
  })
  return data
}

export const downloadCertificateService = async (content_id: string, content_name?: string | null) => {
  try {
    const { data } = await httpClient.post('/certificates', { content_id }, {
      params: {
        download: true,
      },
      responseType: 'blob'
    })

    const contentName = (content_name || 'manole-play')
      .toLowerCase()
      .replaceAll(' ', '-')

    const filename = `certificado-${contentName}.pdf`

    download(data, filename)
  }
  catch {
    throw new Error('Erro ao gerar o certificado!')
  }
}
