import { createGlobalStyle } from 'styled-components'

interface GlobalStylesProps {
  primaryColor?: string
  secondaryColor?: string
}

export const GlobalStyles = createGlobalStyle<GlobalStylesProps>`
  :root {
    --primary-color: ${({ primaryColor }) => primaryColor ? primaryColor : '#EF7D52'};
    --secondary-color: ${({ secondaryColor }) => secondaryColor ? secondaryColor : '#110982'};
    --text-color: #293374;
    --background-white: #fff;
    --background-app: #f2f2f2;

    --white: #fff;
    --white-cool: #f7fafc;
    --gray-darkest: #25282b;
    --gray-darker: #657787;
    --gray-lighter: #e5e5e5;
    --border-gray: #cfd2d4;
    --hover: #1e12461a;
    --danger: #f5365c;
    --success: #4fd69c;
    --blueish: #5e72e4;
  }
`
