import { MouseEvent, useCallback, useRef } from 'react';
import { BiUserCircle } from 'react-icons/bi';
import { FiLogOut } from 'react-icons/fi';
import { IoIosArrowDown } from 'react-icons/io';
import Button from 'src/components/Button';
import {
  DropdownButton,
  DropdownContainer,
  DropdownNav,
  UserAvatarContainer,
} from './style';
import useApp from 'src/contexts/AppContext/useApp';
import useUser from 'src/contexts/UserContext/useUser';
import PartnerLink from 'src/components/PartnerLink';

interface IDropdownHeader {
  openModalPartners: () => void
}

export function DropdownHeader({ openModalPartners }: IDropdownHeader) {
  const dropdownRef = useRef<HTMLButtonElement>(null);
  const { showUserContextMenu, setShowUserContextMenu } = useApp();

  const { logout, user } = useUser();
  const userAvatar = user?.images?.avatar

  const logUserOut = useCallback(() => {
    logout()
  }, [logout])

  const handleVisibilityToggle = (event: MouseEvent) => {
    event.stopPropagation();
    setShowUserContextMenu(oldValue => !oldValue);
  };

  return (
    <DropdownContainer>
      <DropdownButton onClick={handleVisibilityToggle}>
        {userAvatar
          ? (
            <UserAvatarContainer>
              <img src={userAvatar} alt={`${user?.name}'s avatar`} />
            </UserAvatarContainer>
          )
          : (
            <BiUserCircle size={36} />
          )
        }

        <span>{user?.name || 'Desconhecido'}</span>

        <IoIosArrowDown />
      </DropdownButton>

      <DropdownNav ref={dropdownRef} isActive={showUserContextMenu}>
        <ul>
          <li>
            <PartnerLink to="/my-progress">
              Meu progresso
            </PartnerLink>
          </li>
          <li>
            <PartnerLink to="/my-list">
              Minha Lista
            </PartnerLink>
          </li>
          <li>
            <PartnerLink to="/my-profile">
              Meu perfil
            </PartnerLink>
          </li>
          <li>
            <Button type="button" onClick={openModalPartners}>
              Trocar o parceiro
            </Button>
          </li>
          <li>
            <Button type="button" className="logout-button" onClick={logUserOut}>
              <span>
                <FiLogOut />
                Sair
              </span>
            </Button>
          </li>
        </ul>
      </DropdownNav>
    </DropdownContainer>
  );
};

export default DropdownHeader;
