import styled from 'styled-components';
import Button from 'src/components/Button';

const HeaderWrapper = styled.div`
  width: 100%;
  top: 0;
  left: 0;
  z-index: 9;
`;

const HeaderContainer = styled.header`
  height: 80px;
  width: 100%;
  background: var(--background-white);
  display: flex;
  align-items: center;
`;

const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const HeaderLinks = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  margin: 0 32px;
`;

const MobileHeaderLinks = styled.div`
  display: none;

  @media screen and (max-width: 1000px) {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex: 1;
    background-color: white;
    border-top: 1px solid #29337433;
    padding-bottom: 8px;
  }
`;

const MobileHeaderLink = styled(Button)<HeaderLinkProps>`
  font-weight: 600;
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
  line-height: 12px;
  padding: 16px;
  color: ${({ active }) =>
    active ? 'var(--primary-color)' : 'var(--text-color)'};
  border-bottom: ${({ active }) =>
    active ? '2px solid var(--primary-color)' : '2px solid transparent'};
`;

interface HeaderLinkProps {
  active: boolean;
}

const HeaderLink = styled(Button)<HeaderLinkProps>`
  font-weight: 600;
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;
  line-height: 24px;
  padding: 16px;
  color: ${({ active }) =>
    active ? 'var(--primary-color)' : 'var(--text-color)'};
  border-bottom: ${({ active }) =>
    active ? '2px solid var(--primary-color)' : '2px solid transparent'};
`;

const MobileMenuOpenButton = styled(Button)``;

export {
  HeaderContainer,
  HeaderLinks,
  HeaderContent,
  HeaderLink,
  MobileMenuOpenButton,
  MobileHeaderLinks,
  HeaderWrapper,
  MobileHeaderLink,
};
