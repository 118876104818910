import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import urljoin from 'url-join';
import UserContext from 'src/contexts/UserContext/UserContext';
import { getUserService, putUserService } from 'src/services/usersService';
import {
  loginService,
  refreshTokenService,
  signUpService,
} from 'src/services/sessionsService';
import { ILoginCredentials } from 'src/models/loginModel';
import { IPlan, IUser, IUserPost, IUserPut } from 'src/models/userModel';
import Partner from 'src/models/partner';
import { IUserSpecialty } from 'src/models/specialty';
import { GlobalStyles } from 'src/styles/globalStyles';

const UserContextProvider: React.FC = ({ children }) => {
  const location = useLocation();
  const goTo = useNavigate();

  const [user, setUser] = useState<IUser>();
  const [userPlan, setUserPlan] = useState<IPlan>();
  const [userPartners, setUserPartners] = useState<Partner[]>();
  const [userSpecialties, setUserSpecialties] = useState<IUserSpecialty[]>();

  const [selectedPartner, setSelectedPartner] = useState<Partner>();

  const isAuthenticated = useMemo(() => { return !!user; }, [user]);

  const navigate = useCallback(
    (url: string) => {
      const urlPrefix = `/${selectedPartner?.url_complement || 'manole'}`;
      const fullPathname = urljoin(urlPrefix, url);
      goTo(fullPathname)
    },
    [selectedPartner, goTo]
  );

  const setDefaultPartner = (partnersArgs: Partner[]) => {
    let partnerToSet: Partner | undefined;
    let storedPartner = localStorage.getItem('manole-partner-data');

    try {
      if (!storedPartner || ['null', 'undefined'].includes(storedPartner)) {
        throw new Error();
      }

      partnerToSet = JSON.parse(storedPartner);
    } catch (e) {
      partnerToSet = partnersArgs?.find((pt) =>
        [
          process.env.REACT_APP_MANOLE_UUID,
          '8f5caaa2-3981-4e83-84f9-44f488cca3b3',
        ].includes(pt.segmentation_item_id)
      );
    } finally {
      setSelectedPartner(partnerToSet);

      if (partnerToSet) {
        const partnerJson = JSON.stringify(partnerToSet)
        localStorage.setItem('manole-partner-data', partnerJson);
      }
    }
  };

  const changePartner = (partnerToChange: Partner) => {
    const { partner_id, url_complement } = partnerToChange

    if (partner_id === selectedPartner?.partner_id) return

    setSelectedPartner(partnerToChange);
    localStorage.setItem('manole-partner-data', JSON.stringify(partnerToChange));

    goTo(`/${url_complement}/home`);
  };

  const getUser = useCallback(async () => {
    try {
      const { user, plan, partners, specialties } = await getUserService();

      const planStatus = String(plan?.status).toLowerCase();
      const planPremium = planStatus === 'compra';

      const mappedPlan = {
        ...plan,
        planPremium,
      }

      const mappedSpecialties = specialties.map(specialty => {
        const mappedSpecialty: IUserSpecialty = {
          id: specialty.specialty_id,
          name: specialty.specialty_name,
        }
        return mappedSpecialty
      })


      setUser(user);
      setUserPartners(partners);
      setUserPlan(mappedPlan);
      setUserSpecialties(mappedSpecialties)

      setDefaultPartner(partners);

      localStorage.setItem('manole-user', JSON.stringify(user));
    } catch (error) {
      toast.error('Informações do usuário não encontradas!');
    }
  }, []);

  useEffect(() => {
    const pathsNotLoadUser = [
      '',
      '/',
      '/login',
      '/validate-certificate',
      '/sign-up'
    ]
    if (!pathsNotLoadUser.includes(location.pathname)) {
      getUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const putUser = async (user: IUserPut): Promise<boolean> => {
    try {
      const userModified = await putUserService(user);
      setUser(userModified);

      localStorage.setItem('manole-user', JSON.stringify(user));

      return true;
    } catch (error) {
      return false;
    }
  };

  const login = useCallback(
    async (credentials: ILoginCredentials) => {
      const { token, user, refresh_token, manole_version_id } = await loginService(credentials);

      localStorage.setItem('manole-api-token', token);
      localStorage.setItem('manole-api-refresh-token', refresh_token);
      localStorage.setItem('manole-user', JSON.stringify(user));
      localStorage.setItem('manole-version-id', String(manole_version_id));

      getUser();
    },
    [getUser]
  );

  const signUp = useCallback(async (credentials: IUserPost, token?: string) => {
    const _credentials = {
      ...credentials,
      user_name: `${credentials.name?.split(' ')[0]}_${uuidv4()}`,
      partner_id:
        process.env.REACT_APP_MANOLE_UUID ||
        '8f5caaa2-3981-4e83-84f9-44f488cca3b3',
    };

    await signUpService(_credentials, token);
  }, []);

  const logout = useCallback(async () => {
    localStorage.removeItem('manole-api-token');
    localStorage.removeItem('manole-api-refresh-token');
    localStorage.removeItem('manole-user');
    localStorage.removeItem('manole-version-id');
    localStorage.removeItem('manole-partner-data');

    setUser(undefined);
    goTo('/');
  }, [goTo]);

  const refreshToken = useCallback(async () => {
    const currentRefreshToken = localStorage.getItem('manole-api-refresh-token');
    if (!currentRefreshToken) return;

    const { token, refresh_token } = await refreshTokenService(currentRefreshToken);

    localStorage.setItem('manole-api-token', token);
    localStorage.setItem('manole-api-refresh-token', refresh_token);
  }, []);

  return (
    <UserContext.Provider
      value={{
        isAuthenticated,
        login,
        signUp,
        logout,
        refreshToken,
        user,
        userPlan,
        userPartners,
        userSpecialties,
        setUser,
        selectedPartner,
        changePartner,
        putUser,
        navigate,
        getUser,
      }}
    >
      <GlobalStyles
        primaryColor={selectedPartner?.color?.primary}
        secondaryColor={selectedPartner?.color?.secondary}
      />
      {children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
