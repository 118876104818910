import { useState, useEffect, FormEvent } from 'react'
import { toast } from 'react-toastify'
import { BiUserCircle } from 'react-icons/bi'
import FormInput from 'src/components/FormInput'
import FormButton from 'src/components/FormButton/style'
import { FormMultiselect } from 'src/components/FormMultiselect'
import CutImage from 'src/components/CutImage'
import { Modal } from 'src/components/Modal'
import { PersonalDataContainer, NameAndPicture, Form } from './style'
import { uploadFileService } from 'src/services/filesService'
import { getPublicSpecialtiesService } from 'src/services/specialtiesService'
import { addSpecialtiesToUserService, removeSpecialtiesToUserService } from 'src/services/usersService'
import useUser from 'src/contexts/UserContext/useUser'
import useLoading from 'src/contexts/LoadingContext/useLoading'
import { IUser, IUserPut } from 'src/models/userModel'
import { IPublicSpecialty, IUserSpecialty } from 'src/models/specialty'
import SystemError from 'src/models/error'
import { checkStringIsEmpty, Mask, showErrorMessage, validateBirthDate } from 'src/helpers'
import { ReactComponent as Camera } from 'src/assets/icons/camera.svg'

const personalDataDefault: IUserPut = {
  name: '',
  last_name: '',
  info: {
    birth_date: '',
    phone: '',
    address: '',
    cep: '',
    number: '',
    district: '',
    city: '',
    state: '',
  },
  images: {
    avatar: '',
  }
}

const keysInfoArray = Object.keys(personalDataDefault.info)

interface IViewImageProp {
  view: string
  file: File
}

export function PersonalData() {
  const { user, putUser, userSpecialties } = useUser()
  const { setIsLoading } = useLoading()

  const [personalData, setPersonalData] = useState<IUserPut>(personalDataDefault)
  const [avatarToUpload, setAvatarToUpload] = useState<IViewImageProp | null>(null)
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [imageUploderOpen, setImageUploderOpen] = useState(false)

  const [specialties, setSpecialties] = useState<IPublicSpecialty[]>()
  const [selectedSpecialties, setSelectedSpecialties] = useState<IPublicSpecialty[]>()

  const getPublicSpecialties = async () => {
    const data = await getPublicSpecialtiesService()
    setSpecialties(data)
  }

  useEffect(() => { getPublicSpecialties() }, [])
  useEffect(() => { setSelectedSpecialties(userSpecialties) }, [userSpecialties])

  const setUserPersonalData = (user: IUser) => {
    setPersonalData({
      name: user.name,
      last_name: user.last_name,
      info: {
        birth_date: user.info.birth_date || '',
        phone: user.info.phone || '',
        address: user.info.address || '',
        cep: user.info.cep || '',
        number: user.info.number || '',
        district: user.info.district || '',
        city: user.info.city || '',
        state: user.info.state || '',
      },
      images: {
        avatar: user.images.avatar || '',
      }
    })
  }

  useEffect(() => {
    if (user) setUserPersonalData(user)
  }, [user])

  const handleChange = (propName: string, newValue: string) => {
    const isPropInfo = keysInfoArray.includes(propName)

    if (!isPropInfo) {
      setPersonalData((oldValue: IUserPut) => ({
        ...oldValue,
        [propName]: newValue,
      }))
    } else {
      setPersonalData((oldValue: IUserPut) => ({
        ...oldValue,
        info: {
          ...oldValue.info,
          [propName]: newValue,
        },
      }))
    }
  }

  const onCutImage = (image: File) => {
    let fileReader = new FileReader()

    fileReader.onloadend = () => {
      setAvatarToUpload(() => ({
        view: `${fileReader.result}`,
        file: image,
      }))
    }

    fileReader.readAsDataURL(image)
  }

  const validateForm = (): boolean => {
    try {
      if (checkStringIsEmpty(personalData.name)) {
        throw new Error('Informe seu nome!')
      }

      if (checkStringIsEmpty(personalData.last_name)) {
        throw new Error('Informe seu sobrenome!')
      }

      if (checkStringIsEmpty(personalData.info.birth_date)) {
        throw new Error('Informe sua data de nascimento!')
      }

      if (!validateBirthDate(personalData.info.birth_date, 18)) {
        throw new Error('Informe uma data de nascimento válida!')
      }

      if (!selectedSpecialties || selectedSpecialties.length === 0) {
        throw new Error('Informe sua especialidade!')
      }

      if (checkStringIsEmpty(personalData.info.phone)) {
        throw new Error('Informe seu celular!')
      }

      return true
    } catch (error) {
      showErrorMessage(error as SystemError)
      return false
    }
  }

  const generateSpecialtiesToAdd = (
    userSpecialties: IUserSpecialty[] | undefined,
    selectedSpecialties: IPublicSpecialty[] | undefined,
  ): string[] | null => {
    if (!selectedSpecialties || selectedSpecialties.length === 0) return null

    if (!userSpecialties || userSpecialties.length === 0) {
      return selectedSpecialties.map(({ id }) => id)
    }

    const userSpecialtiesIds = userSpecialties.map(({ id }) => id)
    const selectedSpecialtiesIds = selectedSpecialties.map(({ id }) => id)

    const specialtiesToAdd = selectedSpecialtiesIds.filter(idSelectedSpecialty => {
      const existInUserSpecialties = !!userSpecialtiesIds.find(id => id === idSelectedSpecialty)
      return !existInUserSpecialties
    })

    if (specialtiesToAdd.length === 0) return null

    return specialtiesToAdd
  }

  const generateSpecialtiesToRemove = (
    userSpecialties: IUserSpecialty[] | undefined,
    selectedSpecialties: IPublicSpecialty[] | undefined,
  ): string[] | null => {
    if (!userSpecialties || userSpecialties.length === 0) return null

    if (!selectedSpecialties || selectedSpecialties.length === 0) return null

    const userSpecialtiesIds = userSpecialties.map(({ id }) => id)
    const selectedSpecialtiesIds = selectedSpecialties.map(({ id }) => id)

    const specialtiesToRemove = userSpecialtiesIds.filter(idUserSpecialty => {
      const existInSelectedSpecial = !!selectedSpecialtiesIds.find(id => id === idUserSpecialty)
      return !existInSelectedSpecial
    })

    if (specialtiesToRemove.length === 0) return null

    return specialtiesToRemove
  }

  const submitForm = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (!isEditing) return setIsEditing(true)

    const isValidatedForm = validateForm()

    if (!isValidatedForm) return

    try {
      let newImgUrlReference: undefined | string

      setIsLoading(true)

      if (avatarToUpload) {
        const { reference } = await uploadFileService(avatarToUpload.file)
        newImgUrlReference = reference
      }

      const userToPut: IUserPut = {
        ...personalData,
        info: {
          ...user?.info,
          ...personalData.info,
        },
        images: {
          ...personalData.images,
          avatar: newImgUrlReference || personalData.images.avatar,
        },
      }

      const success = await putUser(userToPut)

      if (!success) throw new Error()

      setAvatarToUpload(null)

      const specialtiesToAdd = generateSpecialtiesToAdd(userSpecialties, selectedSpecialties)
      const specialtiesToRemove = generateSpecialtiesToRemove(userSpecialties, selectedSpecialties)

      if (specialtiesToAdd) await addSpecialtiesToUserService(specialtiesToAdd)
      if (specialtiesToRemove) await removeSpecialtiesToUserService(specialtiesToRemove)

      setIsEditing(false)
    } catch (error) {
      toast.error('Erro ao editar as informações!')
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <PersonalDataContainer>
      <h2 className="main-title">Dados pessoais</h2>
      <h4 className="subtitle">Confirme e altere seus dados aqui.</h4>
      <h3 className="section-title">Informações pessoais</h3>

      <NameAndPicture>
        {(avatarToUpload?.view || user?.images?.avatar)
          ? (
            <img
              src={avatarToUpload?.view || user?.images?.avatar}
              alt="Foto de perfil"
            />
          )
          : (
            <BiUserCircle size={72} color="var(--primary-color)" />
          )
        }

        <div className="right-side">
          <h2>{`${user?.name} ${user?.last_name}` || 'Desconhecido'}</h2>

          {isEditing && (
            <button className="change-picture">
              {avatarToUpload
                ? (
                  <span id="remove" onClick={() => setAvatarToUpload(null)}>
                    Remover foto selecionada
                  </span>
                )
                : (
                  <>
                    <Camera />
                    <span onClick={() => setImageUploderOpen(true)}>
                      Alterar foto de perfil
                    </span>
                  </>
                )}
            </button>
          )}
        </div>
      </NameAndPicture>

      {imageUploderOpen && (
        <Modal close={() => setImageUploderOpen(false)}>
          <CutImage
            close={setImageUploderOpen}
            aspect={1}
            onCutImage={onCutImage}
          />
        </Modal>
      )}

      <Form onSubmit={submitForm}>
        <div className="line">
          <FormInput
            name="name"
            onChange={(value) => handleChange('name', value)}
            value={personalData.name}
            label="Nome"
            disabled={!isEditing}
          />
          <FormInput
            name="last_name"
            onChange={(value) => handleChange('last_name', value)}
            value={personalData.last_name}
            label="Sobrenome"
            disabled={!isEditing}
          />
        </div>

        <div className="line">
          <FormInput
            name="cpf"
            value={Mask.cpf(user?.cpf || '')}
            label="CPF"
            disabled
          />

          <FormInput
            name="birth_date"
            onChange={(value) => handleChange('birth_date', value)}
            value={personalData.info.birth_date}
            type="date"
            label="Data de nascimento"
            disabled={!isEditing}
          />
        </div>

        <FormMultiselect
          label="Especialidades"
          displayValue="name"
          options={specialties}
          selectedValues={selectedSpecialties}
          onChange={(selectedList: any) => setSelectedSpecialties(selectedList)}
          disabled={!isEditing}
        />

        <div className="line">
          <FormInput
            name="phone"
            onChange={(value) => handleChange('phone', Mask.phone(value))}
            value={personalData.info.phone}
            label="Celular"
            disabled={!isEditing}
          />
          <FormInput
            name="email"
            value={user?.email || ''}
            type="email"
            label="E-mail"
            disabled
          />
        </div>

        <h3 className="section-title address-title">Endereço</h3>

        <div className="line">
          <FormInput
            name="address"
            onChange={(value) => handleChange('address', value)}
            value={personalData.info.address}
            label="Endereço"
            flex={4}
            disabled={!isEditing}
          />
          <FormInput
            name="cep"
            onChange={(value) => handleChange('cep', Mask.cep(value))}
            value={personalData.info.cep}
            label="CEP"
            disabled={!isEditing}
          />
        </div>

        <div className="line">
          <div className="inner-line">
            <FormInput
              name="number"
              onChange={(value) => handleChange('number', Mask.number(value))}
              value={personalData.info.number}
              label="Número"
              flex={2}
              disabled={!isEditing}
            />
            <FormInput
              name="district"
              onChange={(value) => handleChange('district', value)}
              value={personalData.info.district}
              label="Bairro"
              flex={5}
              disabled={!isEditing}
            />
            <FormInput
              name="city"
              onChange={(value) => handleChange('city', value)}
              value={personalData.info.city}
              label="Cidade"
              flex={5}
              disabled={!isEditing}
            />
          </div>

          <FormInput
            name="state"
            onChange={(value) => handleChange('state', value)}
            value={personalData.info.state}
            label="Estado"
            disabled={!isEditing}
          />
        </div>

        <FormButton type="submit">
          {isEditing ? 'SALVAR ALTERAÇÕES' : 'EDITAR MEUS DADOS'}
        </FormButton>
      </Form>
    </PersonalDataContainer>
  )
}
