import styled from 'styled-components'

interface IPasswordRulesContainer {
}

export const PasswordRulesContainer = styled.div<IPasswordRulesContainer>`
  margin-left: 4px;

  span {
    font-weight: 600;
  }

  ul {
    margin-top: 8px;
  }

  li {
    list-style: none;
    display: flex;
    align-items: center;

    & + li {
      margin-top: 4px;
    }
  }

  .check-icon {
    width: 20px;
    height: 18px;
    margin-right: 4px;

    svg {
      width: 16px;
      height: 16px;
      fill: var(--primary-color);
      stroke: var(--primary-color);

      path {
        fill: var(--primary-color);
        stroke: var(--primary-color);
      }
    }
  }
`
