import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import VideosContext from './VideosContext';
import useLoading from 'src/contexts/LoadingContext/useLoading';
import { getSpecialtyService } from 'src/services/specialtiesService';
import { getThemeService } from 'src/services/themesService';
import { getContentService } from 'src/services/contentsService';
import Content from 'src/models/content';
import { ISpecialty } from 'src/models/specialty';
import Theme from 'src/models/theme';
import useUser from 'src/contexts/UserContext/useUser';

const VideosContextProvider: React.FC = ({ children }) => {
  const { specialtyId, themeId, contentId } = useParams<
    'specialtyId' | 'themeId' | 'contentId'
  >();
  const { navigate } = useUser();
  const { setIsLoading } = useLoading();

  const [specialty, setSpecialty] = useState<ISpecialty | undefined>();
  const [theme, setTheme] = useState<Theme | undefined>();
  const [selectedContent, setSelectedContent] = useState<Content | undefined>();
  const [search, setSearch] = useState('');
  const [autoPlay, setAutoPlay] = useState(true);

  const changeSelectedContent = useCallback(
    (content: Content) => {
      if (specialty) {
        if (theme) {
          navigate(
            `/videos/specialty/${specialty.content_id}/theme/${theme.content_id}/content/${content.content_id}`
          );
        } else {
          navigate(
            `/videos/specialty/${specialty.content_id}/content/${content.content_id}`
          );
        }
      }
    },
    [navigate, specialty, theme]
  );

  const getSpecialty = useCallback(async () => {
    setIsLoading(true);

    const specialty = await getSpecialtyService(specialtyId!);
    setSpecialty(specialty);

    setIsLoading(false);
  }, [setIsLoading, specialtyId]);

  const getTheme = useCallback(async () => {
    setIsLoading(true);

    const theme = await getThemeService(themeId!);
    setTheme(theme);

    setIsLoading(false);
  }, [setIsLoading, themeId]);

  const getContent = useCallback(async () => {
    setIsLoading(true);

    const content = await getContentService(contentId!);
    setSelectedContent(content);

    setIsLoading(false);
  }, [contentId, setIsLoading]);

  const handleGetSpecialtyOrTheme = useCallback(async () => {
    if (specialtyId || themeId) {
      if (specialtyId) {
        getSpecialty();
      }
      if (themeId) {
        getTheme();
      }
    } else {
      getContent();
    }
  }, [getContent, getSpecialty, getTheme, specialtyId, themeId]);

  const reloadData = useCallback(async () => {
    handleGetSpecialtyOrTheme();
  }, [handleGetSpecialtyOrTheme]);

  useEffect(() => {
    reloadData();
  }, [reloadData]);

  const reloadSelectedContent = useCallback(() => {
    setSelectedContent(selectedContent);
    setSpecialty(specialty);
  }, [selectedContent, specialty]);

  const allContents = useMemo(() => {
    let allContents = [] as Content[];
    if (themeId && theme) {
      allContents = theme.children || [];
    } else if (!themeId && specialtyId && specialty) {
      allContents = (
        (specialty.children || [])
          .filter((t) => !!t && t !== null)
          .sort((t1, t2) => ((t1.position || 0) > (t2.position || 0) ? 1 : -1))
          .map((t) => (t.children || []).filter((c) => !!c && c !== null)) || []
      ).flat();
    }

    let allUniqueContents = [] as Content[];
    for (let content of allContents) {
      if (
        !allUniqueContents.map((c) => c.content_id).includes(content.content_id)
      ) {
        allUniqueContents.push(content);
      }
    }

    return allUniqueContents.sort((c1, c2) =>
      (c1.position || 0) > (c2.position || 0) ? 1 : -1
    );
  }, [specialty, specialtyId, theme, themeId]);

  const goToNextContent = async () => {
    if (selectedContent) {
      const indexOfCurrentContent = allContents.indexOf(selectedContent);
      if (indexOfCurrentContent > -1) {
        const nextContent = allContents[indexOfCurrentContent + 1];
        if (nextContent) {
          changeSelectedContent(nextContent);
        }
      }
    }
  };

  const filteredContents = useMemo(() => {
    let filteredContents = [];
    if (search) {
      filteredContents = allContents.filter((c) =>
        `${c.name}`
          .toLocaleLowerCase()
          .includes(`${search}`.toLocaleLowerCase())
      );
    } else {
      filteredContents = allContents;
    }

    return filteredContents;
  }, [allContents, search]);

  const allThemes = useMemo(() => {
    return specialty?.children || [];
  }, [specialty]);

  const handleGetSelectedContent = useCallback(async () => {
    if (contentId) {
      const foundContent = allContents.find((c) => c.content_id === contentId);
      setSelectedContent(foundContent);
    } else {
      const foundContent = allContents[0];
      setSelectedContent(foundContent);
    }
  }, [allContents, contentId]);

  useEffect(() => {
    handleGetSelectedContent();
  }, [handleGetSelectedContent]);

  return (
    <VideosContext.Provider
      value={{
        specialty,
        theme,
        selectedContent,
        changeSelectedContent,
        allContents,
        search,
        setSearch,
        reloadData,
        allThemes,
        filteredContents,
        autoPlay,
        setAutoPlay,
        goToNextContent,
        reloadSelectedContent,
      }}
    >
      {children}
    </VideosContext.Provider>
  );
};

export default VideosContextProvider;
