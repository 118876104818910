import { ModalContainer } from './style'
import FormButton from 'src/components/FormButton/style'

interface IModalCancelSubscription {
  isOpen: boolean
  handleClose: () => void
}

export function ModalCancelSubscription({ isOpen, handleClose }: IModalCancelSubscription) {
  if (!isOpen) return null

  const handleCancelSubscription = () => {
    handleClose()
    window.open('https://www.manole.com.br/_secure/account#/subscriptions')
  }

  return (
    <ModalContainer>
      <div className="pop-up">
        <h3>Tem certeza que deseja cancelar a assinatura?</h3>

        <ul>
          <li>Será cancelado imediatamente.</li>
          <li>
            Seu acesso permanece até o vencimento da sua assinatura atual.
          </li>
        </ul>

        <div className="buttons">
          <FormButton onClick={handleClose}>
            CONTINUAR MINHA ASSINATURA
          </FormButton>

          <FormButton onClick={handleCancelSubscription} redOnWhite>
            CANCELAR ASSINATURA
          </FormButton>
        </div>
      </div>
    </ModalContainer>
  )
}
