import styled from 'styled-components';

const Button = styled.button`
  background: none;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  padding: 8px;
  transition: all 0.3s;
`;

export default Button;
