import { createContext, Dispatch, SetStateAction } from 'react';
import { ILoginCredentials } from 'src/models/loginModel';
import Partner from 'src/models/partner';
import { IUserSpecialty } from 'src/models/specialty';
import { IPlan, IUser, IUserPost, IUserPut } from 'src/models/userModel';

interface IUserContext {
  isAuthenticated: boolean;
  login: (credentials: ILoginCredentials) => Promise<void>;
  signUp: (credentials: IUserPost, token?: string) => Promise<void>;
  logout: () => void;
  refreshToken: () => Promise<void>;
  user: IUser | undefined;
  userPlan: IPlan | undefined;
  userPartners: Partner[] | undefined;
  userSpecialties: IUserSpecialty[] | undefined;
  selectedPartner?: Partner;
  changePartner: (switchingParnter: Partner) => void;
  setUser: Dispatch<SetStateAction<IUser | undefined>>;
  putUser: (user: IUserPut) => Promise<boolean>;
  navigate: (to: string) => void;
  getUser: () => Promise<void>
}

const UserContext = createContext({} as IUserContext);

export default UserContext;
