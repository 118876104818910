import styled from 'styled-components';

export const ModalContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(30, 18, 70, 0.8);
  z-index: 9999;

  .pop-up {
    width: calc(100% - 24px);
    max-width: 900px;
    max-height: 90vh;
    overflow-y: auto;
    background: #F2F2F2;
    border-radius: 8px;
    padding: 24px;
    margin: 0 12px;

    header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 24px;

      h3 {
        font-weight: bold;
        color: var(--text-color);
        font-size: max(min(34px, 5vw), 23px);
        line-height: min(48px, 8vw);
      }

      svg {
        width: 24px;
        height: 24px;
        color: var(--text-color);
        cursor: pointer;
      }
    }

    footer {
      display: flex;
      gap: 24px;
      margin-top: 8px;

      button {
        width: 100%;
        font-size: max(min(18px, 4vw), 15px);
      }
    }

    @media (min-width: 600px) {
      .pop-up {
        padding: 48px;
      }
    }
  }
`
