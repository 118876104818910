import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
`;

const StylesSwiper = styled.div`
  width: 100%;
  position: relative;

  .swiper-container {
    width: 100%;
  }

  .swiper-pagination-bullet {
    width: 24px !important;
    height: 3px !important;
    border-radius: 2px !important;
    background: #ffffff !important;
  }

  .swiper-pagination-bullets {
    top: 88%;
  }

  .swiper-button-prev {
    left: auto !important;
    right: 140px !important;
    top: 90%;
    z-index: 88;
  }

  .swiper-button-next {
    right: 96px;
    top: 90%;
    z-index: 88;
  }

  .swiper-button-prev:after,
  .swiper-button-next:after {
    font-size: 16px !important;
    font-weight: bold !important;
    color: var(--white);
  }
`;

export { Container, StylesSwiper };
