import styled, { css } from 'styled-components';

const MenuContainer = styled.div`
  width: 288px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: start;
  padding-left: 48px;
  border-right: 1px solid #cfd2d4;

  @media (max-width: 1000px) {
    padding: 0 16px;
    width: 100%;
    margin-bottom: 16px;
    padding-bottom: 20px;
    border-right: none;
    border-bottom: 1px solid #cfd2d4;
  }

  @media (max-width: 1000px) and (min-width: 550px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
  }
`;

interface MenuButtonProps {
  isActive: boolean;
}

const MenuButton = styled.button<MenuButtonProps>`
  text-align: left;
  font-family: Open Sans;
  font-size: 20px;
  color: var(--text-color);
  background: none;
  border: none;
  outline: none;
  text-align: center;

  ${({ isActive }) => isActive && css`
    color: var(--primary-color);
    font-weight: 700;
  `}
`;

export { MenuContainer, MenuButton };
