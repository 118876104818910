import styled from 'styled-components';

const SwiperContainer = styled.div`
  .swiper {
    width: 100%;
    height: fit-content;
    border-radius: 5px;
  }

  .swiper-slide {
    border-radius: 5px;
    justify-content: center;
    flex-direction: column;
    font-size: 18px;
    color: var(--white);
    width: 300px !important;
    height: 400px;
  }

  .swiper-button-prev,
  .swiper-button-next {
    top: 5%;
    width: 40px;
    height: 100%;
    color: white;
    font-weight: 900;
    outline: 0;
    transition: background-color 0.2s ease, color 0.2s ease;

    &::after {
      font-size: 14px;
    }
  }

  .swiper-button-prev {
    left: 0;
    background: rgb(32, 32, 32);
    background: linear-gradient(
      270deg,
      rgba(32, 32, 32, 0) 0%,
      rgba(32, 32, 32, 0.9) 100%
    );
  }

  .swiper-button-next {
    right: 0;
    background: rgb(32, 32, 32);
    background: linear-gradient(
      90deg,
      rgba(32, 32, 32, 0) 0%,
      rgba(32, 32, 32, 0.9) 100%
    );
  }

  .swiper-button-prev {
    &:after {
      position: relative;
      left: -1px;
    }
  }

  .swiper-button-next {
    &:after {
      position: relative;
      left: 1px;
    }
  }

  .swiper-button-prev.swiper-button-disabled,
  .swiper-button-next.swiper-button-disabled {
    opacity: 0;
    cursor: auto;
    pointer-events: none;
  }
`;

const Content = styled.div<{ background?: string }>`
  border-radius: 5px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: linear-gradient(
      180deg,
      rgba(20, 20, 20, 0) 0%,
      rgba(20, 20, 20, 0.7) 100%
    ),
    url(${({ background }) => background});
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: justify;
  gap: 8px;
  padding: 24px;
  font-family: 'Open Sans', sans-serif;

  h3 {
    font-size: 22px;
  }

  p {
    font-size: 14px;
  }
`;

export { SwiperContainer, Content };
