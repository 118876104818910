import { useCallback, useEffect, useState } from 'react'
import SwiperCore, { Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js'
import { PlanCard } from 'src/components/PlanCard'
import { StylesSwiper } from './style'
import useWindowSize from 'src/contexts/WindowSizeContext/useWindowSize'
import { getPlansService } from 'src/services/plansService'
import Plan from 'src/models/plan'
import 'swiper/swiper.scss'
import 'swiper/modules/navigation/navigation.scss'
import 'swiper/modules/pagination/pagination.scss'

SwiperCore.use([Navigation, Pagination])

interface IPlansSlider {
  selectPlan?: (plan: Plan) => void
  selectedPlan?: string
}

export function PlansSlider({ selectPlan, selectedPlan }: IPlansSlider) {
  const { windowWidth } = useWindowSize()

  const [plans, setPlans] = useState<Plan[]>()

  const signUp = !!selectPlan

  const getPlans = useCallback(async () => {
    const plans = await getPlansService()
    setPlans(plans)
  }, [])

  useEffect(() => { getPlans() }, [getPlans])

  if (!plans) return null

  return (
    <StylesSwiper signUp={signUp}>
      <Swiper
        slidesPerView={windowWidth > 1300 ? 2 : 1}
        spaceBetween={windowWidth > 850 ? 0 : 20}
        slidesPerGroup={1}
        cssMode
        navigation={windowWidth > 850}
        pagination={{ clickable: true }}
        className="mySwiper"
      >
        {plans?.map((plan: Plan) => (
          <SwiperSlide key={plan.plan_id}>
            <PlanCard
              key={plan.plan_id}
              signUp={signUp}
              plan={plan}
              selected={selectedPlan === plan.plan_id}
              onClick={() => (signUp ? selectPlan(plan) : undefined)}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </StylesSwiper>
  )
}
