import React, { useCallback } from 'react';
import Button from '../Button';
import { GoBackContainer } from './style';
import { ReactComponent as GoBackIcon } from 'src/assets/go-back-icon.svg';
import { useNavigate } from 'react-router-dom';

const GoBack: React.FC = () => {
  const navigate = useNavigate();

  const handleGoBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <GoBackContainer>
      <Button type="button" onClick={handleGoBack}>
        <GoBackIcon />
        <span>Voltar para página anterior</span>
      </Button>
    </GoBackContainer>
  );
};

export default GoBack;
