import React, { useState } from 'react';
import MobileMenuContext from 'src/contexts/MobileMenuContext/MobileMenuContext';

const MobileMenuContextProvider: React.FC = ({ children }) => {
  const [opened, setOpened] = useState(false);

  return (
    <MobileMenuContext.Provider value={{ opened, setOpened }}>
      {children}
    </MobileMenuContext.Provider>
  );
};

export default MobileMenuContextProvider;
