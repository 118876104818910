import React from 'react';
import { SubHeaderContainer, SubHeaderContentContainer } from './style';

interface SubHeaderProps {
  background: string;
  style?: React.CSSProperties;
}

const SubHeader: React.FC<SubHeaderProps> = ({
  background,
  children,
  style,
}) => {
  return (
    <SubHeaderContainer background={background} className="subheader">
      <SubHeaderContentContainer className="max-content">
        {children}
      </SubHeaderContentContainer>
    </SubHeaderContainer>
  );
};

export default SubHeader;
