import Button from 'src/components/Button';
import styled from 'styled-components';

const MyProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 48px;
`;

const InProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const FinishedContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 32px;
`;

const ContentsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;

  @media (max-width: 1000px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 700px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const SpecialtiesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;

  @media (max-width: 1000px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 700px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const TitleAndOrderOrShowMoreContainer = styled.div`
  grid-column: 1 / 4;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1000px) {
    grid-column: 1 / 3;
  }

  @media (max-width: 700px) {
    grid-column: 1 / 2;
  }
`;

const InProgressTitle = styled.h3`
  font-size: 1.4em;
  color: var(--text-color);
`;

const FinishedTitle = styled.h3`
  font-size: 1.4em;
  color: var(--text-color);
`;

const ContentsTitle = styled.h4`
  font-size: 1.1em;
  color: var(--gray-darker);
`;

const SpecialtiesTitle = styled.h4`
  font-size: 1.1em;
  color: var(--gray-darker);
`;

const PaginationContainer = styled.div`
  grid-column: 1 / 4;

  @media (max-width: 1000px) {
    grid-column: 1 / 3;
  }

  @media (max-width: 700px) {
    grid-column: 1 / 2;
  }
`;

const ShowMoreButton = styled(Button)`
  &,
  svg,
  path,
  circle {
    font-weight: bold;
    color: var(--primary-color);
    stroke: (--primary-color);
  }
`;

const InProgressAndFinishedDivider = styled.div`
  height: 1px;
  margin: 48px auto;
  background-color: var(--gray-lighter);
`;

export {
  MyProgressContainer,
  InProgressContainer,
  FinishedContainer,
  ContentsContainer,
  SpecialtiesContainer,
  InProgressTitle,
  FinishedTitle,
  ContentsTitle,
  SpecialtiesTitle,
  TitleAndOrderOrShowMoreContainer,
  PaginationContainer,
  ShowMoreButton,
  InProgressAndFinishedDivider,
};
