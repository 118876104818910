import { createContext, Dispatch, SetStateAction } from 'react';

interface IMobileMenuContext {
  opened: boolean;
  setOpened: Dispatch<SetStateAction<boolean>>;
}

const MobileMenuContext = createContext({} as IMobileMenuContext);

export default MobileMenuContext;
