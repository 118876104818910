import styled from 'styled-components';

export const ModalContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(30, 18, 70, 0.8);
  z-index: 10;

  .pop-up {
    width: min(600px, 95%);
    background: #F2F2F2;
    border-radius: 8px;
    padding: 24px;

    h3 {
      font-weight: bold;
      font-size: max(min(34px, 5vw), 23px);
      line-height: min(48px, 8vw);
    }

    ul {
      display: flex;
      flex-direction: column;
      padding-left: 28px;
      gap: 12px;
      margin: 24px 0 36px;

      li {
        font-size: max(min(20px, 4vw), 16px);
        line-height: 24px;
      }
    }

    h3, li {
      color: var(--text-color);
    }

    button {
      width: 100%;
      font-size: max(min(18px, 4vw), 15px);
      :first-of-type {
        margin-bottom: 24px;
      }
    }

    @media (min-width: 600px) {
      .pop-up {
        padding: 48px;

        ul {
          margin: 48px 0 78px;
          gap: 24px;
        }
      }
    }
  }
`
