import styled from "styled-components"

interface ITextCertificateInformation {
  label: string
  text: string
}

export function TextCertificateInformation({ label, text }: ITextCertificateInformation) {
  return (
    <Container className="TextCertificateInformation">
      <span>{label}</span>
      <p>{text}</p>
    </Container>
  )
}

const Container = styled.div`
  span, p {
    display: inline-block;
    font-size: 20px;
    line-height: 36px;
    color: var(--text-color);
  }

  span {
    font-weight: 700;
    margin-right: 8px;
  }

  @media screen and (max-width: 640px) {
    span, p {
      font-size: 18px;
    }
  }
`
