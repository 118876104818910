import { toast } from 'react-toastify'
import SystemError from 'src/models/error'

export const showSuccessMessage = (message: string) => {
  toast.success(message)
}

export const showErrorMessage = (error: SystemError) => {
  let errorMessage = error.message

  if (error.response?.data) {
    if (error.response.data?.validation?.body?.message) {
      errorMessage = error.response.data.validation.body.message
    }
    else if (error.response?.data?.message) {
      errorMessage = error.response.data.message
    }
  }

  toast.error(errorMessage)
}
