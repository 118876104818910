import { useCallback, useEffect, useState } from 'react'
import CertificateCard from './components/CertificateCard'
import {
  CertificatesContainer,
  CertificatesList,
  CertificatesTitle,
} from './style'
import { getCertificatesService } from 'src/services/certificatesService'
import useUser from 'src/contexts/UserContext/useUser'
import useLoading from 'src/contexts/LoadingContext/useLoading'
import { ICertificate } from 'src/models/certificate'

export function Certificates() {
  const { user } = useUser()
  const { setIsLoading } = useLoading()

  const [certificates, setCertificates] = useState<ICertificate[]>()

  const getCertificates = useCallback(async () => {
    setIsLoading(true)

    const certificates = await getCertificatesService({
      relations: ['content'],
      user_id: user?.user_id,
    })

    setCertificates(certificates)

    setIsLoading(false)
  }, [user?.user_id, setIsLoading])

  useEffect(() => { getCertificates() }, [getCertificates])

  return (
    <CertificatesContainer>
      <CertificatesTitle>Certificados</CertificatesTitle>

      <CertificatesList>
        {certificates?.map((cert) => (
          <CertificateCard
            key={cert.id}
            certificate={cert}
          />
        ))}
      </CertificatesList>
    </CertificatesContainer>
  )
}
