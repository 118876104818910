import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { SideContent } from 'src/components/SideContent';
import { PlansSlider } from 'src/components/PlansSlider';
import FormButton from 'src/components/FormButton/style';
import FormInput from 'src/components/FormInput';
import { PasswordRules, validatePassword } from 'src/components/PasswordRules';
import { FormMultiselect } from 'src/components/FormMultiselect';
import {
  GlobalStyles,
  SignUpContainer,
  CheckboxContainer,
  Form,
  PlanPicker,
  Header,
  SideAndFormContainer,
  SignInLinkMobile,
} from './style';
import useLoading from 'src/contexts/LoadingContext/useLoading';
import useUser from 'src/contexts/UserContext/useUser';
import { getPublicSpecialtiesService } from 'src/services/specialtiesService';
import {
  checkStringIsEmpty,
  Mask,
  showErrorMessage,
  validateBirthDate,
  validateCPF
} from 'src/helpers';
import { IPublicSpecialty } from 'src/models/specialty';
import { IUserPost } from 'src/models/userModel';
import SystemError from 'src/models/error';
import Plan from 'src/models/plan';
import { ReactComponent as Logo } from 'src/assets/logos/logo-manole-play.svg';

const credentialsDefault: IUserPost = {
  name: '',
  last_name: '',
  cpf: '',
  email: '',
  password: '',
  info: {
    birth_date: '',
    phone: '',
  },
  specialty_ids: []
}

const keysInfoArray = Object.keys(credentialsDefault.info);

const todayDate = new Date().toISOString().split('T')[0];

const SignUp: React.FC = () => {
  const { setIsLoading } = useLoading();
  const { login, signUp, navigate } = useUser();
  const { search } = useLocation();

  const [signUpToken, setSignUpToken] = useState<string | undefined>(undefined);

  const [step, setStep] = useState<number>(1);
  const [selectedPlan, setSelectedPlan] = useState<Plan | null>(null);

  const [credentials, setCredentials] = useState<IUserPost>(credentialsDefault);
  const [passwordConfirmation, setPasswordConfirmation] = useState<string>('');
  const [policiesAccepted, setPoliciesAccepted] = useState<boolean>(false);

  const [specialties, setSpecialties] = useState<IPublicSpecialty[]>()
  const [specialtiesSelected, setSpecialtiesSelected] = useState<IPublicSpecialty[]>()

  const getPublicSpecialties = async () => {
    const data = await getPublicSpecialtiesService()
    setSpecialties(data)
  }

  useEffect(() => { getPublicSpecialties() }, [])

  useEffect(() => {
    const urlParams = new URLSearchParams(search);

    const name = urlParams.get('name');
    const email = urlParams.get('email');
    const token = urlParams.get('token') || '';

    setCredentials((oldValue) => ({
      ...oldValue,
      name: name || oldValue.name,
      email: email || oldValue.email,
    }));

    setSignUpToken(token);
  }, [search]);

  const handleChangeCredentials = (propName: string, newValue: string) => {
    const isPropInfo = keysInfoArray.includes(propName);

    if (!isPropInfo) {
      setCredentials((oldValue: IUserPost) => ({
        ...oldValue,
        [propName]: newValue,
      }));
    } else {
      setCredentials((oldValue: IUserPost) => ({
        ...oldValue,
        info: {
          ...oldValue.info,
          [propName]: newValue,
        },
      }));
    }
  };

  const validate = () => {
    if (checkStringIsEmpty(credentials.name)) {
      throw new Error('Informe seu nome!');
    }

    if (checkStringIsEmpty(credentials.last_name)) {
      throw new Error('Informe seu sobrenome!');
    }

    if (checkStringIsEmpty(credentials.cpf)) {
      throw new Error('Informe seu CPF!');
    }

    if (!validateCPF(credentials.cpf)) {
      throw new Error('Informe um CPF válido!');
    }

    if (checkStringIsEmpty(credentials.info.birth_date)) {
      throw new Error('Informe sua data de nascimento!');
    }

    if (!validateBirthDate(credentials.info.birth_date, 18)) {
      throw new Error('Informe uma data de nascimento válida!');
    }

    if (!specialtiesSelected || specialtiesSelected.length === 0) {
      throw new Error('Informe sua especialidade!');
    }

    if (checkStringIsEmpty(credentials.info.phone)) {
      throw new Error('Informe seu celular!');
    }

    if (checkStringIsEmpty(credentials.email)) {
      throw new Error('Informe seu email!');
    }

    if (checkStringIsEmpty(credentials.password)) {
      throw new Error('Informe sua senha!');
    }

    if (!validatePassword(credentials.password)) {
      throw new Error('Nova senha inválida.');
    }

    if (credentials.password !== passwordConfirmation) {
      throw new Error('A senha e a confirmação de senha devem ser iguais!');
    }

    if (!policiesAccepted) {
      throw new Error('Você deve ler aceitar as Políticas de privacidade e uso para prosseguir.');
    }
  }

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    setIsLoading(true);

    const isPremiumPlan = !!Number(selectedPlan?.price);
    const urlPlan = selectedPlan?.info.url_plan;

    try {
      if (step === 1) {
        validate();
        setStep(2);
      } else {
        const specialtyIds = specialtiesSelected?.map(({ id }) => id)

        const credentialsPost = {
          ...credentials,
          specialty_ids: specialtyIds || []
        }

        await signUp(credentialsPost, signUpToken);

        if (isPremiumPlan && urlPlan) {
          // Send plan id to app instead of opening the plan url
          if (window.ReactNativeWebView?.postMessage) {
            const data = {
              planId: selectedPlan?.plan_id,
            };
            window.ReactNativeWebView?.postMessage(JSON.stringify(data));
          } else {
            window.location.href = urlPlan;
          }
        }

        await login({
          email: credentials.email,
          password: credentials.password,
        });

        navigate('/home');
      }
    } catch (error) {
      showErrorMessage(error as SystemError);
    }

    setIsLoading(false);
  };

  return (
    <SignUpContainer>
      <GlobalStyles />

      <Header className="max-content">
        <Logo />
      </Header>

      <SideAndFormContainer className="max-content">
        <SideContent
          text={step === 1 ? 'Cadastre-se agora.' : 'Escolha sua assinatura'}
          step={step}
          goBack={() => setStep(1)}
        />

        {step === 1
          ? (
            <Form onSubmit={handleSubmit}>
              <FormInput
                label="Nome"
                value={credentials.name || ''}
                onChange={(value) => handleChangeCredentials('name', value)}
              />

              <FormInput
                label="Sobrenome"
                value={credentials.last_name || ''}
                onChange={(value) => handleChangeCredentials('last_name', value)}
              />

              <div className="line">
                <FormInput
                  label="CPF"
                  value={Mask.cpf(credentials.cpf) || ''}
                  onChange={(value) =>
                    handleChangeCredentials(
                      'cpf',
                      value.replace(/\D/g, '').substring(0, 11)
                    )
                  }
                />

                <FormInput
                  label="Data de nascimento"
                  name="birth_date"
                  onChange={(value) =>
                    handleChangeCredentials('birth_date', value)
                  }
                  value={credentials.info?.birth_date || ''}
                  type="date"
                  max={todayDate}
                />
              </div>

              <FormMultiselect
                label="Especialidades"
                displayValue="name"
                options={specialties}
                selectedValues={specialtiesSelected}
                onChange={(selectedList: any) => setSpecialtiesSelected(selectedList)}
              />

              <FormInput
                name="phone"
                onChange={(value) =>
                  handleChangeCredentials('phone', Mask.phone(value))
                }
                value={credentials.info.phone}
                label="Celular"
              />

              <FormInput
                label="E-mail"
                value={credentials.email || ''}
                onChange={(value) => handleChangeCredentials('email', value)}
              />

              <FormInput
                label="Senha"
                value={credentials.password || ''}
                type="password"
                onChange={(value) => handleChangeCredentials('password', value)}
              />

              <FormInput
                label="Confirme sua senha"
                value={passwordConfirmation || ''}
                type="password"
                onChange={(value) => setPasswordConfirmation(value)}
              />

              <PasswordRules password={credentials.password} />

              <CheckboxContainer>
                <input
                  type="checkbox"
                  checked={policiesAccepted}
                  onChange={() => setPoliciesAccepted((prev) => !prev)}
                />
                <span>
                  Li e aceito as <a href="/">Políticas de privacidade e uso</a>.
                </span>
              </CheckboxContainer>

              <FormButton>ESCOLHER PLANO</FormButton>
              <SignInLinkMobile>
                <span>Já tem uma conta? </span>
                <Link to="/login">Entre agora</Link>
              </SignInLinkMobile>
            </Form>
          )
          : (
            <PlanPicker>
              <div>
                <PlansSlider
                  selectPlan={(plan: Plan) => setSelectedPlan(plan)}
                  selectedPlan={selectedPlan?.plan_id}
                />
                <FormButton onClick={handleSubmit}>FINALIZAR CADASTRO</FormButton>
              </div>
            </PlanPicker>
          )
        }
      </SideAndFormContainer>
    </SignUpContainer>
  );
};

export default SignUp;
