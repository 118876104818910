import styled from 'styled-components';

const MyListContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 91px;

  .pagination {
    grid-column: 1 / 4;

    @media (max-width: 1000px) {
      grid-column: 1 / 3;
    }

    @media (max-width: 700px) {
      grid-column: 1 / 2;
    }
  }
`;

const ContentsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;

  @media (max-width: 1000px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 700px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const SpecialtiesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;

  @media (max-width: 1000px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 700px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const TitleAndSpecialtyContainer = styled.div`
  grid-column: 1 / 4;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1000px) {
    grid-column: 1 / 3;
  }

  @media (max-width: 700px) {
    grid-column: 1 / 2;
  }
`;

const Title = styled.h3`
  color: var(--text-color);
  line-height: 88px;
`;

const ShowMoreButtonContainer = styled.div`
  grid-column: 1 / 4;
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    &,
    svg,
    path,
    circle {
      color: var(--primary-color);
      font-weight: bold;
    }
  }

  @media (max-width: 1000px) {
    grid-column: 1 / 3;
  }

  @media (max-width: 700px) {
    grid-column: 1 / 2;
  }
`;

const ContentesAndSpecialtiesDivider = styled.div`
  padding: 0;
  height: 2px;
  margin: 48px auto 12px;
  max-width: calc(min(100%, 1400px) - 48px);
  background-color: var(--gray-lighter);

  @media (max-width: 540px) {
    max-width: calc(100% - 32px);
  }
`;

export {
  MyListContainer,
  ContentsContainer,
  SpecialtiesContainer,
  Title,
  ShowMoreButtonContainer,
  TitleAndSpecialtyContainer,
  ContentesAndSpecialtiesDivider,
};
