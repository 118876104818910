import React, { useCallback } from 'react';
import { BiSearch } from 'react-icons/bi';
import useSearch from 'src/contexts/SearchContext/useSearch';
import { SearchContainer, SearchButton, SearchInput } from './style';

const Search: React.FC = () => {
  const { opened, setOpened, searchText, setSearchText } = useSearch();

  const toggleOpened = useCallback(() => {
    setOpened(!opened);
  }, [opened, setOpened]);

  return (
    <SearchContainer opened={opened}>
      <SearchInput
        type="search"
        placeholder="Pesquise aqui"
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
      />
      <SearchButton type="button" onClick={toggleOpened}>
        <BiSearch size={20} color="var(--primary-color)" />
      </SearchButton>
    </SearchContainer>
  );
};

export default Search;
