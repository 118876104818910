import React from 'react';
import { AiOutlinePlayCircle } from 'react-icons/ai';
import styled from 'styled-components';

const BlankPlayerContainer = styled.div`
  width: 100%;
  height: 440px;

  @media (max-width: 1300px) {
    height: 360px;
  }

  @media (max-width: 768px) {
    height: 220px;
  }

  @media (max-width: 400px) {
    height: 160px;
  }

  background: rgba(0, 0, 0, 0.6);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BlankPlayer: React.FC = () => {
  return (
    <BlankPlayerContainer>
      <AiOutlinePlayCircle size={128} />
    </BlankPlayerContainer>
  );
};

export default BlankPlayer;
