import ReactLoading from 'react-loading'
import { LoadingContainer } from './style'
import useLoading from 'src/contexts/LoadingContext/useLoading'

export function Loading() {
  const { isLoading } = useLoading()

  if (!isLoading) return null

  return (
    <LoadingContainer>
      <ReactLoading type="bars" color="#fff" />
    </LoadingContainer>
  )
}
