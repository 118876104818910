import { Link } from 'react-router-dom';
import styled from 'styled-components';

const SideContentContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  flex: 0;
  justify-content: space-between;
  margin: auto;

  .text-step-container {
    display: flex;
    flex-direction: column;
    margin-top: 50px;

    @media screen and (max-width: 640px) {
      text-align: center;
    }
  }

  @media (min-width: 770px) {
    border-right: solid 1px var(--border-gray);
    height: 434px;
    padding-left: 10px;
    margin: unset;
    .text-step-container {
      min-width: 297px;
      margin-top: 91px;
      margin-right: 51px;
    }
  }
`;

const GoBackButton = styled.button`
  position: absolute;
  background: none;
  border: none;
`;

const GoToLandingLink = styled(Link)`
  position: absolute;
`;

const SideContentText = styled.h3`
  font-size: 2em;
  color: var(--text-color);
  line-height: 1.3;
  font-weight: bold;

  @media screen and (max-width: 640px) {
    font-size: 32px;
    text-align: center;
  }

  @media (min-width: 770px) {
    font-size: 50px;
    line-height: 72px;
  }
`;

const Step = styled.span`
  font-size: 24px;
  color: var(--text-color);
  margin-top: 24px;
  line-height: 32px;
  font-weight: 600;
  opacity: 0.7;
`;

const SignUpLink = styled.span`
  font-size: 14px;
  line-height: 24px;
  color: var(--text-color);
  margin-top: 30px;

  @media screen and (max-width: 640px) {
    display: none;
  }

  a {
    text-decoration: underline;
    font-weight: 600;
    cursor: pointer;

    :link,
    :visited,
    :hover,
    :active {
      color: var(--text-color);
    }
  }
`;

export {
  SideContentContainer,
  GoBackButton,
  GoToLandingLink,
  SideContentText,
  Step,
  SignUpLink,
};
