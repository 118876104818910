import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  header {
    display: none !important;
  }
`;

export const SignUpContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  .line {
    display: flex;
    flex-direction: row;
    gap: 20px;
    width: 100%;
  }
`;

export const Header = styled.div`
  display: flex;
  padding: 8px 0 0;
`;

export const SideAndFormContainer = styled.div`
  margin: auto;
  display: flex;
  gap: 32px;

  @media (max-width: 770px) {
    flex-direction: column;
  }
`;

export const Form = styled.form`
  padding-top: 35px;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  gap: 32px;

  > div,
  > button {
    width: 100%;
    max-width: 446px;
  }
  > button {
    min-height: 48px;
  }
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 4px 0;

  input {
    height: 20px;
    width: 20px;
    cursor: pointer;
  }

  span {
    font-size: 16px;
    line-height: 20px;
    color: var(--text-color);
    font-weight: 500;

    a {
      text-decoration: underline;

      :link,
      :visited,
      :hover,
      :active {
        color: var(--text-color);
      }
    }
  }
`;

export const PlanPicker = styled.div`
  padding-top: 9px;
  flex: 1;
  display: flex;
  justify-content: center;

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;

    > button {
      height: 56px;
      width: 100%;
      min-width: 300px;
    }
  }

  @media (min-width: 850px) {
    & > div > button {
      width: calc(100% - 78px);
    }
  }

  @media (min-width: 1300px) {
    & > div > button {
      width: calc(100% - 140px);
    }
  }
`;

export const SignInLinkMobile = styled.span`
  display: none;
  font-size: 14px;
  line-height: 24px;
  color: var(--text-color);
  margin-top: 30px;

  @media screen and (max-width: 640px) {
    display: inline;
  }

  a {
    text-decoration: underline;
    font-weight: 600;
    cursor: pointer;

    :link,
    :visited,
    :hover,
    :active {
      color: var(--text-color);
    }
  }
`;
