import { useEffect, useRef, useState } from 'react'
import clamp from 'clamp-js'
import { ThemeCardContainer, ThemeTitle, ThemeWatch } from './style'
import useUser from 'src/contexts/UserContext/useUser'
import Theme from 'src/models/theme'
import { ISpecialty } from 'src/models/specialty'
import { ReactComponent as ThemeIcon } from 'src/assets/theme-icon.svg'
import { ReactComponent as GoForwardIcon } from 'src/assets/go-forward-icon.svg'

interface IThemeCard {
  theme: Theme
  specialty: ISpecialty
}

export function ThemeCard({ theme, specialty }: IThemeCard) {
  const { navigate } = useUser()
  const themeTitle = useRef<HTMLHeadingElement>(null)

  const [hovered, setHovered] = useState(false)
  const handleMouseEnter = () => setHovered(true)
  const handleMouseLeave = () => setHovered(false)

  const handleClick = () => {
    const path = `/videos/specialty/${specialty.content_id}/theme/${theme.content_id}`
    navigate(path)
  }

  useEffect(() => {
    if (themeTitle.current) clamp(themeTitle.current, { clamp: 2 })
  }, [themeTitle])

  return (
    <ThemeCardContainer
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
    >
      <div className="icon-container">
        <ThemeIcon />
      </div>

      <ThemeTitle ref={themeTitle} hovered={hovered}>
        {theme.name}
      </ThemeTitle>

      {hovered && (
        <ThemeWatch>
          <span>Assistir</span>
          <GoForwardIcon />
        </ThemeWatch>
      )}
    </ThemeCardContainer>
  )
}
