import styled from 'styled-components';

const HomeContainer = styled.div`
  width: 100%;
  padding-bottom: 32px;
`;

const HomeContent = styled.div``;

export { HomeContainer, HomeContent };
