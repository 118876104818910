import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Breadcrumb from 'src/components/Breadcrumb';
import SubHeader from 'src/components/SubHeader';
import SubHeaderTitle from 'src/components/SubHeader/SubHeaderTitle';
import VideoCard from 'src/components/VideoCard';
import SpecialtyCard from 'src/components/SpecialtyCard';
import {
  ContentsContainer,
  ContentsTitle,
  NoResultFound,
  ResultDescription,
  SearchContainer,
  SpecialtiesContainer,
  SpecialtiesTitle,
} from './style';
import { getSpecialtiesService } from 'src/services/specialtiesService';
import { getContentsService } from 'src/services/contentsService';
import useSearch from 'src/contexts/SearchContext/useSearch';
import useLoading from 'src/contexts/LoadingContext/useLoading';
import { ISpecialty } from 'src/models/specialty';
import Content from 'src/models/content';
import searchDefaultImage from 'src/assets/search-default-image.png';
import useUser from 'src/contexts/UserContext/useUser';
import PartnerLink from 'src/components/PartnerLink';

interface ExtendedWindow extends Window {
  searchTimeoutId: number;
}

declare let window: ExtendedWindow;

const Search: React.FC = () => {
  const { searchText } = useSearch();
  const { setIsLoading } = useLoading();
  const { selectedPartner: partner } = useUser();

  const [specialties, setSpecialties] = useState<ISpecialty[] | undefined>();
  const [contents, setContents] = useState<Content[] | undefined>();

  const getSpecialtiesAndContents = useCallback(async () => {
    setIsLoading(true);

    if (searchText) {
      const { data: specialties } = await getSpecialtiesService({
        name: searchText,
        ...(partner
          ? {
            segmentation_item_ids: [
              partner.segmentation_item_id ||
              process.env.REACT_APP_MANOLE_UUID ||
              '8f5caaa2-3981-4e83-84f9-44f488cca3b3',
            ],
          }
          : {}),
      });

      setSpecialties(specialties);

      const { data: contents } = await getContentsService({
        name: searchText,
        ...(partner
          ? {
            segmentation_item_ids: [
              partner.segmentation_item_id ||
              process.env.REACT_APP_MANOLE_UUID ||
              '8f5caaa2-3981-4e83-84f9-44f488cca3b3',
            ],
          }
          : {}),
      });

      setContents(contents);
    } else {
      setSpecialties([] as ISpecialty[]);
      setContents([] as Content[]);
    }

    setIsLoading(false);
  }, [partner, searchText, setIsLoading]);

  useEffect(() => {
    window.clearTimeout(window.searchTimeoutId);

    window.searchTimeoutId = window.setTimeout(async () => {
      getSpecialtiesAndContents();
    }, 300);
  }, [getSpecialtiesAndContents]);

  const numberOfResults = useMemo(() => {
    const specialtiesLength = (specialties || []).length
    const contentsLength = (contents || []).length
    return specialtiesLength + contentsLength;
  }, [contents, specialties]);

  return (
    <SearchContainer>
      <SubHeader background={partner?.banner.search || searchDefaultImage}>
        <Breadcrumb
          crumbs={[
            <PartnerLink to="/home">Início</PartnerLink>,
            <span>Pesquisa</span>,
          ]}
        />
        <SubHeaderTitle title="Pesquisa" />
      </SubHeader>

      <ResultDescription className="max-content">
        {numberOfResults} resultado{numberOfResults > 1 ? 's' : ''} para{' '}
        <span className="search-text">"{searchText}"</span>
      </ResultDescription>

      <ContentsContainer className="max-content">
        <ContentsTitle>Vídeos</ContentsTitle>

        {!!contents?.length
          ? (
            contents.map((c) => <VideoCard video={c} key={c.content_id} />)
          )
          : (
            <NoResultFound>
              Não foram encontrados vídeos para sua pesquisa.
            </NoResultFound>
          )}
      </ContentsContainer>

      <SpecialtiesContainer className="max-content">
        <SpecialtiesTitle>Especialidades</SpecialtiesTitle>

        {!!specialties?.length
          ? (
            specialties.map((s) => (
              <SpecialtyCard specialty={s} key={s.content_id} />
            ))
          )
          : (
            <NoResultFound>
              Não foram encontradas especialidades para sua pesquisa.
            </NoResultFound>
          )}
      </SpecialtiesContainer>
    </SearchContainer>
  );
};

export default Search;
