export const checkStringIsEmpty = (str: string) => {
  if (!str || !str.replace(/[!@#$%¨&()_+=~^<>*|"]/g, '').trim()) {
    return true
  }
  return false
}

export const trim = (string: string, char: string) => {
  const regexp = new RegExp(`^${char}+|${char}+$`, 'g')
  return string.replace(regexp, '')
}

export const removeAccentuation = (str: string) => {
  const com_acento = `ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝŔÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿŕ`
  const sem_acento = `AAAAAAACEEEEIIIIDNOOOOOOUUUUYRsBaaaaaaaceeeeiiiionoooooouuuuybyr`
  let novastr = ''

  for (let i = 0; i < str.length; i++) {
    let troca = false

    for (let a = 0; a < com_acento.length; a++) {
      if (str.substr(i, 1) === com_acento.substr(a, 1)) {
        novastr += sem_acento.substr(a, 1)
        troca = true
        break
      }
    }

    if (troca === false) {
      novastr += str.substr(i, 1)
    }
  }

  return novastr
}

export const secondsToTimeText = (seconds: number) => {
  let timeText = ''

  const hours = Math.floor(seconds / 3600)
  if (hours) {
    timeText += `${`${hours}`.padStart(2, '0')}:`
  }

  const minutes = Math.floor((seconds % 3600) / 60)

  timeText += `${`${minutes}`.padStart(2, '0')}:`

  const remainingSeconds = (seconds % 3600) % 60

  timeText += `${`${remainingSeconds}`.padStart(2, '0')}`

  return timeText
}
