import httpClient from 'src/http-client'
import Plan from 'src/models/plan'

export const getPlansService = async (): Promise<Plan[]> => {
  const { data } = await httpClient.get<Plan[]>('/plans')

  const plans = data.filter((plan) => plan.info.is_active)

  return plans
}

export const getPlanService = async (manole_version_id: string): Promise<Plan | null> => {
  const { data } = await httpClient.get<Plan[]>('/plans', {
    params: {
      manole_version_id,
    }
  })

  const plan = data[0]

  if (!plan) return null

  return plan
}
