import { createContext } from 'react';
import Content from 'src/models/content';

interface IHomeContext {
  contentsInProgress?: Content[];
  featuredContents?: Content[];
  suggestedContents?: Content[];
  newContents?: Content[];
}

const HomeContext = createContext({} as IHomeContext);

export default HomeContext;
