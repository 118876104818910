import Button from 'src/components/Button';
import styled from 'styled-components';

const ErrorPageContainer = styled.div`
  & .subheader {
    padding-bottom: 24px;
  }
`;

const ErrorMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  margin: 90px;
  text-align: center;

  & > h1 {
    font-family: 'Open Sans', sans-serif;
    font-size: 48px;
    font-weight: 600;
    color: var(--text-color);
    width: 740px;

    & > span {
      color: var(--primary-color);
    }
  }

  & > p {
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 24px;
    color: var(--text-color);
  }

  & > div {
    margin-top: 16px;
  }
`;

const GoBackButton = styled(Button)`
  border: none;
  background-color: var(--primary-color);
  border-radius: 8px;
  color: var(--white);
  font-weight: 700;
  font-size: 18px;
  font-family: 'Open Sans', sans-serif;
  min-height: 56px;
  padding: 16px 24px;
  text-transform: uppercase;
`;

export { ErrorPageContainer, ErrorMessageContainer, GoBackButton };
