import { useCallback, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import FormInput from 'src/components/FormInput'
import { ValidateCertificateContainer } from './style'
import { ReactComponent as Logo } from 'src/assets/logos/logo-manole-play.svg'
import { TextCertificateInformation } from './components/TextCertificateInformation'
import FormButton from 'src/components/FormButton/style'
import { validateCertificateService } from 'src/services/certificatesService'
import { showErrorMessage } from 'src/helpers'
import SystemError from 'src/models/error'

export function ValidateCertificate() {
  const { search } = useLocation()

  const [keyToValidate, setKeyToValidate] = useState("")

  const [certificateData, setCertificateData] = useState({
    name: '',
    course: '',
    completionDate: '',
    workload: '',
  })

  const validateCertificate = useCallback(async (certificateKey: string) => {
    try {
      if (!certificateKey) throw new Error('Informe um código válido!')

      const certificateData = await validateCertificateService(certificateKey)

      const name = `${certificateData.user?.name} ${certificateData.user?.last_name}`

      const date = new Date(certificateData.content_finish_at)
      const completionDate = new Intl.DateTimeFormat('pt-BR', { timeZone: 'UTC' }).format(date)

      const workload = `${certificateData.content_duration} minutos`

      setCertificateData({
        name,
        course: certificateData.content_name,
        completionDate,
        workload,
      })
    } catch (error) {
      showErrorMessage(error as SystemError)
    }
  }, [])

  useEffect(() => {
    const urlParams = new URLSearchParams(search)

    const validationKey = urlParams.get('validation_key')

    if (!validationKey) return

    setKeyToValidate(validationKey)
    validateCertificate(validationKey)
  }, [search, validateCertificate])

  const handleSubmit = () => {
    validateCertificate(keyToValidate)
  }

  return (
    <ValidateCertificateContainer>
      <header>
        <Link to="/">
          <Logo />
        </Link>
      </header>

      <main>
        <section className="formContainer">
          <h1>Comprovantes</h1>

          <FormInput
            label="Código do comprovante"
            value={keyToValidate}
            onChange={(value) => setKeyToValidate(value)}
          />

          <FormButton onClick={handleSubmit}>
            PESQUISAR
          </FormButton>
        </section>

        <div className="vertical-bar" />

        <section className='dataContainer'>
          <TextCertificateInformation
            label="Nome:"
            text={certificateData.name}
          />

          <TextCertificateInformation
            label="Curso:"
            text={certificateData.course}
          />

          <TextCertificateInformation
            label="Data de finalização:"
            text={certificateData.completionDate}
          />

          <TextCertificateInformation
            label="Carga horária:"
            text={certificateData.workload}
          />
        </section>
      </main>
    </ValidateCertificateContainer>
  )
}
