import React, { useCallback, useEffect, useMemo, useState } from 'react';
import GoBack from 'src/components/GoBack';
import { Select, SelectOption } from 'src/components/Select';
import SubHeader from 'src/components/SubHeader';
import Breadcrumb from 'src/components/Breadcrumb';
import Pagination from 'src/components/Pagination';
import SpecialtyCard from 'src/components/SpecialtyCard';
import { SubHeaderDivider } from 'src/components/SubHeaderDivider';
import SubHeaderTitle from 'src/components/SubHeader/SubHeaderTitle';
import {
  SpecialtiesContainer,
  SpecialtiesList,
  SpecialtiesTitleAndOrderContainer,
} from './style';
import useLoading from 'src/contexts/LoadingContext/useLoading';
import { getSpecialtiesService } from 'src/services/specialtiesService';
import { ISpecialty } from 'src/models/specialty';
import { goToTop, removeAccentuation } from 'src/helpers';
import specialtiesBanner from 'src/assets/specialties.png';
import useUser from 'src/contexts/UserContext/useUser';
import PartnerLink from 'src/components/PartnerLink';

const orderOptions = [
  { label: 'Mais recentes', value: 'most_recent', },
  { label: 'Ordem alfabética', value: 'alpha' },
] as SelectOption[];

const firstPage = 1;

const numberOfItemsPerPage = 12;

const Specialties: React.FC = () => {
  const { selectedPartner: partner } = useUser();
  const { setIsLoading } = useLoading();

  const [page, setPage] = useState(firstPage);
  const [specialties, setSpecialties] = useState([] as ISpecialty[]);
  const [selectedOrder, setSelectedOrder] = useState('alpha');

  const getSpecialties = useCallback(async () => {
    setIsLoading(true);

    let specialties = [] as ISpecialty[];

    if (partner) {
      const { data } = await getSpecialtiesService({
        segmentation_item_ids: [partner.segmentation_item_id],
      });
      specialties = data;
    };


    setSpecialties(specialties);

    setIsLoading(false);
  }, [partner, setIsLoading]);

  useEffect(() => {
    getSpecialties();
  }, [getSpecialties]);

  const specialtiesToShow = useMemo(() => {
    let result = [...(specialties || [])];

    if (selectedOrder === 'most_recent') {
      result = (result || []).sort((s1, s2) => {
        const s1Date = new Date(s1.created_at);
        const s2Date = new Date(s2.created_at);

        return s1Date.getTime() > s2Date.getTime() ? -1 : 1;
      });
    } else {
      result = (result || []).sort((s1, s2) => {
        return removeAccentuation(s1.name) > removeAccentuation(s2.name)
          ? 1
          : -1;
      });
    }

    result = result.slice(
      (page - 1) * numberOfItemsPerPage,
      page * numberOfItemsPerPage
    );

    return result;
  }, [page, selectedOrder, specialties]);

  const handleChangePage = useCallback((page: number) => {
    goToTop();
    setPage(page);
  }, []);

  const handleChangeOrder = useCallback(
    (order: SelectOption | undefined) => {
      handleChangePage(firstPage);
      setSelectedOrder((order || {}).value || 'alpha');
    },
    [handleChangePage]
  );

  return (
    <SpecialtiesContainer>
      <SubHeader
        background={partner?.banner?.specialties! || specialtiesBanner}
      >
        <Breadcrumb
          crumbs={[
            <PartnerLink className="home" to="/home">
              Início
            </PartnerLink>,
            <PartnerLink to="/specialties">Especialidades</PartnerLink>,
          ]}
        />
        <SubHeaderDivider />
        <GoBack />
        <SubHeaderTitle title={'Especialidades'} />
      </SubHeader>

      <SpecialtiesTitleAndOrderContainer className="max-content">
        <h2>Descubra aqui</h2>

        <Select
          label={"Classificar por"}
          options={orderOptions}
          value={orderOptions.find((o) => o.value === selectedOrder)}
          setValue={handleChangeOrder}
        />
      </SpecialtiesTitleAndOrderContainer>

      <SpecialtiesList className="max-content">
        {!!specialtiesToShow.length &&
          specialtiesToShow.map((specialty) => (
            <SpecialtyCard key={specialty.content_id} specialty={specialty} />
          ))}
      </SpecialtiesList>

      <Pagination
        pageCount={Math.ceil(specialties.length / numberOfItemsPerPage)}
        onPageChange={handleChangePage}
      />
    </SpecialtiesContainer>
  );
};

export default Specialties;
