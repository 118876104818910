import { ReactNode } from 'react'
import { useLocation } from 'react-router-dom'
import Footer from './components/Footer'
import { Header } from './components/Header'
import useApp from 'src/contexts/AppContext/useApp'
import { AppLayoutContainer, ContentContainer } from './style'

interface IAppLayout {
  children: ReactNode
}

export function AppLayout({ children }: IAppLayout) {
  const location = useLocation()
  const { setShowUserContextMenu } = useApp()

  const hideContextUseMenu = () => {
    setShowUserContextMenu(false)
  }

  const waysToDisableHeader = [
    '/login',
    '/sign-up',
    '/validate-certificate',
  ]

  return (
    <AppLayoutContainer onClick={hideContextUseMenu}>
      {!waysToDisableHeader.includes(location.pathname) && (
        <Header />
      )}

      <ContentContainer>
        {children}
      </ContentContainer>

      <Footer />
    </AppLayoutContainer>
  )
}
