import { FormEvent, useState } from 'react'
import FormInput from 'src/components/FormInput'
import FormButton from 'src/components/FormButton/style'
import { PasswordRules, validatePassword } from 'src/components/PasswordRules'
import { SecurityContainer } from './style'
import useUser from 'src/contexts/UserContext/useUser'
import { changePasswordService } from 'src/services/sessionsService'
import SystemError from 'src/models/error'
import { showSuccessMessage, showErrorMessage } from 'src/helpers'

export function Security() {
  const { user } = useUser()

  const [password, setPassword] = useState<string>('')
  const [newPassword, setNewPassword] = useState<string>('')
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState<string>('')

  const cleanForm = () => {
    setPassword('')
    setNewPassword('')
    setNewPasswordConfirmation('')
  }

  const validateForm = (): boolean => {
    try {
      if ([password, newPassword, newPasswordConfirmation].includes('')) {
        throw new Error('Preencha todos os campos.')
      }

      if (newPassword !== newPasswordConfirmation) {
        throw new Error('A nova senha e a de confirmação devem ser iguais.')
      }

      if (password === newPassword) {
        throw new Error('A nova senha não pode ser igual a senha antiga.')
      }

      if (!validatePassword(newPassword)) {
        throw new Error('Nova senha inválida.')
      }

      return true
    } catch (error) {
      showErrorMessage(error as SystemError)
      return false
    }
  }

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    try {
      const isValidatedForm = validateForm()

      if (!isValidatedForm) return

      await changePasswordService({
        password,
        new_password: newPassword,
        email: user?.email || ''
      })

      showSuccessMessage('Senha alterada!')
      cleanForm()
    } catch (error: any) {
      const axiosMessageError: string = error?.response?.data?.message || ''

      if (axiosMessageError.toLowerCase() === 'usuario ou senha invalido') {
        const errorPassword = new Error('Senha atual inválida.')
        showErrorMessage(errorPassword as SystemError)
        return
      }

      showErrorMessage(error as SystemError)
    }
  }

  return (
    <SecurityContainer onSubmit={handleSubmit}>
      <div>
        <h1 className="main-title">Segurança</h1>

        <h4 className="subtitle">
          Edite sua senha a qualquer momento para manter sua segurança!
        </h4>
      </div>

      <div className="input-container">
        <div className="input-inner-container">
          <FormInput
            label="Senha atual"
            value={password}
            onChange={(value) => setPassword(value)}
            type="password"
          />

          <FormInput
            label="Nova senha"
            value={newPassword}
            onChange={(value) => setNewPassword(value)}
            type="password"
          />
        </div>

        <FormInput
          label="Confirme a nova senha"
          value={newPasswordConfirmation}
          onChange={(value) => setNewPasswordConfirmation(value)}
          type="password"
        />
      </div>

      <PasswordRules password={newPassword} />

      <FormButton type="submit">
        SALVAR ALTERAÇÕES
      </FormButton>
    </SecurityContainer>
  )
}
