import { createContext } from 'react';
import Content from 'src/models/content';

interface ILandingContext {
  contents: Content[];
}

const LandingContext = createContext({} as ILandingContext);

export default LandingContext;
