import httpClient from 'src/http-client'
import Author from 'src/models/author'
import Content from 'src/models/content'
import Paginated from 'src/models/paginated'

export const getLandingContentsService = async (
  params?: any
): Promise<Paginated<Content>> => {
  const { data } = await httpClient.get(`/contents/landing`, { params })
  return data
}

export const getLandingAuthorsService = async (): Promise<Author[]> => {
  const { data } = await httpClient.get<Author[]>(`/authors/landing`)
  return data
}
