import React, { useEffect, useRef, useState } from 'react';
import { BsDownload } from 'react-icons/bs';
import clamp from 'clamp-js';
import {
  CertificateCardContainer,
  CertificateCardThumbnail,
  CertificateCardTitle,
  CertificateDownloadButton,
} from './style';
import useLoading from 'src/contexts/LoadingContext/useLoading';
import useWindowSize from 'src/contexts/WindowSizeContext/useWindowSize';
import { downloadCertificateService } from 'src/services/certificatesService';
import { showErrorMessage } from 'src/helpers';
import { ICertificate } from 'src/models/certificate';
import SystemError from 'src/models/error';

interface CertificateCardProps {
  certificate: ICertificate;
}

const CertificateCard: React.FC<CertificateCardProps> = ({ certificate }) => {
  const title = useRef<HTMLHeadingElement>(null);

  const { setIsLoading } = useLoading();
  const { windowWidth } = useWindowSize();

  const [hovered, setHovered] = useState(false);
  const handleMouseEnter = () => setHovered(true)
  const handleMouseLeave = () => setHovered(false)

  const handleDownloadCertificate = async () => {
    setIsLoading(true);

    try {
      const { content_id, content_name } = certificate
      if (window.ReactNativeWebView?.postMessage) {
        const data = {
          downloadCertificate: {
            contentId: content_id,
            name: content_name,
          }
        };
        window.ReactNativeWebView?.postMessage(JSON.stringify(data));
      }else{
        await downloadCertificateService(content_id, content_name)
      }
    } catch (error) {
      showErrorMessage(error as SystemError);
    }

    setIsLoading(false);
  }

  useEffect(() => {
    if (title.current) {
      clamp(title.current, { clamp: 2 });
    }
  }, []);

  return (
    <CertificateCardContainer
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleDownloadCertificate}
    >
      <CertificateCardThumbnail
        alt="certificate background"
        src={certificate.content?.images.thumbnail}
      />

      <CertificateCardTitle ref={title}>
        {certificate.content?.name}
      </CertificateCardTitle>

      <CertificateDownloadButton
        type="button"
        hovered={hovered || windowWidth <= 1000}
      >
        <BsDownload /> <span>Baixar Certificado</span>
      </CertificateDownloadButton>
    </CertificateCardContainer>
  );
};

export default CertificateCard;
