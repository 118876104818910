import { createContext, Dispatch, SetStateAction } from 'react';
import Content from 'src/models/content';
import { ISpecialty } from 'src/models/specialty';
import Theme from 'src/models/theme';

interface IVideosContext {
  specialty?: ISpecialty;
  theme?: Theme;
  selectedContent?: Content;
  changeSelectedContent: (content: Content) => void;
  allContents: Content[];
  allThemes: Theme[];
  search: string;
  setSearch: Dispatch<SetStateAction<string>>;
  reloadData: () => Promise<void>;
  filteredContents: Content[];
  autoPlay: boolean;
  setAutoPlay: Dispatch<SetStateAction<boolean>>;
  goToNextContent: () => void;
  reloadSelectedContent: () => void;
}

const VideosContext = createContext({} as IVideosContext);

export default VideosContext;
