import { createContext, Dispatch, SetStateAction } from 'react';

interface ISearchContext {
  searchText: string;
  setSearchText: Dispatch<SetStateAction<string>>;
  opened: boolean;
  setOpened: Dispatch<SetStateAction<boolean>>;
}

const SearchContext = createContext({} as ISearchContext);

export default SearchContext;
