import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ThemesContext from 'src/contexts/ThemesContext/ThemesContext';
import Theme from 'src/models/theme';
import { getThemesService } from 'src/services/themesService';

const ThemesContextProvider: React.FC = ({ children }) => {
  const location = useLocation();
  const [themes, setThemes] = useState([] as Theme[]);

  const getThemes = useCallback(async () => {
    const { data: themes } = await getThemesService({
      relations: ['children'],
    });
    setThemes(themes);
  }, []);

  useEffect(() => {
    if (
      location.pathname !== '' &&
      location.pathname !== '/' &&
      location.pathname !== '/login' &&
      location.pathname !== '/sign-up'
    ) {
      getThemes();
    }
  }, [getThemes, location.pathname]);

  return (
    <ThemesContext.Provider value={{ themes }}>
      {children}
    </ThemesContext.Provider>
  );
};

export default ThemesContextProvider;
