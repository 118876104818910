import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { GiHamburgerMenu } from 'react-icons/gi';
import { ModalChangePartner } from 'src/components/ModalChangePartner';
import { MobileMenu } from './MobileMenu';
import SearchComponent from './Search';
import DropdownHeader from './DropdownHeader';
import {
  HeaderWrapper,
  HeaderContainer,
  HeaderContent,
  HeaderLink,
  HeaderLinks,
  MobileHeaderLink,
  MobileHeaderLinks,
  MobileMenuOpenButton,
} from './style';
import useUser from 'src/contexts/UserContext/useUser';
import useMobileMenu from 'src/contexts/MobileMenuContext/useMobileMenu';
import useWindowSize from 'src/contexts/WindowSizeContext/useWindowSize';
import { ReactComponent as Logo } from 'src/assets/logos/logo-manole-play.svg';
import PartnerLink from 'src/components/PartnerLink';

export function Header() {
  const { user, navigate } = useUser();
  const { pathname } = useLocation();
  const { windowWidth } = useWindowSize();

  const { setOpened } = useMobileMenu();
  const handleOpenMobileMenu = () => setOpened(true)

  const [isOpenModalPartners, setIsOpenModalPartners] = useState(false);
  const openModalPartners = () => setIsOpenModalPartners(true);
  const closeModalPartners = () => setIsOpenModalPartners(false);

  return (
    <HeaderWrapper>
      <HeaderContainer>
        <HeaderContent className="max-content">
          {!!user ? (
            <PartnerLink to="/home">
              <Logo />
            </PartnerLink>
          ) : (
            <Link to="/">
              <Logo />
            </Link>
          )}

          {windowWidth > 1000 ? (
            <>
              <HeaderLinks>
                <HeaderLink
                  onClick={() => navigate('/home')}
                  active={pathname.includes('/home')}
                >
                  Início
                </HeaderLink>

                <HeaderLink
                  onClick={() => navigate('/specialties')}
                  active={
                    pathname.includes('/specialt') &&
                    !pathname.includes('/my-list')
                  }
                >
                  Especialidades
                </HeaderLink>

                <HeaderLink
                  onClick={() => navigate('/my-list')}
                  active={pathname.includes('/my-list')}
                >
                  Minha lista
                </HeaderLink>
              </HeaderLinks>

              <SearchComponent />

              <DropdownHeader openModalPartners={openModalPartners} />
            </>
          ) : (
            <>
              <MobileMenuOpenButton onClick={handleOpenMobileMenu}>
                <GiHamburgerMenu size={32} color="var(--secondary-color)" />
              </MobileMenuOpenButton>

              <MobileMenu openModalPartners={openModalPartners} />
            </>
          )}
        </HeaderContent>

        <ModalChangePartner
          isOpen={isOpenModalPartners}
          handleClose={closeModalPartners}
        />
      </HeaderContainer>

      <MobileHeaderLinks>
        <MobileHeaderLink
          onClick={() => navigate('/home')}
          active={pathname.includes('/home')}
        >
          Início
        </MobileHeaderLink>

        <MobileHeaderLink
          onClick={() => navigate('/specialties')}
          active={
            pathname.includes('/specialt') && !pathname.includes('/my-list')
          }
        >
          Especialidades
        </MobileHeaderLink>

        <MobileHeaderLink
          onClick={() => navigate('/my-list')}
          active={pathname.includes('/my-list')}
        >
          Minha lista
        </MobileHeaderLink>
      </MobileHeaderLinks>
    </HeaderWrapper>
  );
}
