import styled from 'styled-components';

const CarouselContentContainer = styled.div<{ background?: string }>`
  border-radius: 5px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: linear-gradient(
      180deg,
      rgba(20, 20, 20, 0) 0%,
      rgba(20, 20, 20, 0.7) 100%
    ),
    url(${({ background }) => background});
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
  padding: 24px;
  font-family: 'Open Sans', sans-serif;

  & > div {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  h3 {
    font-size: 20px;
    font-weight: 700;
  }

  p {
    font-size: 14px;
    font-weight: 300;
  }

  span {
    border: none;
    border-radius: 20px;
    padding: 8px 16px;
    background: rgb(17, 9, 43);
    background: linear-gradient(
      180deg,
      rgba(17, 9, 43, 0.7) 0%,
      rgba(17, 9, 43, 1) 50%,
      rgba(17, 9, 43, 0.7) 100%
    );
    color: var(--white);
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    font-weight: 600;
    text-shadow: 0px 2px 4px rgba(17, 9, 43, 0.8);
    width: fit-content;
  }

  .description {
    max-height: 80px;
    overflow: hidden;
  }
`;

export { CarouselContentContainer };
