import { MultiselectContainer, MultiselectCustom } from "./style"

interface IFormMultiselect {
  label?: string
  placeholder?: string
  displayValue: string
  options: any
  selectedValues: any
  onChange: (selectedList: any, selectedItem: any) => void
  disabled?: boolean
}

export function FormMultiselect({
  label,
  placeholder,
  displayValue,
  options,
  selectedValues,
  onChange,
  disabled
}: IFormMultiselect) {
  return (
    <MultiselectContainer>
      {label && <label htmlFor={label}>{label}</label>}

      <MultiselectCustom
        placeholder={placeholder || label}
        displayValue={displayValue}
        options={options}
        selectedValues={selectedValues}
        onSelect={onChange}
        onRemove={onChange}
        showCheckbox
        disable={disabled}
        style={{
          searchBox: {
            borderRadius: '50px',
            background: disabled ? '#E1E3E6' : '#F5F8FA',
            padding: '16px'
          },
          chips: {
            background: 'var(--primary-color)',
          },
        }}
      />
    </MultiselectContainer>
  )
}
