import React, { useCallback, useEffect, useState } from 'react';
import { SideContent } from 'src/components/SideContent';
import FormInput from 'src/components/FormInput';
import FormButton from 'src/components/FormButton/style';
import {
  LoginContainer,
  LoginForm,
  LoginFormButtonGroup,
  LoginHeader,
  SideAndFormContainer,
} from './style';
import { getUserService } from 'src/services/usersService';
import useUser from 'src/contexts/UserContext/useUser';
import useLoading from 'src/contexts/LoadingContext/useLoading';
import SystemError from 'src/models/error';
import { ILoginCredentials } from 'src/models/loginModel';
import { showErrorMessage, checkStringIsEmpty } from 'src/helpers';
import { ReactComponent as Logo } from 'src/assets/logos/logo-manole-play.svg';

const Login: React.FC = () => {
  const { setIsLoading } = useLoading();
  const { login, logout, getUser, refreshToken, navigate } = useUser();

  const [credentials, setCredentials] = useState({} as ILoginCredentials);

  const validateLogin = useCallback(async () => {
    try {
      const hasToken = !!window.localStorage.getItem('manole-api-token');
      if (!hasToken) return

      await getUserService();
      await getUser();

      navigate('/home');
    } catch (error) {
      try {
        await refreshToken();
        await getUser();
        navigate('/home');
      } catch (err) {
        logout()
        showErrorMessage(error as SystemError);
      }
    }
  }, [navigate, logout, refreshToken, getUser])

  useEffect(() => { validateLogin() }, [validateLogin]);

  const handleChangeCredentials = (propName: string, newValue: any) => {
    setCredentials({
      ...credentials,
      [propName]: newValue
    });
  }

  const validate = () => {
    if (checkStringIsEmpty(credentials.email)) {
      throw new Error('Informe seu email!');
    }

    if (checkStringIsEmpty(credentials.password)) {
      throw new Error('Informe sua senha!');
    }
  }

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    setIsLoading(true);

    try {
      validate();

      await login(credentials);
      navigate('/home');
    } catch (error) {
      showErrorMessage(error as SystemError);
    }

    setIsLoading(false);
  }

  return (
    <LoginContainer>
      <LoginHeader className="max-content">
        <Logo />
      </LoginHeader>

      <SideAndFormContainer className="max-content">
        <SideContent text="Acesse sua conta" />

        <LoginForm onSubmit={handleSubmit}>
          <FormInput
            label="E-mail"
            value={credentials.email || ''}
            type="email"
            onChange={(value) => handleChangeCredentials('email', value)}
          />

          <FormInput
            label="Senha"
            value={credentials.password || ''}
            type="password"
            onChange={(value) => handleChangeCredentials('password', value)}
          />

          <LoginFormButtonGroup>
            <a href='https://atendimento.manole.com.br/recuperar-senha/manole' target='_blank' rel="noreferrer">
              Esqueci minha senha
            </a>

            <FormButton>ENTRAR</FormButton>
          </LoginFormButtonGroup>
        </LoginForm>
      </SideAndFormContainer>
    </LoginContainer>
  );
};

export default Login;
