import { useCallback, useEffect, useState } from 'react';
import HomeContext from 'src/contexts/HomeContext/HomeContext';
import useLoading from 'src/contexts/LoadingContext/useLoading';
import {
  getContentUsersService,
  IContentUsersParams,
} from 'src/services/contentUsersService';
import {
  getContentsService,
  getContentRecommendationsByTagService,
} from 'src/services/contentsService';
import Content from 'src/models/content';
import useUser from '../UserContext/useUser';

const HomeContextProvider: React.FC = ({ children }) => {
  const { setIsLoading } = useLoading();
  const { selectedPartner: partner } = useUser();

  const [contentsInProgress, setContentsInProgress] = useState<Content[]>();
  const [featuredContents, setFeaturedContents] = useState<Content[]>();
  const [suggestedContents, setSuggestedContents] = useState<Content[]>();
  const [newContents, setNewContents] = useState<Content[]>();

  const getContentsProgress = useCallback(
    async (params: IContentUsersParams = {}) => {
      let contents: Content[] = [];

      if (partner) {
        const segmentation_item_ids = [partner.segmentation_item_id];
        const contentsProgress = await getContentUsersService({
          ...params,
          segmentation_item_ids,
          relations: ['content'],
        });

        contents = (contentsProgress || []).map((c) => {
          const contentView = c.content_view || 0;
          const duration = c.content?.duration || 1;
          const progress = (contentView / duration) * 100;

          return {
            ...(c.content! || {}),
            progress,
          };
        });
      }

      setContentsInProgress(contents);
    },
    [partner]
  );

  const getFeaturedContents = useCallback(async () => {
    if (!partner) return [];

    const contents = (
      await getContentsService(
        partner
          ? {
            segmentation_item_ids: [partner.segmentation_item_id],
          }
          : {}
      )
    ).data.filter((content) => content.is_highlight);
    setFeaturedContents(contents);
  }, [partner]);

  const getSuggestedContents = useCallback(async () => {
    if (!partner) return [];

    const contents = await getContentRecommendationsByTagService({
      limit: 20,
      segmentation_item_ids: [partner.segmentation_item_id],
      relations: ['segmentations'],
    });
    setSuggestedContents(contents);
  }, [partner]);

  const getNewContents = useCallback(async () => {
    if (!partner) return [];

    const { data: contents } = await getContentsService({
      segmentation_item_ids: [partner.segmentation_item_id],
      order_by: 'updated_at',
      sort_by: 'DESC',
      limit: 20,
    });

    setNewContents(contents);
  }, [partner]);

  const getContents = useCallback(async () => {
    setIsLoading(true);

    await getContentsProgress({
      relations: ['content'],
      limit: 12,
      in_progress: true,
    });

    await getFeaturedContents();
    await getSuggestedContents();
    await getNewContents();

    setIsLoading(false);
  }, [
    getContentsProgress,
    getFeaturedContents,
    getNewContents,
    getSuggestedContents,
    setIsLoading,
  ]);

  useEffect(() => {
    getContents();
  }, [getContents]);

  return (
    <HomeContext.Provider
      value={{
        contentsInProgress,
        featuredContents,
        suggestedContents,
        newContents,
      }}
    >
      {children}
    </HomeContext.Provider>
  );
};

export default HomeContextProvider;
