import styled from 'styled-components';

const TextContainer = styled.div`
  color: var(--text-color);
  display: flex;
  flex-wrap: wrap;
  font-family: 'Open Sans', sans-serif;
  justify-content: space-evenly;
  height: 480px;
  padding: 0 24px;

  @media screen and (max-width: 640px) {
    text-align: center;
    gap: 32px;
  }

  & > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 24px;
    min-width: 320px;
    max-width: 460px;

    & > h1 {
      @media screen and (max-width: 640px) {
        font-size: 24px;
      }
      & > span {
        color: var(--primary-color);
      }
    }

    & > p {
      font-size: 24px;

      @media screen and (max-width: 640px) {
        font-size: 18px;
      }
    }
  }

  @media (max-width: 1200px) {
    justify-content: center;
    gap: 32px;
  }

  @media (max-width: 1000px) {
    gap: 16px;
    height: 420px;
    margin-top: 24px;
    margin-bottom: 24px;

    & > div {
      gap: 8px;
    }
  }
`;

export { TextContainer };
