import React from 'react';
import { SubHeaderTitleContainer } from './style';

interface PageTitleProps {
  title: string;
}

const SubHeaderTitle: React.FC<PageTitleProps> = ({ title }) => {
  return <SubHeaderTitleContainer>{title}</SubHeaderTitleContainer>;
};

export default SubHeaderTitle;
