import styled from 'styled-components'

interface StylesSwiperProps {
  signUp?: boolean
}

export const StylesSwiper = styled.div<StylesSwiperProps>`
  margin: 0 auto;
  width: 100%;
  max-width: 332px;

  .swiper-pagination-bullet {
    transform: scale(0.7);
    transition: all 0.3s;

    &.swiper-pagination-bullet-active {
      transform: scale(1.4);
      transition: all 0.3s;
      background: var(--primary-color);
    }
  }

  .swiper-container {
    width: 100%;
  }

  .swiper-slide {
    padding-bottom: ${props => props.signUp ? '38' : '45'}px;
    background: unset;
    text-align: left;
  }

  @media (min-width: 650px) {
    width: 410px;
    min-width: 410px;

    :not(:hover) {
      .swiper-button-next, .swiper-button-prev {
        opacity: 0;
      }
    }

    .swiper-button-next, .swiper-button-prev {
      width: 70px;
      height: calc(100% - 30px);
      max-height: 630px;
      top: 0;
      transition: opacity ease-out .3s;
      color: var(--text-color);
      transition: all .3s;
      ::after {
        transition: all .3s;
      }
      :hover::after {
        transform: scale(1.2);
      }
    }

    .swiper-button-next {
      padding-left: 40px;
      right: 0;
    }

    .swiper-button-prev {
      padding-right: 40px;
      left: 0;
    }

    .swiper-button-prev:after,
    .swiper-button-next:after {
      font-size: 16px !important;
      font-weight: bold !important;
    }
  }

  @media (min-width: 1300px) {
    width: 830px;
    min-width: 830px;

    .swiper-slide-active {
      padding-left: 60px;
    }
    .swiper-slide-next {
      padding-right: 60px;
    }

    .swiper-slide {
      transition: opacity ease-out .5s;
      opacity: 0;
    }

    .swiper-slide-active, .swiper-slide-next {
      opacity: 1;
    }
  }
`
