import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  height: 700px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;

  img {
    position: absolute;
    top: 0;
    left: 0;
    filter: brightness(60%);
    width: 100%;
    height: 100%;
  }
`;

const ContainerContentBanner = styled.div`
  z-index: 9;

  .inner-wrapper {
    @media (min-width: 920px) {
      width: 64%;
      margin-left: 120px;
    }
  }
`;

const ContentBanner = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;

  h1 {
    font-size: 56px;
    font-weight: bold;
    line-height: 72px;
    text-align: left;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    color: var(--white);

    @media screen and (max-width: 640px) {
      font-size: 32px;
      line-height: normal;
    }
  }

  p {
    color: var(--white);
    font-style: normal;
    font-size: 18px;
    line-height: 24px;
    text-align: left;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  div.actions {
    display: flex;
    gap: 20px;

    svg {
      font-size: 16px;
      height: 20px;
    }

    @media screen and (max-width: 640px) {
      width: 100%;

      button {
        width: 100%;
        max-width: 100%;
        font-size: 14px;
      }
    }
    @media screen and (max-width: 360px) {
      flex-direction: column;
    }
  }

  .description {
    overflow: hidden;
    text-overflow: ellipsis;
    -moz-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    text-align: justify;
  }
`;

const ContainerTheme = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;

  span {
    display: flex;
    align-items: center;
    padding: 4px 8px;
    border-radius: 20px;
    background: rgba(0, 0, 0, 0.75);
    color: var(--primary-color);
    font-size: 18px;
    line-height: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media screen and (max-width: 640px) {
      font-size: 12px;
    }
  }
`;

export { Container, ContainerContentBanner, ContentBanner, ContainerTheme };
