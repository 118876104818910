import styled from "styled-components";

import Button from "src/components/Button";
import { useCallback } from "react";

const DownloadButton = styled(Button)`
  width: 100%;
  background: var(--primary-color);
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  border-radius: 2px;

  &[data-disabled='disabled'] {
    opacity: 0.7;
  }
`;

interface DownloadVideoButtonProps {
  contentId: string;
  specialtyId?: string;
}

const DownloadVideoButton: React.FC<DownloadVideoButtonProps> = ({contentId, specialtyId}) => {
  const handleClick = useCallback(
    (e) => {
      e.stopPropagation();

      // O attr `disabled` não funcionou no web-view, então utiliza `data-disabled`
      const disabledAttr = document.querySelector('#download-video-button')?.getAttribute('data-disabled') || 'false';
      if(disabledAttr !== 'false') {
        return;
      }

      const data = {
        downloadVideo: {
          contentId: contentId,
          specialtyId: specialtyId,
          webUrl: window.location.pathname,
        }
      };
      window.ReactNativeWebView?.postMessage(JSON.stringify(data));
    },
    [contentId, specialtyId],
  )

  return (
    <DownloadButton
      data-disabled='disabled'
      data-content-id={contentId}
      id="download-video-button"
      type="button"
      onClick={handleClick}
    >
      <span>Carregando...</span>
    </DownloadButton>
  )
}

export default DownloadVideoButton;
