import { useEffect, useMemo, useRef } from 'react';
import clamp from 'clamp-js';
import useLanding from 'src/contexts/LandingContext/useLanding';
import Author from 'src/models/author';
import { CardWrapper, CardImage } from './style';

interface AuthorCardProps {
  author: Author;
}

const AuthorCard: React.FC<AuthorCardProps> = ({ author }) => {
  const title = useRef<HTMLHeadingElement>(null);
  const description = useRef<HTMLParagraphElement>(null);
  const { contents } = useLanding();

  const numberOfContents = useMemo(() => {
    return (contents || []).length
      ? contents.filter((c) =>
        (c.authors || []).map((a) => a.author_id).includes(author.author_id)
      ).length
      : 0;
  }, [author.author_id, contents]);

  useEffect(() => {
    if (title.current) {
      clamp(title.current, { clamp: 2 });
    }
  }, []);

  useEffect(() => {
    if (description.current) {
      clamp(description.current, { clamp: 3 });
    }
  }, []);

  return (
    <CardWrapper>
      <CardImage background={author.images.avatar}>
        <div>
          <h1>
            {numberOfContents} VÍDEO{numberOfContents > 1 ? 'S' : ''}
          </h1>
        </div>
        <div>
          {author.info.specialization ? (
            <span>{author.info.specialization}</span>
          ) : (
            ''
          )}
          <h3 ref={title}>{author.name}</h3>
          <p ref={description}>{author.description}</p>
        </div>
      </CardImage>
    </CardWrapper>
  );
};

export default AuthorCard;
