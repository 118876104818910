import React from 'react';
import { ProfileMenus } from '../..';
import { MenuButton, MenuContainer } from './style';

interface MenuProps {
  page: ProfileMenus;
  onClick: (page: ProfileMenus) => void;
  paidAccount: boolean;
}

const Menu: React.FC<MenuProps> = ({ page, onClick, paidAccount }) => {
  return (
    <MenuContainer>
      <MenuButton
        type="button"
        isActive={page === ProfileMenus.personalData}
        onClick={() => onClick(ProfileMenus.personalData)}
      >
        Dados Pessoais
      </MenuButton>
      <MenuButton
        type="button"
        isActive={page === ProfileMenus.security}
        onClick={() => onClick(ProfileMenus.security)}
      >
        Segurança
      </MenuButton>
      {paidAccount && (
        <MenuButton
          type="button"
          isActive={page === ProfileMenus.signature}
          onClick={() => onClick(ProfileMenus.signature)}
        >
          Assinatura
        </MenuButton>
      )}
      <MenuButton
        type="button"
        isActive={page === ProfileMenus.certificate}
        onClick={() => onClick(ProfileMenus.certificate)}
      >
        Certificados
      </MenuButton>
    </MenuContainer>
  );
};

export default Menu;
