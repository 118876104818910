import { useState } from 'react'
import { FiX } from 'react-icons/fi'
import { ModalContainer } from './style'
import FormButton from 'src/components/FormButton/style'
import { PlansSlider } from 'src/components/PlansSlider'
import Plan from 'src/models/plan'
import { showErrorMessage } from 'src/helpers'
import SystemError from 'src/models/error'

interface IModalChangeSubscription {
  isOpen: boolean
  handleClose: () => void
}

export function ModalChangeSubscription({ isOpen, handleClose }: IModalChangeSubscription) {
  const [planSelected, setPlanSelected] = useState<Plan | null>(null)

  const handleConfirmation = () => {
    try {
      if (!planSelected) throw new Error('Selecione um plano!')

      const { url_plan } = planSelected.info

      handleClose()

      // Send plan id to app instead of opening the plan url
      if (window.ReactNativeWebView?.postMessage) {
        const data = {
          planId: planSelected.plan_id,
        };
        window.ReactNativeWebView?.postMessage(JSON.stringify(data));
      } else {
        window.open(url_plan)
      }
    } catch (error) {
      showErrorMessage(error as SystemError)
    }
  }

  if (!isOpen) return null

  return (
    <ModalContainer>
      <div className="pop-up">
        <header>
          <h3>Selecione uma assinatura</h3>
          <FiX onClick={handleClose} />
        </header>

        <PlansSlider
          selectPlan={setPlanSelected}
          selectedPlan={planSelected?.plan_id}
        />

        <footer>
          <FormButton onClick={handleClose} redOnWhite>
            CONTINUAR MINHA ASSINATURA
          </FormButton>

          <FormButton onClick={handleConfirmation}>
            ALTERAR ASSINATURA
          </FormButton>
        </footer>
      </div>
    </ModalContainer>
  )
}
