import httpClient from 'src/http-client'
import { ISpecialty, IPublicSpecialty } from 'src/models/specialty'
import Paginated from 'src/models/paginated'

export const getSpecialtiesService = async (filters?: any) => {
  const { data } = await httpClient.get<Paginated<ISpecialty>>(`/contents`, {
    params: {
      ...filters,
      is_active: true,
      flag: ['specialty'],
      relations: ['segmentations', ...(filters.relations || [])],
    },
  })
  return data
}

export const getSpecialtyService = async (specialtyId: string, filters?: any) => {
  const { data: specialty } = await httpClient.get<ISpecialty>(`/contents/${specialtyId}`, {
    params: {
      ...filters,
      relations: [
        'children',
        'authors',
        'content_user',
        'extra_materials',
        ...(filters?.relations || []),
      ],
    },
  })

  if (specialty.children?.length) {
    for (let theme of specialty.children) {
      if (theme.children?.length) {
        for (let content of theme.children) {
          content.themeId = theme.content_id
        }
      }
    }
  }

  return specialty
}

export const getPublicSpecialtiesService = async () => {
  const { data } = await httpClient.get<IPublicSpecialty[]>('contents/list/specialty')
  return data
}

export const startSpecialtyService = async (specialtyId: string) => {
  const { data } = await httpClient.post(`/content-users/${specialtyId}/start`)
  return data
}

export const addToListSpecialtyService = async (specialtyId: string, addToList: boolean) => {
  const { data } = await httpClient.patch(`/content-users/${specialtyId}/bookmark`, {
    book_mark: addToList,
  })
  return data
}

export const finishSpecialtyService = async (specialtyId: string) => {
  const { data } = await httpClient.post(`/content-users/${specialtyId}/finish`)
  return data
}
