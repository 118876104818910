import { useEffect, useState } from 'react'
import FormButton from 'src/components/FormButton/style'
import { ModalChangeSubscription } from 'src/components/ModalChangeSubscription'
import { ModalCancelSubscription } from './components/ModalCancelSubscription'
import { SubscriptionContainer } from './style'
import { getPlanService } from 'src/services/plansService'
import Plan from 'src/models/plan'

export function Subscription() {
  const [userPlan, setUserPlan] = useState<Plan | null>(null)

  const [displayModalChangeSubscription, setDisplayModalChangeSubscription] = useState(false)
  const [displayModalCancelSubscription, setDisplayModalCancelSubscription] = useState(false)

  const getPlan = async () => {
    const manole_version_id = localStorage.getItem('manole-version-id') || ''
    const plan = await getPlanService(manole_version_id)
    setUserPlan(plan)
  }

  useEffect(() => { getPlan() }, [])

  const handleCloseModalChangeSubscription = () => {
    setDisplayModalChangeSubscription(false)
  }

  const handleCloseModalCancelSubscription = () => {
    setDisplayModalCancelSubscription(false)
  }

  return (
    <>
      <SubscriptionContainer>
        <h1 className="main-title">Assinatura</h1>
        <h4 className="subtitle">
          Visualize e altere seu plano de assinatura aqui.
        </h4>

        <h5 className="small-title">Tipo de assinatura</h5>
        <h3 className="section-title">{userPlan?.name || 'Nome do plano'}</h3>

        <h5 className="small-title">Preço</h5>
        <h4 className="subtitle">{`R$ ${userPlan?.price || '0.00'}`}</h4>

        <div className="buttons-container">
          <FormButton onClick={() => setDisplayModalChangeSubscription(true)}>
            ALTERAR PLANO
          </FormButton>

          <FormButton onClick={() => setDisplayModalCancelSubscription(true)} redOnWhite>
            CANCELAR ASSINATURA
          </FormButton>
        </div>
      </SubscriptionContainer>

      <ModalChangeSubscription
        isOpen={displayModalChangeSubscription}
        handleClose={handleCloseModalChangeSubscription}
      />

      <ModalCancelSubscription
        isOpen={displayModalCancelSubscription}
        handleClose={handleCloseModalCancelSubscription}
      />
    </>
  )
}
