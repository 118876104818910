import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AiOutlineDown } from 'react-icons/ai';
import Button from 'src/components/Button';
import { Select, SelectOption } from 'src/components/Select';
import GoBack from 'src/components/GoBack';
import SubHeader from 'src/components/SubHeader';
import VideoCard from 'src/components/VideoCard';
import Breadcrumb from 'src/components/Breadcrumb';
import Pagination from 'src/components/Pagination';
import SpecialtyCard from 'src/components/SpecialtyCard';
import { SubHeaderDivider } from 'src/components/SubHeaderDivider';
import SubHeaderTitle from 'src/components/SubHeader/SubHeaderTitle';
import {
  MyListContainer,
  SpecialtiesContainer,
  ContentsContainer,
  ShowMoreButtonContainer,
  Title,
  TitleAndSpecialtyContainer,
  ContentesAndSpecialtiesDivider,
} from './style';
import { getContentUsersService } from 'src/services/contentUsersService';
import useLoading from 'src/contexts/LoadingContext/useLoading';
import Content from 'src/models/content';
import { ISpecialty } from 'src/models/specialty';
import ContentUser from 'src/models/content-user';
import { goToTop, removeAccentuation } from 'src/helpers';
import specialtiesBanner from 'src/assets/specialties.png';
import useUser from 'src/contexts/UserContext/useUser';
import PartnerLink from 'src/components/PartnerLink';

type ShowingOptions = 'both' | 'contents' | 'specialties';

type Orders = 'most_recent' | 'alpha';

const numberOfContentsPerPage = 12;
const numberOfContentsPerPageExpanded = 18;
const firstPage = 1;

const orderOptions: SelectOption[] = [
  { label: 'Mais recentes', value: 'most_recent' },
  { label: 'Ordem alfabética', value: 'alpha' },
]

const MyList: React.FC = () => {
  const { showingContent } = useParams<'showingContent'>();

  const { setIsLoading } = useLoading();
  const { selectedPartner: partner, navigate } = useUser();

  const [page, setPage] = useState(firstPage);
  const [contents, setContents] = useState([] as Content[]);
  const [specialties, setSpecialties] = useState([] as ISpecialty[]);

  const [showing, setShowing] = useState<ShowingOptions>('both');
  const [selectedOrder, setSelectedOrder] = useState<Orders>('alpha');

  const getSpecialtiesAndContents = useCallback(async () => {
    setIsLoading(true);

    let specialtiesAndContentUsers = [] as ContentUser[];

    if (partner) {
      specialtiesAndContentUsers = (await getContentUsersService({
        book_mark: true,
        relations: ["content"],
        segmentation_item_ids: [partner?.segmentation_item_id]
      })) as ContentUser[];
    }

    const specialtiesAndContents = (specialtiesAndContentUsers || []).map(
      (c) => ({ ...c.content })
    ) as (ISpecialty | Content)[];

    const contents = (specialtiesAndContents || []).filter(
      (c) => c.flag === 'content'
    ) as Content[];

    const specialties = (specialtiesAndContents || []).filter(
      (c) => c.flag === 'specialty'
    ) as ISpecialty[];

    setContents(contents);
    setSpecialties(specialties);

    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setIsLoading]);

  useEffect(() => { getSpecialtiesAndContents(); }, [getSpecialtiesAndContents]);

  const contentsToShow = useMemo(() => {
    let result = [...(contents || [])];

    if (selectedOrder === 'most_recent') {
      result = (result || []).sort((c1, c2) => {
        const c1Date = new Date(c1.created_at);
        const c2Date = new Date(c2.created_at);

        return c1Date.getTime() > c2Date.getTime() ? -1 : 1;
      });
    } else {
      result = (result || []).sort((c1, c2) => {
        return removeAccentuation(c1.name) > removeAccentuation(c2.name)
          ? 1
          : -1;
      });
    }

    if (showing === 'both') {
      result = result.slice(0, numberOfContentsPerPage);
    } else if (showing === 'contents') {
      result = result.slice(
        numberOfContentsPerPageExpanded * (page - 1),
        numberOfContentsPerPageExpanded * page
      );
    }

    return result;
  }, [contents, page, selectedOrder, showing]);

  const specialtiesToShow = useMemo(() => {
    let result = [...(specialties || [])];

    if (selectedOrder === 'most_recent') {
      result = (result || []).sort((c1, c2) => {
        const c1Date = new Date(c1.created_at);
        const c2Date = new Date(c2.created_at);

        return c1Date.getTime() > c2Date.getTime() ? -1 : 1;
      });
    } else {
      result = (result || []).sort((c1, c2) => {
        return removeAccentuation(c1.name) > removeAccentuation(c2.name)
          ? 1
          : -1;
      });
    }

    if (showing === 'both') {
      result = result.slice(0, numberOfContentsPerPage);
    } else if (showing === 'specialties') {
      result = result.slice(
        numberOfContentsPerPageExpanded * (page - 1),
        numberOfContentsPerPageExpanded * page
      );
    }

    return result;
  }, [page, selectedOrder, showing, specialties]);

  useEffect(() => {
    if (showingContent) {
      setShowing(showingContent as ShowingOptions);
    } else {
      setShowing('both');
    }
  }, [showingContent]);

  const handleChangePage = useCallback((selectedPage: number) => {
    setPage(selectedPage);
    goToTop();
  }, []);

  const handleChangeShowing = useCallback(
    (showing: ShowingOptions) => {
      handleChangePage(firstPage);
      setShowing(showing);

      if (showing === 'both') {
        navigate(`/my-list`);
      } else {
        navigate(`/my-list/${showing}`);
      }
    },
    [handleChangePage, navigate]
  );

  const showContents = useCallback(() => {
    handleChangeShowing('contents');
  }, [handleChangeShowing]);

  const showSpecialties = useCallback(() => {
    handleChangeShowing('specialties');
  }, [handleChangeShowing]);

  return (
    <MyListContainer>
      <SubHeader background={partner?.banner?.mylist || specialtiesBanner}>
        <Breadcrumb
          crumbs={[
            <PartnerLink className="home" to="/home">
              Início
            </PartnerLink>,
            <PartnerLink to="/my-list">Minha Lista</PartnerLink>,
          ]}
        />
        <SubHeaderDivider />
        <GoBack />
        <SubHeaderTitle title="Minha Lista" />
      </SubHeader>

      {(showing === 'both' || showing === 'contents') && (
        <ContentsContainer className="max-content">
          <TitleAndSpecialtyContainer>
            <Title>Vídeos ({contents.length || 0})</Title>

            {showing === 'contents'
              ? (
                <Select
                  label="Classificar por"
                  options={orderOptions}
                  value={orderOptions.find((o) => o.value === selectedOrder)}
                  setValue={(order) =>
                    setSelectedOrder((order || {}).value as Orders)
                  }
                />
              )
              : (
                <span></span>
              )}
          </TitleAndSpecialtyContainer>

          {!!contentsToShow?.length &&
            contentsToShow.map((c) => (
              <VideoCard key={c.content_id} video={c} />
            ))
          }

          {showing === 'both'
            ? (
              contents?.length > numberOfContentsPerPage &&
              <ShowMoreButtonContainer>
                <Button type="button" onClick={showContents}>
                  <span>Ver Mais</span> <AiOutlineDown />
                </Button>
              </ShowMoreButtonContainer>
            )
            : (
              contents?.length > numberOfContentsPerPageExpanded &&
              <Pagination
                onPageChange={handleChangePage}
                pageCount={Math.ceil(
                  (contents || []).length / numberOfContentsPerPageExpanded
                )}
              />
            )}
        </ContentsContainer>
      )}

      {showing === 'both' && (
        <ContentesAndSpecialtiesDivider className="max-content" />
      )}

      {(showing === 'both' || showing === 'specialties') && (
        <SpecialtiesContainer className="max-content">
          <TitleAndSpecialtyContainer>
            <Title>
              Especialidades ({specialties.length || 0})
            </Title>

            {showing === 'specialties'
              ? (
                <Select
                  label="Classificar por"
                  options={orderOptions}
                  value={orderOptions.find((o) => o.value === selectedOrder)}
                  setValue={(order) =>
                    setSelectedOrder((order || {}).value as Orders)
                  }
                />
              )
              : (
                <span></span>
              )}
          </TitleAndSpecialtyContainer>

          {!!specialtiesToShow?.length &&
            specialtiesToShow.map((s) => (
              <SpecialtyCard key={s.content_id} specialty={s} />
            ))
          }

          {showing === 'both'
            ? (
              specialties?.length > numberOfContentsPerPage &&
              <ShowMoreButtonContainer>
                <Button type="button" onClick={showSpecialties}>
                  <span>Ver Mais</span> <AiOutlineDown />
                </Button>
              </ShowMoreButtonContainer>
            )
            : (
              specialties?.length > numberOfContentsPerPageExpanded &&
              <Pagination
                onPageChange={handleChangePage}
                pageCount={Math.ceil(
                  (specialties || []).length / numberOfContentsPerPageExpanded
                )}
              />
            )}
        </SpecialtiesContainer>
      )}
    </MyListContainer>
  );
};

export default MyList;
