import styled from 'styled-components'

export const MobileMenuContainer = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;

  display: flex;

  nav.menu {
    display: flex;
    flex-direction: column;
    background: var(--background-white);
    padding: 32px 40px;
    flex-grow: 1;

    .logo-svg {
      width: 100%;
      max-width: 200px;
    }

    hr {
      border: 1px solid #CFD2D4;
      margin: 24px 0 32px;
    }

    ul.menu-itens {
      flex-grow: 1;
      list-style: none;

      .menu-item {
        font-size: 18px;
        line-height: 22px;
        font-weight: 400;
        color: var(--text-color);
        padding: 12px 0;
        transition: 0.2s;
        cursor: pointer;

        &:hover {
          color: var(--primary-color);
        }
      }
    }

    .menu-item-logout {
      display: flex;
      align-items: center;
      gap: 8px;
      color: var(--primary-color);
      cursor: pointer;

      svg {
        color: var(--primary-color);
      }
    }
  }

  section.container-close-menu {
    background: rgba(41, 51, 116, 0.8);
    padding: 24px 12px;

    display: flex;
    align-items: flex-end;

    .btn-close-menu {
      width: 52px;
      height: 52px;
      border-radius: 100%;
      background: var(--background-white);
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
`
