import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import clamp from 'clamp-js';
import { IoMdAdd, IoMdPlay } from 'react-icons/io';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import { StyleDefaultButton } from '../StyleDefaultButton/styles';
import {
  Container,
  ContainerContentBanner,
  ContainerTheme,
  ContentBanner,
} from './style';
import useUser from 'src/contexts/UserContext/useUser';
import useThemes from 'src/contexts/ThemesContext/useThemes';
import Content from 'src/models/content';
import SystemError from 'src/models/error';
import { addToListContentService } from 'src/services/contentsService';
import { goToTop, showErrorMessage, showSuccessMessage } from 'src/helpers';

interface HighlightVideoSlideProps {
  content: Content;
}

const HighlightVideoSlide: React.FC<HighlightVideoSlideProps> = ({
  content: initialContent,
}) => {
  const title = useRef<HTMLHeadingElement>(null);

  const { userPlan, navigate } = useUser();
  const { themes } = useThemes();

  const [content, setContent] = useState({} as Content);

  const videoIsFree = !!initialContent.info?.is_free;
  const isContentBlocked = !videoIsFree && !userPlan?.planPremium;

  const goToContent = useCallback(async () => {
    navigate(`/videos/content/${content.content_id}`);
    goToTop();
  }, [navigate, content.content_id]);

  const contentThemes = useMemo(() => {
    return (themes || []).filter((t) => {
      return (t.children || [])
        .map((c) => c.content_id)
        .includes(content.content_id);
    });
  }, [themes, content.content_id]);

  const addedToList = useMemo(() => {
    return content?.content_user?.book_mark;
  }, [content]);

  const handleAddToList = useCallback(async () => {
    if (content?.content_id) {
      try {
        const shouldAddToList = !content.content_user?.book_mark;

        await addToListContentService(content?.content_id, shouldAddToList);

        showSuccessMessage(
          `Vídeo ${shouldAddToList ? 'adicionado ' : 'removido d'
          }a lista de favoritos!`
        );

        if (content.content_user) {
          content.content_user.book_mark = shouldAddToList;
        }

        setContent({ ...(content || {}) });
      } catch (error) {
        showErrorMessage(error as SystemError);
      }
    }
  }, [content]);

  useEffect(() => {
    if (title.current) {
      clamp(title.current, { clamp: 2 });
    }
  }, []);

  useEffect(() => {
    if (initialContent) {
      setContent(initialContent);
    }
  }, [initialContent]);

  return (
    <Container>
      <img src={content.images?.thumbnail} alt={content.name} />
      <ContainerContentBanner className="max-content">
        <div className='inner-wrapper'>
          <ContentBanner>
            <ContainerTheme>
              {contentThemes.length
                ? contentThemes.map((theme) => {
                  return <span key={theme.content_id}>{theme.name}</span>;
                })
                : null}
            </ContainerTheme>

            <h1 ref={title}>{content.name}</h1>
            <p
              className="description"
              dangerouslySetInnerHTML={{ __html: content.description }}
            />

            <div className='actions'>
              <StyleDefaultButton widthValue="214px" onClick={goToContent}>
                <IoMdPlay />
                {!isContentBlocked ? 'assistir' : 'bloqueado'}
              </StyleDefaultButton>

              {!isContentBlocked && (
                <StyleDefaultButton
                  isOutline
                  widthValue="214px"
                  onClick={handleAddToList}
                >
                  {addedToList ? (
                    <>
                      <AiOutlineCheckCircle /> <span>Adicionado</span>
                    </>
                  ) : (
                    <>
                      <IoMdAdd /> <span>minha lista</span>
                    </>
                  )}
                </StyleDefaultButton>
              )}
            </div>
          </ContentBanner>
        </div>
      </ContainerContentBanner>
    </Container>
  );
};

export default HighlightVideoSlide;
