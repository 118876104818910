import styled from 'styled-components';

const PaginationContainer = styled.div`
  width: 100%;

  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 32px;
    list-style: none;

    li {
      a {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        cursor: pointer;
      }

      &.selected {
        a {
          color: var(--primary-color);
        }
      }

      &.disabled {
        a {
          cursor: not-allowed;
        }
      }
    }
  }
`;

export { PaginationContainer };
