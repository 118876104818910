import axios from 'axios';

const httpClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

httpClient.interceptors.request.use((config) => {
  if (config.headers) {
    const token = window.localStorage.getItem('manole-api-token');
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

httpClient.interceptors.response.use(
  (config) => {
    return config;
  },
  (error) => {
    if (error?.response) {
      const urlComplement = window.localStorage.getItem('manole-partner');

      if (error.response.status === 401) {
        if (
          !window.location.href.endsWith('/sign-up') &&
          !window.location.href.endsWith('/login') &&
          !window.location.href.includes('/validate-certificate') &&
          !(window.location.pathname === '/' || window.location.pathname === '')
        ) {
          window.location.href = `${window.location.origin}/login`;
        }
      }
      else if (error.response.status === 403) {
        if (
          !window.location.href.endsWith('/accessDenied') &&
          !window.location.href.endsWith('/login')
        ) {
          if (
            !(
              window.location.pathname === '/' ||
              window.location.pathname === ''
            )
          ) {
            window.location.href = `${window.location.origin}/${urlComplement}/accessDenied`;
          }
        }
      }
      else if (
        (error.response.status === 500 || error.response.status === 400) &&
        !window.location.href.endsWith('/sign-up') &&
        !window.location.href.includes('/validate-certificate') &&
        !window.location.href.endsWith('/my-profile')
      ) {
        if (!window.location.href.endsWith('/somethingWrong')) {
          window.location.href = `${window.location.origin}/${urlComplement}/somethingWrong`;
        }
      }
    }
    return Promise.reject(error);
  }
);

export default httpClient;
