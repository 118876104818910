import { FiX } from 'react-icons/fi'
import { ModalContainer } from './style'
import useUser from 'src/contexts/UserContext/useUser'
import Partner from 'src/models/partner'

interface IModalChangePartner {
  isOpen: boolean
  handleClose: () => void
}

export function ModalChangePartner({ isOpen, handleClose }: IModalChangePartner) {
  const { userPartners, changePartner } = useUser()

  const handleConfirmation = (partner: Partner) => {
    changePartner(partner)
    handleClose()
  }

  if (!isOpen) return null

  return (
    <ModalContainer>
      <div className="pop-up">
        <header>
          <h3>Trocar o parceiro</h3>
          <FiX onClick={handleClose} />
        </header>

        {userPartners?.map((partner, idx) => (
          <div
            key={`${partner.partner_id}-${idx}`}
            onClick={() => handleConfirmation(partner)}
            className="item-modal"
          >
            {partner.name}
          </div>
        ))}
      </div>
    </ModalContainer>
  )
}
