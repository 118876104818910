import styled, { css } from 'styled-components';

const SpecialtyCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  position: relative;
  width: 100%;
  height: 240px;
  overflow: hidden;
  padding: 24px 8px;
  background: rgb(0, 0, 0);
  overflow: hidden;
  border-radius: 8px;
  position: relative;
  cursor: pointer;

  @keyframes from-down-to-up-specialty {
    from {
      transform: translateY(20%);
    }
    to {
      transform: translateY(0%);
    }
  }
`;

interface SpecialtyTitleProps {
  hovered: boolean;
}

const SpecialtyTitle = styled.h4<SpecialtyTitleProps>`
  color: var(--white);
  font-size: 1.4em;
  z-index: 2;

  ${(props) =>
    props.hovered &&
    css`
      animation: from-down-to-up-specialty 0.5s;
    `}
`;

interface SpecialtyThumbnailProps {
  hovered: boolean;
}

const SpecialtyThumbnail = styled.img<SpecialtyThumbnailProps>`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  filter: brightness(80%);
  transition: all 0.5s;

  ${(props) =>
    props.hovered &&
    css`
      transform: scale(1.2);
    `}
`;

const SpecialtyContentDescription = styled.p`
  animation: from-down-to-up-specialty 0.5s;
  margin-top: 8px;
  color: var(--white);
  font: 1em;
  z-index: 2;
  text-transform: uppercase;
`;

const ContinueWatching = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  width: 100%;
  animation: from-down-to-up-video 0.5s;
  margin-top: 8px;
  color: var(--white);
  font: 1em;
  z-index: 2;

  & > .icon {
    margin-top: 6px;
  }

  & > .text {
    flex: 1;
    font-size: 1.1em;
    font-weight: 500;
  }

  & > .percent {
    font-weight: bold;
  }
`;

const ReWatch = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  width: 100%;
  animation: from-down-to-up-video 0.5s;
  margin-top: 8px;
  color: var(--white);
  font: 1em;
  z-index: 2;

  & > .icon {
    margin-top: 6px;
  }

  & > .text {
    flex: 1;
    font-size: 1.1em;
    font-weight: 500;
  }

  & > .percent {
    font-weight: bold;
  }
`;

export {
  SpecialtyCardContainer,
  SpecialtyThumbnail,
  SpecialtyTitle,
  SpecialtyContentDescription,
  ReWatch,
  ContinueWatching,
};
