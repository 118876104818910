import styled, { createGlobalStyle } from 'styled-components';
import heroImage from 'src/assets/heroImage.png';

interface ButtonProps {
  header?: boolean;
}

const GlobalStyles = createGlobalStyle`
  header {
    display: none !important;
  }
`;

const LandingHeader = styled.header`
  position: absolute;
  right: 0;
  top: 0;
  z-index: 99999;
  width: 100%;
  display: flex !important;
  background: var(--background-white);

  & > div {
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    width: 100%;
    padding: 28px 32px;

    @media screen and (max-width: 640px) {
      padding: 24px 16px;

      & > button {
        display: none;
      }
    }
  }
`;

const LandingPageContainer = styled.div`
  width: 100%;
`;

const Button = styled.button<ButtonProps>`
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  color: var(--white);
  text-transform: uppercase;

  ${({ header }) =>
    header
      ? `
      background: none;
      color: var(--text-color);
      border: 1px solid var(--secondary-color);
      border-radius: 6px;
      width: 212px;
      padding: 16px 24px;
      font-weight: bold;
      font-size: 16px;
      line-height: 18px;
      letter-spacing: 1px;

      @media screen and (max-width: 640px){
        width: 100%;
        font-size: 10px;
        padding: 8px 12px;
      }
    `
      : `
      width: 100%;
      background-color: var(--primary-color);
      border: none;
      border-radius: 8px;
      font-size: 18px;
      padding: 16px 24px;
      max-width: 300px;
      min-height: 56px;
    `}
`;

const Hero = styled.div`
  height: 650px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0) 100%
    ),
    url(${heroImage});

  @media screen and (max-width: 640px) {
    display: flex;
    align-items: center;
  }

  .leftSideHero {
    display: flex;
    flex-direction: column;
    height: fit-content;
    gap: 16px;
    color: var(--white);
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 24px;
    padding-top: 200px;
    padding-left: 100px;

    @media screen and (max-width: 640px) {
      align-items: center;
      padding: 0 16px;
      margin-top: 64px;

      h1 {
        font-size: 24px;
        text-align: center;
      }
    }

    & > h1 {
      font-size: 56px;
      max-width: 800px;
      & > span {
        color: var(--primary-color);
        font-family: 'Lato', sans-serif;
        font-weight: 700;
      }
    }

    button.btn-white {
      display: none;
      background-color: transparent;
      border: 1px solid white;

      @media screen and (max-width: 640px) {
        display: block;
      }
    }

    button {
      font-size: 14px;
    }

    & > p {
      padding-bottom: 24px;
    }

    @media (max-width: 768px) {
      font-size: 1em;

      h1 {
        font-size: 28px;
      }
    }
  }
`;

const SpecialistContainer = styled.div`
  color: var(--text-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;

  & > h1 {
    font-family: 'Open Sans', sans-serif;
    font-size: 40px;
    font-weight: 700;

    @media screen and (max-width: 640px) {
      text-align: center;
      font-size: 28px;
    }
  }

  & > div {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;

    @media (max-width: 1100px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 900px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
`;

const SignContainer = styled.div`
  display: flex;
  color: var(--text-color);
  justify-content: space-between;
  padding: 100px 100px;

  & > h1 {
    max-width: 400px;
    font-family: 'Open Sans', sans-serif;
    font-size: 56px;
    font-weight: 700;
    padding-top: 24px;

    @media screen and (max-width: 640px) {
      text-align: center;
      font-size: 32px;
    }
  }

  & > div {
    width: 70%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 24px;
  }

  @media (max-width: 1000px) {
    padding: 0 24px;
    justify-content: center;

    & > div {
      justify-content: center;
      margin-top: 24px;
      margin-bottom: 24px;
    }
  }

  @media (max-width: 900px) {
    flex-direction: column;
    align-items: center;
    padding: 0;

    & > h1 {
      max-width: 332px;
    }
  }
`;

const MostViewedContainer = styled.div`
  background-color: #e9e9e9;
  padding: 32px 0;

  & > div {
    padding: 64px 0px 98px 100px;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  h1 {
    color: var(--text-color);
    font-family: 'Open Sans', sans-serif;
    font-size: 36px;
    font-weight: 700;

    @media screen and (max-width: 640px) {
      text-align: center;
      font-size: 28px;
    }
  }

  @media (max-width: 1000px) {
    & > div {
      padding: 0 24px;
    }
  }
`;

const DevicesContainer = styled.div`
  position: relative;
  font-size: 32px;
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  width: 100%;
  color: var(--text-color);
  background-color: #f2f2f2;
  padding: 170px 60px;

  @media screen and (max-width: 640px) {
    font-size: 24px;
    padding: 60px 0;
  }

  .rightSide {
    position: absolute;
    display: flex;
    flex-direction: column;
    bottom: 28%;
    left: 58%;
    gap: 48px;
    width: 24%;

    @media screen and (max-width: 640px) {
      width: 100%;
      gap: 48px;
      padding: 16px;

      button {
        width: 100%;
        max-width: 100%;
      }
    }

    & > p {
      width: 330px;

      @media screen and (max-width: 640px) {
        text-align: center;
        width: 100%;
      }
    }
  }

  .store {
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 16px;

    img {
      width: 100%;
      height: auto;
    }
  }

  @media (max-width: 1000px) {
    display: flex;
    justify-content: center;
    align-items: center;

    & > div > img {
      height: auto;
      width: 100%;
    }

    .rightSide {
      position: static;
      width: 100%;
    }
  }
`;

export {
  GlobalStyles,
  LandingHeader,
  Button,
  LandingPageContainer,
  Hero,
  SpecialistContainer,
  MostViewedContainer,
  SignContainer,
  DevicesContainer,
};
