import { CardWrapper } from './style'
import { Button } from 'src/pages/LandingPage/style'
import Plan from 'src/models/plan'

interface IPlanCard {
  signUp: boolean
  plan: Plan
  selected?: boolean
  onClick?: () => void
}

export function PlanCard({ signUp, plan, selected, onClick }: IPlanCard) {
  const free = !Number(plan.price)

  const formatPrice = (value: number) => String(value).replace(/\./, ',')

  return (
    <CardWrapper selected={selected} signUp={signUp} onClick={onClick}>
      {signUp && (
        <input type="radio" name="plan" checked={selected} readOnly />
      )}

      <h3 className="name">{plan.name}</h3>

      <div>
        {free
          ? (
            <h2 className="free-test">Testar gratuitamente</h2>
          )
          : (
            <>
              {!!Number(plan.full_price) && (
                <h3 className="full-price">
                  R$ {formatPrice(plan.full_price)} / mês
                </h3>
              )}

              <h2 className="price">
                R$ <span>{formatPrice(plan.price)}</span> / mês
              </h2>
            </>
          )
        }
      </div>

      <p>{plan.description}</p>

      <ul>
        {plan.benefits.map((benefit) => (
          <li key={benefit}>
            <span>{benefit}</span>
          </li>
        ))}
      </ul>

      {!signUp && (
        <a href={plan.info.url_plan} target="_blank" rel="noreferrer">
          <Button>
            {free ? 'Testar gratuitamente' : 'Quero assinar'}
          </Button>
        </a>
      )}
    </CardWrapper>
  )
}
