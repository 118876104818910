import React, { ChangeEvent, useState } from 'react';
import { InputContainer } from './style';
import { ReactComponent as EyeIcon } from 'src/assets/icons/eye.svg'
import { ReactComponent as EyeOffIcon } from 'src/assets/icons/eye-off.svg'

interface FormInputProps {
  label: string;
  onChange?: (value: string) => void;
  name?: string;
  value?: string;
  type?: string;
  flex?: number;
  disabled?: boolean;
  required?: boolean;
  max?: string
}

const FormInput: React.FC<FormInputProps> = ({
  label,
  onChange,
  name,
  value,
  flex,
  type = 'text',
  disabled,
  required,
  max,
}) => {
  const isTypePassword = type === 'password'

  const [visible, setVisible] = useState<boolean>(false)

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (onChange) onChange(e.target?.value)
  }

  return (
    <InputContainer flex={flex || 1} disabled={disabled || false}>
      <label htmlFor={label}>{label}</label>

      <div className="input-container">
        <input
          id={label}
          onChange={onChange ? handleChange : undefined}
          disabled={disabled}
          required={required}
          type={
            !isTypePassword
              ? type
              : visible ? 'text' : type
          }
          value={value}
          name={name}
          max={max}
        />

        {isTypePassword && (
          <div
            className="eye-container"
            onClick={() => setVisible(oldValue => !oldValue)}
          >
            {visible
              ? <EyeIcon />
              : <EyeOffIcon />
            }
          </div>
        )}
      </div>
    </InputContainer>
  );
};

export default FormInput;
