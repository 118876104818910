import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import SubHeader from 'src/components/SubHeader';
import Button from 'src/components/Button';
import { Select, SelectOption } from 'src/components/Select';
import VideoCard from 'src/components/VideoCard';
import { ThemeCard } from 'src/components/ThemeCard';
import Breadcrumb from 'src/components/Breadcrumb';
import { SubHeaderDivider } from 'src/components/SubHeaderDivider';
import PartnerLink from 'src/components/PartnerLink';
import {
  SpecialtyContainer,
  SpecialtyContentsContainer,
  SpecialtyThemesContainer,
  SubHeaderSpecialtyInfo,
} from './style';
import useUser from 'src/contexts/UserContext/useUser';
import useLoading from 'src/contexts/LoadingContext/useLoading';
import useWindowSize from 'src/contexts/WindowSizeContext/useWindowSize';
import { addToListSpecialtyService, getSpecialtyService } from 'src/services/specialtiesService';
import Content from 'src/models/content';
import SystemError from 'src/models/error';
import { ISpecialty } from 'src/models/specialty';
import { showSuccessMessage, showErrorMessage, removeAccentuation } from 'src/helpers';
import { ReactComponent as AddIcon } from 'src/assets/add-icon.svg';
import { ReactComponent as PlayIcon } from 'src/assets/play-icon.svg';
import specialtyDefaultImage from 'src/assets/specialty-default-image.png';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

const orderOptions = [
  { label: 'Mais recentes', value: 'most_recent' },
  { label: 'Ordem alfabética', value: 'alphabetic' },
];

const SpecialtyPage: React.FC = () => {
  const { specialtyId } = useParams<'specialtyId'>();
  const { windowWidth } = useWindowSize();
  const { navigate } = useUser();
  const { setIsLoading } = useLoading();

  const [specialty, setSpecialty] = useState<ISpecialty | undefined>();
  const [selectedOrder, setSelectedOrder] = useState<SelectOption | undefined>(
    orderOptions.find((o) => o.value === 'most_recent')
  );

  const getSpecialty = useCallback(async () => {
    if (specialtyId) {
      setIsLoading(true);

      const specialty = await getSpecialtyService(specialtyId);

      setSpecialty(specialty);

      setIsLoading(false);
    }
  }, [setIsLoading, specialtyId]);

  useEffect(() => { getSpecialty(); }, [getSpecialty]);

  const bookmarked = useMemo(() => {
    return !!specialty?.content_user?.book_mark;
  }, [specialty]);

  const themes = useMemo(() => {
    return specialty?.children || [];
  }, [specialty?.children]);

  const themeCarrousselSlidesPerView = useMemo(() => {
    if (windowWidth > 1200) {
      return 6.3;
    } else if (windowWidth > 1000) {
      return 4.8;
    } else if (windowWidth > 768) {
      return 3.8;
    } else if (windowWidth > 560) {
      return 2.6;
    } else {
      return 1.8;
    }
  }, [windowWidth]);

  const contents = useMemo(() => {
    const allContents = themes
      .filter((t) => !!t && t != null)
      .map((t) => t.children || [])
      .flat() || [];

    let allUniqueContents = [] as Content[];

    for (let content of allContents) {
      if (
        !allUniqueContents.map((c) => c.content_id).includes(content.content_id)
      ) {
        allUniqueContents.push(content);
      }
    }

    switch (selectedOrder?.value) {
      case 'alphabetic':
        allUniqueContents = allUniqueContents.sort((c1, c2) =>
          removeAccentuation(c1.name) > removeAccentuation(c2.name) ? 1 : -1
        );
        break;

      case 'most_recent':
        allUniqueContents = allUniqueContents.sort((c1, c2) =>
          c1.created_at &&
            c2.created_at &&
            new Date(c1.created_at).getTime() > new Date(c2.created_at).getTime()
            ? 1
            : -1
        );
        break;
    }

    return allUniqueContents;
  }, [selectedOrder?.value, themes]);

  const watchAllContents = useCallback(() => {
    if (specialty?.content_id) {
      navigate(`/videos/specialty/${specialty.content_id}`);
    }
  }, [navigate, specialty?.content_id]);

  const bookmarkSpecialty = useCallback(async () => {
    if (specialty?.content_id) {
      try {
        const shouldAddToList = !specialty.content_user?.book_mark;
        await addToListSpecialtyService(specialty.content_id, shouldAddToList);

        showSuccessMessage(
          `Especialidade ${shouldAddToList ? 'adicionada ' : 'removida d'
          }a lista de favoritos!`
        );

        await getSpecialty();
      } catch (error) {
        showErrorMessage(error as SystemError);
      }
    }
  }, [getSpecialty, specialty]);

  return (
    <SpecialtyContainer>
      <SubHeader
        background={specialty?.images?.banner || specialtyDefaultImage}
      >
        <Breadcrumb
          crumbs={[
            <PartnerLink to="/home">Início</PartnerLink>,
            <PartnerLink to="/specialties">Especialidades</PartnerLink>,
            <PartnerLink>{specialty?.name}</PartnerLink>,
          ]}
        />

        <SubHeaderDivider />

        <SubHeaderSpecialtyInfo>
          <div className="themes-and-videos">
            {`${themes.length} ${themes.length > 1 ? 'TEMAS' : 'TEMA'} `}
            <span className="separator">•</span>
            {` ${contents.length} ${contents.length > 1 ? 'VÍDEOS' : 'VÍDEO'}`}
          </div>

          <div className="title">{specialty?.name}</div>

          <div className="watch-and-bookmark">
            <Button
              type="button"
              className="watch-all"
              onClick={watchAllContents}
            >
              <PlayIcon />
              <span>ASSISTIR TUDO</span>
            </Button>

            <Button
              type="button"
              className="bookmark"
              onClick={bookmarkSpecialty}
            >
              {!bookmarked
                ? (
                  <>
                    <AddIcon />
                    <span>MINHA LISTA</span>
                  </>
                )
                : (
                  <>
                    <AiOutlineCheckCircle size={16} />
                    <span>ADICIONADO</span>
                  </>
                )
              }
            </Button>
          </div>

          <div className="plan-info">
            Faça já sua assinatura e você terá acesso a todo o conteúdo dessa especialidade.
          </div>
        </SubHeaderSpecialtyInfo>
      </SubHeader>

      <SpecialtyThemesContainer className="max-content">
        <h3 className="title">Assista por tema</h3>

        <Swiper
          slidesPerView={themeCarrousselSlidesPerView}
          pagination={{ clickable: true }}
          style={{ width: '100%' }}
        >
          {themes.map((t) => (
            <SwiperSlide key={t.content_id}>
              <ThemeCard specialty={specialty!} theme={t} />
            </SwiperSlide>
          ))}
        </Swiper>
      </SpecialtyThemesContainer>

      <SpecialtyContentsContainer className="max-content">
        <div className="title-and-filter">
          <h3 className="title">Todos os videos</h3>

          <Select
            label="Classificar por"
            options={orderOptions}
            value={selectedOrder}
            setValue={setSelectedOrder}
          />
        </div>

        {contents.map((c, index) => <VideoCard key={index} video={c} />)}
      </SpecialtyContentsContainer>
    </SpecialtyContainer>
  );
};

export default SpecialtyPage;
