import httpClient from 'src/http-client'

interface IUploadFileService {
  reference: string
  original_name: string
}

export const uploadFileService = async (file: File) => {
  const formData = new FormData()
  formData.append('file', file)

  const { data } = await httpClient.post<IUploadFileService>('/files/avatar', formData)

  return data
}
