import styled from 'styled-components'

export const PersonalDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  max-width: 932px;
  flex: 1;

  .subtitle {
    margin: 16px 0 48px 0;
  }
`

export const NameAndPicture = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  margin: 32px 0 40px 0;

  img {
    border-radius: 100%;
    width: 72px;
    height: 72px;
    aspect-ratio: 1/1;
  }

  .right-side {
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: start;

    h2 {
      color: var(--text-color);
      font-weight: 600;
      font-size: 32px;
      line-height: 32px;

      @media screen and (max-width: 640px) {
        font-size: 24px;
      }
    }

    .change-picture {
      display: flex;
      flex-direction: row;
      background: none;
      gap: 8px;
      align-items: center;
      border: none;

      :hover {
        background: none;
      }

      svg {
        width: 14px;
        height: 12px;
      }

      span {
        color: var(--primary-color);
        font-size: 14px;
        line-height: 16px;

        :hover {
          text-decoration: underline;
        }
      }

      span#remove {
        color: var(--danger);
      }
    }
  }
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: start;
  width: 100%;

  .line {
    display: flex;
    flex-direction: row;
    gap: 40px;
    width: 100%;

    & > .inner-line {
      flex: 4;
      display: flex;
      flex-direction: row;
      gap: 40px;
    }
  }

  .address-title {
    margin-top: 24px;
  }

  button {
    margin-top: 24px;
  }

  @media (max-width: 700px) {
    .line, .line > .inner-line {
      flex-direction: column;
      gap: 32px;
    }

    button {
      width: 100%;
    }
  }
`
