import styled, { css } from 'styled-components'
import check from 'src/assets/check.svg'

interface CardWrapperProps {
  signUp?: boolean
  selected?: boolean
}

export const CardWrapper = styled.div<CardWrapperProps>`
  width: 100%;
  max-width: 332px;
  height: fit-content;
  border-radius: 8px;
  background-color: #e2e2e2;
  padding: 32px 24px;
  border: ${({ selected }) => `3px solid ${selected ? 'rgba(215, 9, 38, 0.3)' : '#e2e2e2'}`};
  cursor: pointer;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: start;

  h2,
  h3,
  span,
  p {
    font-family: 'Open Sans', sans-serif;
  }

  .price,
  .full-price,
  .free-test,
  p {
    color: var(--text-color);

    :not(p) {
      letter-spacing: -1px;
    }
  }

  .name,
  p,
  ul > li > span {
    line-height: 32px;
  }

  .name,
  .full-price {
    font-weight: 400;
  }

  > .name {
    color: var(--primary-color);
    font-size: 20px;
    margin-bottom: 24px;
  }

  & > div {
    margin-bottom: 32px;

    & > .free-test {
      font-size: 32px;
      font-weight: bold;
      line-height: 40px;
    }

    & > .price {
      font-size: 24px;
      font-weight: bold;
      line-height: 40px;

      & > span {
        font-size: 32px;
      }
    }

    & > .full-price {
      font-size: 20px;
      text-decoration: line-through;
      line-height: 24px;
      margin-bottom: 4px;
    }
  }

  & > p {
    font-size: 18px;
    margin-bottom: 40px;
  }

  & > ul {
    list-style-position: inside;
    list-style-image: url(${check});
    color: #25282b;
    font-size: 16px;
    width: 105%;

    & > li:not(:last-child) {
      margin-bottom: 12px;
    }
  }

  button {
    font-size: 18px;
    line-height: 24px;
    padding: 10px;
  }

  ${({ signUp }) => signUp
    ? css`
      input {
        height: 40px;
        width: 40px;
        margin-bottom: 32px;
        cursor: pointer;

        ::before {
          content: "";
          display: inline-block;
          vertical-align: bottom;
          width: 40px;
          height: 40px;
          margin-right: 0.3rem;
          border-radius: 50%;
          border: 2px solid #1E1246;
          box-sizing: border-box;
        }
        :checked::before {
          border-color: var(--primary-color);
        }
        :checked::after {
          content: "";
          display: inline-block;
          vertical-align: bottom;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          transform: translate(0, -40px) scale(.7);
          background-color: var(--primary-color);
        }
      }

      .name {
        margin-bottom: 4px;
      }

      & > div {
        min-height: 80px;
        margin-bottom: 22px;
      }
    `
    : css`
      & > ul {
        margin-bottom: 46px;
      }
  `}
`
