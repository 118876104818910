import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import GoBack from 'src/components/GoBack'
import SubHeader from 'src/components/SubHeader'
import Breadcrumb from 'src/components/Breadcrumb'
import { SubHeaderDivider } from 'src/components/SubHeaderDivider'
import SubHeaderTitle from 'src/components/SubHeader/SubHeaderTitle'
import PartnerLink from 'src/components/PartnerLink'
import Menu from './components/Menu'
import { PersonalData } from './pages/PersonalData'
import { Security } from './pages/Security'
import { Subscription } from './pages/Subscription'
import { Certificates } from './pages/Certificates'
import { MyProfileContainer, Content } from './style'
import useUser from 'src/contexts/UserContext/useUser'
import profileBanner from 'src/assets/my-profile.png'

export enum ProfileMenus {
  personalData = 'personalData',
  security = 'security',
  signature = 'signature',
  certificate = 'certificate',
}

export function MyProfile() {
  const { subPage } = useParams<'subPage'>();

  const { selectedPartner: partner, navigate } = useUser()

  const [page, setPage] = useState<ProfileMenus>(ProfileMenus.personalData)

  const renderContent = {
    personalData: <PersonalData />,
    security: <Security />,
    signature: <Subscription />,
    certificate: <Certificates />,
  }

  useEffect(() => {
    let currentPage: ProfileMenus = ProfileMenus.personalData

    const path = subPage
    if (!path) return

    if (path === 'security') currentPage = ProfileMenus.security
    else if (path === 'signature') currentPage = ProfileMenus.signature
    else if (path === 'certificate') currentPage = ProfileMenus.certificate

    setPage(currentPage)
  }, [subPage])

  const handleNavigation = (page: ProfileMenus) => {
    let destination: string = page
    if (page === 'personalData') destination = 'personal-data'

    navigate(`my-profile/${destination}`)
  }

  return (
    <>
      <SubHeader background={partner?.banner?.mylist || profileBanner}>
        <Breadcrumb
          crumbs={[
            <PartnerLink className="home" to="/home">
              Início
            </PartnerLink>,
            <span>Meu perfil</span>,
          ]}
        />

        <SubHeaderDivider />

        <GoBack />

        <SubHeaderTitle title="Meu perfil" />
      </SubHeader>

      <MyProfileContainer>
        <Menu
          page={page}
          onClick={handleNavigation}
          paidAccount={true}
        />

        <Content>
          {renderContent[page]}
        </Content>
      </MyProfileContainer>
    </>
  )
}
