import styled from 'styled-components';

const NewContentContainer = styled.div`
  width: 100%;
  position: relative;
`;

const Title = styled.h1`
  margin-top: 40px;
  color: var(--text-color);
  font-weight: bold;
  font-size: 36px;
  line-height: 88px;

  @media (max-width: 1000px) {
    line-height: 60px;
    margin: 16px 0;
  }

  @media (max-width: 700px) {
    line-height: 32px;
    font-size: 28px;
  }
`;

const StylesSwiper = styled.div`
  width: 100%;
  margin: 0 auto;
  margin-bottom: 24px;
  position: relative;

  .swiper-container {
    width: 100%;
  }

  .swiper-button-prev {
    background: linear-gradient(
      90deg,
      #0e0e0e 0%,
      rgba(14, 14, 14, 0.6) 46.67%,
      rgba(0, 0, 0, 0) 100%
    );
    width: 50px;
    height: 268px;
    top: 0;
    left: 0;
    color: var(--white);
  }

  .swiper-button-next {
    background: linear-gradient(
      270deg,
      #0e0e0e 0%,
      rgba(14, 14, 14, 0.6) 46.67%,
      rgba(0, 0, 0, 0) 100%
    );
    width: 50px;
    height: 268px;
    top: 0;
    right: 0;
    color: var(--white);
  }

  .swiper-button-prev.swiper-button-disabled,
  .swiper-button-next.swiper-button-disabled {
    opacity: 0.8 !important;
    background: transparent !important;
    color: var(--text-color) !important;
  }

  .swiper-button-prev:after,
  .swiper-button-next:after {
    font-size: 16px !important;
    font-weight: bold !important;
  }

  .swiper-slide.swiper-slide-active {
  }
`;

export { NewContentContainer, Title, StylesSwiper };
