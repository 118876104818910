import httpClient from 'src/http-client'
import ContentUser from 'src/models/content-user'

export interface IContentUsersParams {
  book_mark?: boolean
  relations?: string[]
  segmentation_item_ids?: string[]
  limit?: number
  sort_by?: string
  order_by?: string
  in_progress?: boolean
}

export const getContentUsersService = async (
  params?: IContentUsersParams
): Promise<ContentUser[]> => {
  const { data } = await httpClient.get<ContentUser[]>(`/content-users`, { params })
  return data
}
