import httpClient from 'src/http-client'
import { IUserSpecialtyResponse } from 'src/models/specialty'
import { IUser, IUserMe, IUserPut } from 'src/models/userModel'

export const getUserService = async () => {
  const { data } = await httpClient.get<IUserMe>('/users/me')
  return data
}

export const putUserService = async (user: IUserPut) => {
  const { data } = await httpClient.put<IUser>('/users', user)
  return data
}

export const addSpecialtiesToUserService = async (specialty_ids: string[]) => {
  const { data } = await httpClient.post<IUserSpecialtyResponse[]>('/users/me/specialties', {
    specialty_ids
  })
  return data
}

export const removeSpecialtiesToUserService = async (specialty_ids: string[]) => {
  await httpClient.delete<IUserSpecialtyResponse[]>('/users/me/specialties', {
    data: {
      specialty_ids
    }
  })
}
