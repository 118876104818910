import React, { useCallback, useEffect, useState } from 'react';
import AddToList from './components/AddToList';
import RateStars from './components/RateStars';
import BlankPlayer from './components/BlankPlayer';
import ContentTabs from './components/ContentTabs';
import BlockedBlock from 'src/components/BlockedBlock';
import DownloadVideoButton from './components/DownloadVideoButton';
import { ModalChangeSubscription } from 'src/components/ModalChangeSubscription';
import PlayerSambavideos, { PlayerEventListener } from './components/PlayerSambaVideos';
import {
  ActualVideoContentContainer,
  AddToListAndRateContainer,
  BannerImage,
  ContentAuthor,
  ContentTitle,
  ContentLockedInfoContainer,
} from './style';
import { createCertificateService } from 'src/services/certificatesService';
import {
  startContentService,
  finishContentService,
  updateContentProgressService,
} from 'src/services/contentsService';
import useVideos from 'src/pages/Videos/contexts/useVideos';
import useUser from 'src/contexts/UserContext/useUser';
import { showErrorMessage } from 'src/helpers';
import SystemError from 'src/models/error';
import ContentUser from 'src/models/content-user';

const ActualVideoContent: React.FC = () => {
  // const { specialtyId } = useParams<'specialtyId'>();
  const { userPlan } = useUser();
  const {
    autoPlay,
    selectedContent,
    specialty,
    goToNextContent,
    reloadSelectedContent,
    allContents,
    reloadData,
  } = useVideos();

  const [progress, setProgress] = useState<number>(0);
  const [lastWatchedTime, setLastWatchedTime] = useState<number>(0);
  const [showModalChangeSubscription, setShowModalChangeSubscription] =
    useState(false);

  const createCertificate = useCallback(async () => {
    try {
      await createCertificateService(selectedContent?.content_id!);
    } catch (error) {
      showErrorMessage(error as SystemError);
    }
  }, [selectedContent?.content_id]);

  const updateContentProgress = async (time: number | null) => {
    if (selectedContent) {
      await updateContentProgressService(selectedContent.content_id, time);
    }
  };

  const timeToUpdateVideoProgressInSeconds = 15;

  useEffect(() => {
    if (progress >= lastWatchedTime + timeToUpdateVideoProgressInSeconds) {
      setLastWatchedTime(progress);
      updateContentProgress(progress);
      return;
    }
    if (progress < lastWatchedTime) {
      if (progress > 1) {
        setLastWatchedTime(progress);
        updateContentProgress(progress);
      } else {
        setLastWatchedTime(0);
        updateContentProgress(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastWatchedTime, progress, selectedContent?.content_id]);

  const videoIsFree = !!selectedContent?.info?.is_free
  const isContentBlocked = !videoIsFree && !userPlan?.planPremium

  const startContent = async () => {
    if (selectedContent && !selectedContent.content_user?.start_at) {
      try {
        if (specialty?.content_id && !specialty?.content_user?.start_at) {
          // TODO: validar possivel regra de negocio!
          // await startSpecialtyService(specialty?.content_id!);
        }

        await startContentService(selectedContent?.content_id);

        if (selectedContent.content_user) {
          selectedContent.content_user.start_at = new Date().toLocaleString();
        } else {
          selectedContent.content_user = {
            start_at: new Date().toLocaleString(),
          } as ContentUser;
        }

        reloadSelectedContent();
      } catch (error) {
        showErrorMessage(error as SystemError);
      }
    }
  };

  const finishContent = async () => {
    if (
      !!selectedContent?.content_user?.start_at &&
      !selectedContent?.content_user?.finish_at
    ) {
      try {
        if (selectedContent.content_user) {
          selectedContent.content_user.finish_at = new Date().toLocaleString();
        } else {
          selectedContent.content_user = {
            finish_at: new Date().toLocaleString(),
          } as ContentUser;
        }

        await finishContentService(selectedContent?.content_id);

        await createCertificate();

        reloadSelectedContent();
      } catch (error) {
        showErrorMessage(error as SystemError);
      }
    }

    if ((allContents || []).every((c) => !!c.content_user?.finish_at)) {
      if (specialty?.content_id && !specialty?.content_user?.finish_at) {
        if (specialty?.content_user) {
          specialty.content_user.finish_at = new Date().toLocaleString();
        } else {
          specialty.content_user = {
            finish_at: new Date().toLocaleString(),
          } as ContentUser;
        }

        // TODO: validar possivel regra de negocio!
        //await finishSpecialtyService(specialty?.content_id!);

        await reloadData();
      }
    }
  };

  const hasReachedCompletionTime = (duration: number, currentTime: number) => {
    const totalDurationInSecs = duration;
    const completionRate = 0.9;

    const completionTime = totalDurationInSecs * completionRate;
    return currentTime >= completionTime;
  };

  const checkIsCompleted = async (player: PlayerEventListener) => {
    const { event, eventParam, duration } = player;

    if (event !== 'onProgress' || !eventParam) {
      return false;
    }

    if (hasReachedCompletionTime(duration, eventParam)) {
      await finishContent();
    }
  };

  const updateWatchTime = (player: PlayerEventListener) => {
    const { event, eventParam } = player;

    if (event === 'onProgress') setProgress(eventParam);

    return null;
  };

  const handleOnFinish = async (goToNext: boolean) => {
    await finishContent();
    if (goToNext) goToNextContent();
  };

  const getEventListeners = async (player: PlayerEventListener) => {
    switch (player.event) {
      case 'onProgress':
        updateWatchTime(player);
        await checkIsCompleted(player);
        break;

      case 'onStart':
        await startContent();
        break;
    }
  };

  return (
    <>
      <ActualVideoContentContainer>
        {selectedContent && !isContentBlocked ? (
          <PlayerSambavideos
            contentReference={isContentBlocked ? '' : selectedContent.reference}
            resume={selectedContent.content_user?.content_view}
            contentId={selectedContent.content_id}
            autoPlay={autoPlay}
            handleOnFinish={handleOnFinish}
            getEventListeners={getEventListeners}
            isLocked={isContentBlocked}
          />
        ) : specialty ? (
          <BannerImage
            src={specialty?.images?.banner ? specialty?.images?.banner : ''}
          />
        ) : (
          <BlockedBlock>
            <BlankPlayer />
          </BlockedBlock>
        )}

        {isContentBlocked && (
          <ContentLockedInfoContainer>
            <h4 className="message">
              Conteúdo bloqueado, assine um plano e tenha acesso à todos os
              conteúdos
            </h4>
            <span
              className="link-plans"
              onClick={() => setShowModalChangeSubscription(true)}
            >
              Ver planos
            </span>
          </ContentLockedInfoContainer>
        )}

        {!isContentBlocked && window.ReactNativeWebView && selectedContent && (
          <DownloadVideoButton
            contentId={selectedContent.content_id}
            specialtyId={specialty?.content_id}
          />
        )}

        {!isContentBlocked && (
          <AddToListAndRateContainer>
            <AddToList />
            <RateStars />
          </AddToListAndRateContainer>
        )}

        <ContentTitle>{selectedContent?.name || specialty?.name}</ContentTitle>

        {!!selectedContent && (
          <ContentAuthor>
            <span>por</span>{' '}
            <strong>
              {(selectedContent.authors || []).map((a) => a.name).join(', ')}
            </strong>
          </ContentAuthor>
        )}

        <ContentTabs />
      </ActualVideoContentContainer>

      <ModalChangeSubscription
        isOpen={showModalChangeSubscription}
        handleClose={() => setShowModalChangeSubscription(false)}
      />
    </>
  );
};

export default ActualVideoContent;
